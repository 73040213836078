import React from 'react';
import { Image, Button, Modal, Select } from 'antd';
import { FormWrapper } from '../../Style/style';

const ImageModal = ({ image,imageModalActive,updateImageRow,imageList,lang,messages,toggleModal}) => {

  return (
  <>
  {imageModalActive  &&
    <Modal
      title={messages["genel.edit"]}
      open={imageModalActive}
      footer={
        <Button  size="large" type="primary" onClick={() => toggleModal("imageModalActive",null)}>
          {messages["button.close"]}
        </Button>
      }
      onCancel={() => toggleModal("imageModalActive",null)}
      forceRender
      closable={false}
    >
      <FormWrapper style={{height:'auto'}}>
        <Select 
          value={image && image.description && image.description}
          autoComplete="false"
          showSearch
          onChange={e=>updateImageRow("image",e)}
          style={{ width: '100%' }}
        >
          {imageList.length && imageList.map((row,index)=>{
            const {image,smallimage,description,_id} = row;
            return (
            <Select.Option key={index} value={_id} label={description}>
                <Image alt="#" src={smallimage ? smallimage : image} style={{width:'60px'}}/>
                <span>{description}</span>
            </Select.Option>
            )}
          )}
        </Select>
      </FormWrapper>
    </Modal>
  }
  </>
  );
}

export  {ImageModal};
