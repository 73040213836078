import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faTrash} from '@fortawesome/free-solid-svg-icons';
import { Table ,Button,message,Spin,Popconfirm} from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {PopupChoser} from '../../../components/PopupChoser';
import Heading from '../../../components/heading/heading';
import {updateDelivery} from '../../../redux/deliveryDetails/deliveryDetailActions';
import {getDeliveryList} from '../../../redux/deliveryList/deliveryListActions';
import { deleteDelivery } from '../../../redux/deliveryDetails/deliveryDetailActions';

const CompanyDelivery = ({messages,deliveryStatus,companyID}) => {
  const {deliveryDetailLoading,deliveryList} = useSelector(state => state.deliveryList);
  let delivery = deliveryList && deliveryList.length && deliveryList.map((el, idx) => ({key: idx, ...el}))

  const dispatch = useDispatch();
  //******************************************************************************************************************************************************************/
  const onChangeDeliveryRowStatus =(data,field,value)=>{
    let temp = data;
    temp[field]=value
    dispatch(updateDelivery(temp))
    .then(res => {
        message.success(messages["alert.savesuccess"],2);
        dispatch(getDeliveryList("",companyID))
    })
  }
  //******************************************************************************************************************************************************************/
  const deleteDeliveryRow = (record) => {
    if(record._id){
      dispatch(deleteDelivery(record))
      .then(x=>{
          dispatch(getDeliveryList("",companyID))
          message.success(messages["alert.deletesuccess"],2);
      })   
    }
  }
  //******************************************************************************************************************************************************************/

  const deliveryColumn = [
    {
      title: [messages["genel.date"]],
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      render: (text) => moment(text).format("DD.MM.YYYY")
    },
    {
      title: [messages["genel.qty"]],
      dataIndex: 'qty',
      key: 'qty',
      align: 'center',
      render: (text,record) => text +" "+record.unit,
      width: '15%',
    },
    {
      title: messages["product.name"],
      dataIndex: 'productname',
      key: 'productname',
      width: '30%',
      align:'left'
    },
    {
      title:messages["delivery.details"],
      dataIndex: 'receiver',
      key: 'receiver',
      width: '18%',
    },
    {
      title: [messages["delivery.status"]],
      dataIndex: 'status',
      key: 'status',
      align:'center',
      render: (text,record) =>                
      <PopupChoser
        option={deliveryStatus}
        choseData={(e)=>onChangeDeliveryRowStatus(record,'status',e)}
        selectedData={text}
      /> ,
      width: '15%',
    },
    {
      title: "",
      dataIndex: 'action',
      key: 'action',
      width: '7%',
      render: (text,record) => 
        <Popconfirm
          title={messages["alert.deletequest"]}
          okText={messages["genel.yes"]}
          cancelText={messages["genel.no"]}
          onConfirm={()=>deleteDeliveryRow(record)}
        >
          <Button
            shape="circle"
            size="small"
          >
            <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
          </Button>  
        </Popconfirm>
    }
  ];

  return (
    <div className="full-width-table">
      <Cards
        title={ <Heading as="h5">{messages["company.deliveryList"]}</Heading>}
        size="large"
        bodypadding="0px"
        loading={deliveryDetailLoading && <Spin indicator={faSpinner}  />}
        bodyStyle={{ minHeight: '730px' }}
      >
        <div className="table-bordered top-seller-table table-responsive">
          <Table 
            columns={deliveryColumn} 
            dataSource={delivery} 
            pagination={false}     
            scroll={{ y: 635 }}
          />
        </div>
      </Cards>
    </div>
  );
}

export {CompanyDelivery};
