import { combineReducers } from 'redux';

import auth from './auth/reducers';

import App from './app/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';


import ChangeLayoutMode from './themeLayout/reducers';
 //import settings from './settings';
 import myCompany from './company/myCompanyReducer';
import userList from './users/userListReducer';
import userDetails from './users/userDetailReducer';
import companyList from './CompanyList/companyListReducer';
import orderItemList from './orderItemList/orderItemListReducers';
import orderItemDetail from './orderItemDetail/orderItemReducers';
import taskList from "./taskLists/tasklistReducer";
import taskDetails from "./taskDetails/taskDetailReducers";
import componentList from "././componentList/componentListReducers";
import componentDetails from "./componentDetails/componentDetailsReducer";

import materials from './materials/materialsReducers';
import materialDetail from './materialDetails/materialDetailReducers';
import stockList from './stockLists/stockListReducers';
import stockDetail from './stockDetails/stockDetailReducers';

import offerList from './offerList/offerListReducer';
import offerDetail from './offerDetails/offerDetailReducers';
import modelList from './modelList/modelListReducers';
import modelDetails from './modelDetails/modelDetailReducers';
import productList from './productList/productListReducer';
import tcmb from './tcmb/tcmbReducers';
import productDetail from './productDetails/productDetailReducers';
import imageList from "./imageList/imageListReducers";
import deliveryDetails from "./deliveryDetails/deliveryDetailReducers";
import deliveryList from "./deliveryList/deliveryListReducers";
import acTestList from "./actest/acTestListReducer";
import companyDetail from './companyDetails/companyDetailReducer';

import todoList from './todoList/todoListReducers';
import logList from './logList/logListReducer';

import buyingList from "./buyingList/buyingListReducer";
import buyingDetail from "./buyingDetails/buyingDetailReducer";
import employeeList from "./employee/employeeListReducer";
import employeeDetails from "./employee/employeeDetailReducer";

import employeeFileList from "./employeefiles/employeeFileListReducer";
import employeFileDetails from "./employeefiles/employeeFileDetailReducer";

import productStocks from './productStockList/productStockListReducers';
import productStockDetails from './productStockDetails/productStockDetailReducers';
import maintenanceList from './maintenance/maintenanceListReducers';
/*
import tcmbList from './tcmbList';
import akaysisAuth from './akaysisauth';
import deviceList from './deviceList';
import deviceDetails from './deviceDetails';
import devicePrograms from './gw_programs';
import deviceCommands from './gw_commands';
import imageDetails from "./imageDetails";
import buyingDetail from "./buyingDetail";
 */




export default combineReducers({
    ChangeLayoutMode,
    auth,
    App,
    LanguageSwitcher,
    tcmb,
 //   tcmbList,
    myCompany,
    userList,
    userDetails,
    companyList,
    orderItemList,
    orderItemDetail,
    taskList,
    taskDetails,
    componentList,
    componentDetails,
    materials,
    offerList,
    offerDetail,
    modelList,
    modelDetails,
    productList,
    productDetail,
    productStockDetails,
    productStocks,
    imageList,
    materialDetail,
    stockList,
    stockDetail,
    deliveryDetails,
    deliveryList,
    companyDetail,
    todoList,
    logList,
    buyingList,
    buyingDetail,
    employeeList,
    employeeDetails,
    employeeFileList,
    employeFileDetails,
    acTestList,
    maintenanceList


/*

    logList,
  
    akaysisAuth,
    deviceList,
    deviceDetails,
    devicePrograms,
    deviceCommands,


    imageDetails,

    

 */




    


});