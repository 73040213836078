import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useParams,NavLink ,useHistory} from 'react-router-dom';
import { Row, Col ,Button,message,notification,Popconfirm,Popover} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFilePdf,faList, faTrash, faSave,faRefresh} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../Style/styled';
import { MenuListWrapper} from '../Style/style';
import {getProductDetails,updateProduct,createProduct,deleteProduct} from '../../redux/productDetails/productDetailActions';
import {getModelList} from '../../redux/modelList/modelListActions';
import {getMaterialsList} from '../../redux/materials/materialsActions';
import {getProductList} from '../../redux/productList/productListActions';

import {deleteProductStock,updateProductStockOperation,createProductStock} from '../../redux/productStockDetails/productStockDetailActions';
import {getCompanyList} from '../../redux/CompanyList/companyListActions';
import appActions from '../../redux/app/actions';
import {DetailMenu} from "../commonComponent/detailMenu";
import {ProductGenerals} from "./products/productGenerals";
import {ProductSpecifications} from "./products/productSpecifications";
import {ProductImages} from "./products/productImages";
import {SpecModal} from "./products/specModal";
import {ProModal} from "./products/productionModal";
import {ImageModal} from "./products/imageModal";
import {StockRowModal} from "../commonComponent/StockRowModal";
import {ProductOperations} from "./products/productOperations";
import {StockOperations} from "./products/stockOperations";
import {CompanyListModal} from "../commonComponent/companyListModal";
import blank from '../../static/img/resyok.jpeg';


import moment from 'moment';
const ProductDetails = ( ) => {
  let history = useHistory();
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const {copyValues,copyForm,categoryList,operationCenterList,operationTypeSelect,UnitList,CurrencyList} = useSelector(state => state.App);
  
  const { productDetail, productDetailLoading,status} = useSelector(state => state.productDetail);
  const {materialList,materialsListLoading,materialListStatus} = useSelector((state) => state.materials);
  const {productListLoading,productList,productListStatus} = useSelector(state => state.productList);
  const {companyList,companyListLoading} = useSelector((state) => state.companyList);
  const {modelList} = useSelector(state => state.modelList);

  const [dataSource, setDataSource] = React.useState(productDetail);
  const [modelListOptions, setModelListOptions] = React.useState([]);
  const [stockRowData, setstockRowData] = React.useState();
  const [StockOpList, setStockOpList] = React.useState();
  const [companies, setCompanies] = React.useState(companyList);


  const {tcmb} = useSelector(state => state.tcmb);
  const USDSatis = tcmb && tcmb.Data[0].ForexSelling;
  const EURSatis = tcmb && tcmb.Data[1].ForexSelling;
  const [state, setState] = React.useState({
    specModalIndex:null,
    proModalIndex:null,
    imageModalIndex:null,
    specModalActive:null,
    proModalActive:null,
    stockRowModalActive:null,
    imageModalActive:null,
    productionCollapseActiveKey:1,

    companyListModalActive:null,
    tabID:"general",
    collapsed:false
  });
  const { setCopyValues,setCopyForm } = appActions;
  const dispatch = useDispatch();
  const { productID } = useParams();
  const { messages } = useIntl();
  const lang = locale === 'tr' ? 0 :1
  const MenuList =[
    {tabID:"general",MenuName:messages["product.generalspecs"]},
    {tabID:"specifications",MenuName:messages["product.specifications"]},
    {tabID:"operations",MenuName:messages["product.production"]},
    {tabID:"images",MenuName:messages["product.images"]},
    {tabID:"files",MenuName:messages["model.downloads"]},
    {tabID:"stock",MenuName:messages["product.stocks"]},
  ];
  const buttonList = [
    <div key={0}>
      <Popover content= {messages["button.refresh"]} placement="bottom">
        <Button shape="circle"  onClick={()=>refreshDataSource()} size='large' icon={<FontAwesomeIcon icon={faRefresh} color="#0328fc"/>} style={{marginRight:'10px'}}/>
      </Popover>
      {dataSource && dataSource._id && 
      <Popconfirm
        title={messages["alert.deletequest"]}
        okText={messages["genel.yes"]}
        cancelText={messages["genel.no"]}
        onConfirm={()=>onDeleteProduct(dataSource._id)}
      >
        <Button shape="circle" size='large' icon={<FontAwesomeIcon icon={faTrash} color="#ce1818"/>}  style={{marginRight:'10px'}}/>
      </Popconfirm>}
      <Popover content= {messages["button.save"]} placement="bottom">
        <Button shape="circle"  onClick={()=>onSaveProduct()} size='large' icon={<FontAwesomeIcon icon={faSave} />} style={{marginRight:'10px'}}/>
      </Popover>           

      <Popover content= {messages["product.datasheet"]} placement="bottom">
        <NavLink to={`/productdatasheet/${dataSource._id}`}>
          <Button shape="circle"   size='large' icon={<FontAwesomeIcon icon={faFilePdf} />} style={{marginRight:'10px'}}/>
        </NavLink>
      </Popover> 
      <Popover content= {messages["sidebar.products"]} placement="bottom">
        <NavLink key={4} to="/admin/products" className="project-edit">
          <Button shape="circle" type='primary' size='large' icon={<FontAwesomeIcon icon={faList}/>}/>
        </NavLink>
      </Popover>
    </div>
  ];
  const {collapsed,tabID} = state;
  const {urun_ozellikler,productImages,summary,production,imageList} = dataSource;

  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    let Temp = [];
    modelList.forEach(row => {
        Temp.push({
        label: row.model_kodu,
        value: row._id
        });
    })
    setModelListOptions(Temp)
  },[modelList]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if (productID !=="new" && dataSource._id !== productID) {
        dispatch((getProductDetails({id:productID})))
        .then(res=>{
          message.success(messages["alert.savesuccess"],  2 );
        });
    }
    if(productID === "new"){
      setDataSource({
        urun_sira:1,
        urun_adi:["","-"],
        urun_model:"",
        urun_kodu:"",
        urun_guc:"",
        urun_lumen:"",
        urun_durumu:true,
        urun_doviz:"$",
        urun_fiyat:0,
        urun_panel:"",
        urun_aku:"",
        urun_cct:"XXXX",
        product_dimensions:"",
        product_weight:"",
        urun_optik:"XX",
        production:[],
        urun_ozellikler:[]
      })
      //console.log("init")
    }
    dispatch(getModelList("productionlist",""))
  },[]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    
    if (productID !=="new") {
      //console.log("update")
      //let tempData = productDetail &&  productDetail.production &&  productDetail.production.length &&  productDetail.production.sort((a, b) => a.material_category.localeCompare(b.material_category));
      setDataSource(productDetail)
    }

      let operationList = [];
      let runingTotal = 0;
      productDetail.stockOperations && productDetail.stockOperations.length && productDetail.stockOperations.sort((a,b)=> new Date(a.operation_date)-new Date(b.operation_date)).forEach(mtr=>{
          runingTotal = runingTotal + mtr.sumQty
          operationList.push({
              runTotal: runingTotal,
              product_id : mtr.product_id,
              order_id : mtr.order_id,
              orderitem_id:mtr.orderitem_id,
              operation_center:mtr.operation_center,
              operation_date : mtr.operation_date,
              operation_type  : mtr.operation_type,
              description: mtr.description,
              qty:mtr.qty,
              unit:mtr.unit,
              price : mtr.price,
              doviz : mtr.doviz,
              sumQty:mtr.sumQty,
              sumPrice:mtr.sumPrice,
              _id:mtr._id
          });  
      })
    
      setStockOpList({
        operations:operationList
      }); 
    
  },[productDetail]);
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(productListStatus===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
  }, [productListStatus]);

  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    setCompanies(companyList)
  },[companyList]);
  //******************************************************************************************************************************************************************/
  const gotoPage = (page) => {
    if(page==='operations'){
      dispatch(getMaterialsList('list',null));
      dispatch(getProductList("list","model_category"));
    }else if(page==='stock'){
     // dispatch(getProductStockList(productID))
    }
    setState({
      ...state,
      tabID:page,
      collapsed:false
    })
  }
  //******************************************************************************************************************************************************************/
  const onSaveProduct = () => {
    if(dataSource._id){
      dispatch(updateProduct({...dataSource}))
      .then(res=>{
        message.success(messages["alert.savesuccess"], 2 );
      })
    }else{
      dispatch(createProduct({...dataSource}, history))
      .then(res=>{
        message.success(messages["alert.savesuccess"], 2 );
      });
    }
  };
  //******************************************************************************************************************************************************************/
  const toggleModal = (name,val,data) =>{

    if(name==="companyListModalActive"){
      dispatch(getCompanyList(null));
      setState({
        ...state,
        [name]: val,
        companyListModalActive:true
      });
    }else if (name==="proModalIndex"){
      setState({
        ...state,
        [name]: val,
        proModalActive:true
      });
      !materialList.length && dispatch(getMaterialsList('list',null));
    }else if (name==="imageModalActive"){
      setState({
        ...state,
        [name]: val,
      });
    }
    else if (name==="specModalIndex"){
      setState({
        ...state,
        [name]: val,
        specModalActive:true
      });
    }else if (name==="stockRowModalActive"){
      setState({
        ...state,
        [name]: val,
      });
      let tempData = data;
      if(val){tempData.operation_date = val &&  moment(data.operation_date)};
      setstockRowData(tempData);
    }
  };
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !state.collapsed,
    });
  };
  //******************************************************************************************************************************************************************/
  const addNewSpecRow = () => {
    let temp = dataSource.urun_ozellikler;
    temp.push({
      sira:temp.length ? temp.length +1 :1,
      urun_ozellik:["",""],
      urun_aciklama: [],
      product_description:[]
    })
    setDataSource({
      ...dataSource,
      urun_ozellikler:temp
    });
    setState({
      ...state,
      specModalIndex: temp.length-1,
      specModalActive:true
    });
  }
  //******************************************************************************************************************************************************************/
  const removeSpecRow = (index) => {
    let temp = dataSource.urun_ozellikler;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        urun_ozellikler:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const productSpecificationsCopy=()=>{
    dispatch(setCopyValues(urun_ozellikler))
    dispatch(setCopyForm("productSpecifications"))
  }
  //******************************************************************************************************************************************************************/
  const productSpecificationsPaste=()=>{
    if(copyValues &&  copyForm === "productSpecifications"){
      setDataSource({
        ...dataSource,
        urun_ozellikler : copyValues
      });
    }else{
        if (window.confirm("Kopyalanmış birimler uyuşmuyor veya kopyalama hatası")){}
    }
  }
  //******************************************************************************************************************************************************************/
  const calculateProductionList = () => {
  
    let tempdata = dataSource.production;
    let summary = 0 ; 
    let materialListfilterResult = [];
    let materialListSum = 0;
    let price = 0;
    for(let i = 0; i < tempdata.length; i++){
      if (tempdata[i].op_name==="Yarı Mamül"){
        price = productList.filter(option => option._id === tempdata[i].material_group)[0].urun_fiyat
        
      }else{
        materialListfilterResult =  materialList && materialList.length   && materialList.filter(option => option.material_category === tempdata[i].material_category  && option.material_group === tempdata[i].material_group )        
        //console.log(materialListfilterResult)Burada USD Güncellemesi Yapmak Gerekiyor 
        materialListSum = materialListfilterResult.length>0 && materialListfilterResult.reduce((a, b) => a + b.material_price, 0);
        price = materialListfilterResult.length > 0 ? (materialListSum / materialListfilterResult.length) || 0 : tempdata[i].materialprice ;
      }
      let rowUSD = tempdata[i].materialcurrency==="₺" ? tempdata[i].materialqyt *( price/USDSatis) : tempdata[i].materialcurrency==="€" ? tempdata[i].materialqyt *( (price*EURSatis)/USDSatis) : tempdata[i].materialqyt*price ; 
      tempdata[i].rowTotal =  rowUSD
      tempdata[i].materialprice = price
      summary=summary+rowUSD
  
    }
    setDataSource({
      ...dataSource,
      production:tempdata,
      summary:parseFloat(summary)
    });
  }
  //******************************************************************************************************************************************************************/
  const removeProRow = (index) => {
    let temp = dataSource.production;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        production:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const addNewProRow = () => {
    !materialList.length && dispatch(getMaterialsList('list',null));
    let temp = dataSource.production;
    temp.push({
      op_name:null,
      material_category:"",
      material_group:"",
      information: "",
      materialqyt:1,
      rowTotal:0
    })
    setState({
      ...state,
      proModalIndex: (temp.length)-1,
      proModalActive:true
    });
    setDataSource({
      ...dataSource,
      production:temp
    });

  };
  //******************************************************************************************************************************************************************/
  const updateProRow = (Field,eData) => {
    let tempdata = dataSource
      tempdata.production[state.proModalIndex][Field] = eData;
    if(Field==="materialqyt"){
      tempdata.production[state.proModalIndex].rowTotal=tempdata.production[state.proModalIndex].materialprice * eData
    }else if((Field==="materialprice")){
      tempdata.production[state.proModalIndex].rowTotal=tempdata.production[state.proModalIndex].materialqyt * eData
    }
    setDataSource({
      ...dataSource,
      tempdata
    });
  } 
  //******************************************************************************************************************************************************************/
  const productProductionCopy=()=>{
    dispatch(setCopyValues(dataSource.production));
    dispatch(setCopyForm("productProduction"));
    message.info("Kopyalandı",3);
  }
  //******************************************************************************************************************************************************************/
  const productProductionPaste=()=>{
    if(copyValues &&  copyForm === "productProduction"){
      setDataSource({
        ...dataSource,
        production : copyValues
      });
      message.info("Yapıştırıldı",3);
    }else{
        if (window.confirm("Kopyalanmış birimler uyuşmuyor veya kopyalama hatası")){}
    }
  }
  //******************************************************************************************************************************************************************/
  const updateImageRow = (field,e) => {
    let tempdata = dataSource;
    const _image =  imageList.filter(x=>x._id===e)[0];
    tempdata.productImages[state.imageModalIndex][field] = e;
    tempdata.productImages[state.imageModalIndex].tempimage = _image.image
    tempdata.productImages[state.imageModalIndex].description = _image.description
    setDataSource({
      ...dataSource,
      tempdata
    });
  }
  //******************************************************************************************************************************************************************/
  const addImageRow = () => {
    let temp = dataSource.productImages && dataSource.productImages.length ? dataSource.productImages : [];
    temp.push({
      image:"",
      description:"",
    })
    setDataSource({
      ...dataSource,
      productImages:temp
    });
    setState({
      ...state,
      imageModalIndex: temp.length-1,
      imageModalActive:true
    });

  };
  //******************************************************************************************************************************************************************/
  const removeImageRow = (index) => {
    let temp = dataSource.productImages;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        productImages:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const onDeleteProduct = (id) => {
      if(dataSource._id){
        dispatch(deleteProduct(id))
        .then(res=>{
          message.success(messages["alert.savesuccess"], 2 );
          history.push({
            pathname : "/admin/products"
          })
        });
      };

  };
  //******************************************************************************************************************************************************************/
  const onChangeStockRowData = (field,data) => {
    if(field==="qty"){
      setstockRowData({
        ...stockRowData,
        [field]:data,
        sumQty: stockRowData.operation_type ==="giris" ? data : 0-data,
        sumPrice:stockRowData.operation_type ==="giris" ? data*stockRowData.price : 0-(data*stockRowData.price)
      });
    }else if (field === "price" ){
      setstockRowData({
        ...stockRowData,
        [field]:data,
        sumPrice:stockRowData.operation_type ==="giris" ? data*stockRowData.qty : 0-(data*stockRowData.qty)
      });
    }else if (field === "operation_type" ){
      setstockRowData({
        ...stockRowData,
        [field]:data,
        sumQty: data ==="giris" ? stockRowData.qty : 0-stockRowData.qty,
        sumPrice: data ==="giris" ? stockRowData.price*stockRowData.qty : 0-(stockRowData.price*stockRowData.qty)
      });
    }else{
      setstockRowData({
        ...stockRowData,
        [field]:data
      });
    }

  }
  //******************************************************************************************************************************************************************/
  const removeStockRow = (stockRow) => {
    if(productID && stockRow._id){
      dispatch(deleteProductStock(productID,stockRow._id))
      .then(res=>{
        setTimeout(() => {
          dispatch((getProductDetails({id:productID})))
          .then(res=>{
            message.success(messages["alert.savesuccess"],  2 );
          },500);
        });
      })
    }
   
  }
  //******************************************************************************************************************************************************************/
  const onSaveStockRow = () => {
     if(stockRowData._id){
      dispatch(updateProductStockOperation(stockRowData))
      .then(res=>{
        setTimeout(() => {
          dispatch((getProductDetails({id:productID})))
          .then(res=>{
            message.success(messages["alert.savesuccess"],  2 );
          });
        },1000);
        setState({
          ...state,
          stockRowModalActive: null
        });
      })
    }else{
      dispatch(createProductStock(stockRowData))
      .then(res=>{
        setTimeout(() => {
          dispatch((getProductDetails({id:productID})))
          .then(res=>{
            message.success(messages["alert.savesuccess"],  2 );
          });
        },1000);
        setState({
          ...state,
          stockRowModalActive: null
        });
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const addNewStockRow = ( ) => {
    setstockRowData({
      product_id: productID,
      operation_date: moment(new Date()),
      operation_center:"",
      operation_type: "",
      productname: dataSource.urun_adi[0],
      order_id:null,
      orderitem_id:null,
      company_id:null,
      qty: 1,
      unit: "Adet",
      description: "Teslimat Stok Hareketi",
      price : 1,
      doviz : "$",
      component_type:"product",
      status:0
    });
    setState({
      ...state,
      stockRowModalActive: true
    });
  }
  //******************************************************************************************************************************************************************/
  const searchCompany = search => {
    let filteredList =[]; 
    filteredList =  companyList.filter(
        item => {return item.company_name.toLowerCase().includes(search.toLowerCase()) })
    if (search===""){
      setCompanies(companyList)
    }else{
      setCompanies(filteredList)
    }
  };
  //******************************************************************************************************************************************************************/
  const selectCompany = (company) => {
    let tempData= stockRowData;
    tempData.company_id=company._id;
    tempData.firma_adi=company.company_name;
    setstockRowData(tempData);
    setState({
      ...state,
      companyListModalActive:null
    });
  };
//******************************************************************************************************************************************************************/
  const onChangeProductModel = (e) =>{
    let tempdata = dataSource;
    let model = modelList.filter(option => option._id === (e) );
    tempdata.model_sira = model[0].model_sira;
    tempdata.model_category = model[0].model_kategori;
    tempdata.urun_model = e;

    setDataSource({
      ...dataSource,
      tempdata
    });

  }
  //******************************************************************************************************************************************************************/
  const updateData = (Field,eData) => {
    if(Field==="urun_adi"){
      let temp = dataSource.urun_adi;
      temp[lang]=eData;
      setDataSource({
        ...dataSource,
        urun_adi:temp
      });
    }else{
      //console.log(Field,eData)
      setDataSource({
        ...dataSource,
        [Field]:eData
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const updateSpecRowField =(Field,eData) => {
    let tempdata = dataSource
    if(Field==="urun_ozellik"){
      tempdata.urun_ozellikler[state.specModalIndex].urun_ozellik[lang] = eData;
    }else{
      tempdata.urun_ozellikler[state.specModalIndex][Field] = eData;
    }
    setDataSource({
      ...dataSource,
      tempdata
    });
  };
  //******************************************************************************************************************************************************************/
  const refreshDataSource = () => {
    dispatch((getProductDetails({id:productID})))
    .then(res=>{
      message.success(messages["alert.savesuccess"],  2 );
    });
  }
  //******************************************************************************************************************************************************************/
  const  mainImage = dataSource && dataSource.productImages && dataSource.productImages.length ? dataSource.productImages[0].image.image : blank;
  //******************************************************************************************************************************************************************/

  return (
    <>
      <PageHeader
        ghost
        title={dataSource && dataSource.urun_adi ? dataSource.urun_adi[lang]: "Yükleniyor"} 
        buttons={buttonList}
      />
      <Main>
        <MenuListWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={6} xl={7} lg={8} xs={24}>
              <DetailMenu 
                MenuList={MenuList} 
                data0={messages["model.kategori"+ dataSource.model_category]}
                data1={messages["genel.stocksum"]+" :"+(productDetail.productStock ? productDetail.productStock : "Hesaplanıyor.."  )}
                messages={messages} 
                image={mainImage} 
                imageField={"img"}
                imageWidth={100}
                tabID={tabID}
                beforeUpload={null}
                collapsed={collapsed}
                toggleCollapsed={toggleCollapsed}
                gotoPage={gotoPage}
                updateData={null}
              />
            </Col>
            <Col xxl={18} xl={17} lg={16} xs={24}>
              {state.tabID === "general" ? 
                <ProductGenerals
                  messages={messages}
                  lang={lang}
                  productDetailLoading={productDetailLoading}
                  product={dataSource}
                  CurrencyList={CurrencyList}
                  modelListOptions={modelListOptions}
                  updateData={updateData}
                  USDSatis={USDSatis}
                  EURSatis={EURSatis}
                  onDeleteProduct={onDeleteProduct}
                  onChangeProductModel={onChangeProductModel}
                />
              :state.tabID === "specifications" ? 
                <ProductSpecifications
                  messages={messages}
                  lang={lang}
                  productDetailLoading={productDetailLoading}
                  specifications={urun_ozellikler}
                  toggleModal={toggleModal}
                  addNewSpecRow={addNewSpecRow}
                  removeSpecRow={removeSpecRow}
                  productSpecificationsCopy={productSpecificationsCopy}
                  productSpecificationsPaste={productSpecificationsPaste}
                />
              :state.tabID === "operations" ?
                <ProductOperations
                  messages={messages}
                  lang={lang}
                  productDetailLoading={productDetailLoading}
                  productListLoading={productListLoading}
                  production={production}
                  productList={productList}
                  summary={summary ? Number(summary) : 0}
                  toggleModal={toggleModal}
                  calculateProductionList={calculateProductionList}
                  removeProRow={removeProRow}
                  addNewProRow={addNewProRow}
                  productProductionPaste={productProductionPaste}
                  productProductionCopy={productProductionCopy}
                />
              :state.tabID === "images" ?
                <ProductImages
                  messages={messages}
                  lang={lang}
                  productDetailLoading={productDetailLoading}
                  images={productImages}
                  removeImageRow={removeImageRow}
                  addImageRow={addImageRow}
                  toggleModal={toggleModal}
                />
              :state.tabID ==="stock" ?
                <StockOperations
                  messages={messages}
                  lang={lang}
                  stockList={StockOpList}
                  loading={productDetailLoading}
                  removeStockRow={removeStockRow}
                  toggleModal={toggleModal}
                  addNewStockRow={addNewStockRow}
                />
              :null}
            </Col>
          </Row>
        </MenuListWrapper>
      </Main>
      {state.specModalActive && 
        <SpecModal 
          messages={messages}
          lang={lang}
          specification={state.specModalIndex !== null ? urun_ozellikler[state.specModalIndex]:null}
          specModalActive={state.specModalActive}
          updateSpecRowField={updateSpecRowField}
          toggleModal={toggleModal}
        />}
        {state.proModalActive && 
        <ProModal 
          messages={messages}
          CurrencyList={CurrencyList}
          product={dataSource}
          state={state}
          updateProRow={updateProRow}
          toggleModal={toggleModal}
        />}
        {state.imageModalActive && 
        <ImageModal 
          messages={messages}
          image={state.imageModalIndex !== null ? productImages[state.imageModalIndex]:null}
          imageList={imageList}
          imageModalActive={state.imageModalActive}
          updateProRow={updateProRow}
          toggleModal={toggleModal}
          updateImageRow={updateImageRow}
        />}
        {state.stockRowModalActive && 
        <StockRowModal
          messages={messages}
          toggleModal={toggleModal}
          onSaveStockRow={onSaveStockRow}
          stockRowModalActive={state.stockRowModalActive}
          onChangeStockRowData={onChangeStockRowData}
          delivery={stockRowData}

        />}
        <CompanyListModal
          companyList={companies}
          messages={messages}
          lang={lang}
          loading={companyListLoading}
          companyListModalActive={state.companyListModalActive}
          searchCompany={searchCompany}
          selectCompany={selectCompany}
          toggleModal={toggleModal}
        />
    </>
  );
}



export  {ProductDetails};
