import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Modal,Select,Col,Row,Tag } from 'antd';
import { NumericFormat } from 'react-number-format';
import { FormWrapper } from '../../Style/style';
import {getProductList} from '../../../redux/productList/productListActions';

const ProModal = ({ product,state,messages,toggleModal,CurrencyList,updateProRow}) => {
  const {materialList,materialsListLoading,materialListStatus} = useSelector((state) => state.materials);
  const {proModalIndex,proModalActive} = state;
  const {modelDetails} = product;
  let production =proModalIndex !== null ? product.production[proModalIndex]:null;

  const dispatch = useDispatch();
  const [form2] = Form.useForm();
  let categories = null ;
  let operationlist = null ;
  let grupList = [];
  const operation  = Form.useWatch("op_name",form2);
  const category  = Form.useWatch("material_category",form2);

  React.useEffect(() => {
    operation === "Yarı Mamül" && dispatch(getProductList("list","model_category"));
   }, [category]);

   const { productList, loading } = useSelector((state) => state.productList);
   React.useEffect(() => {
    form2.setFieldsValue(production);
   }, [production]);
   let _productList = []; // Yarı Mamülleri listeleme
   if(proModalActive && 0<=proModalIndex){
    //Operasyonları Listeleme
    operationlist =modelDetails && modelDetails.productiondetail.map(y=>{return({label:y.Operation,value:y.Operation})})
    //Material Kategorileri Listeleme
    let categoriCode =production &&  production.op_name && modelDetails && modelDetails.productiondetail && modelDetails.productiondetail.filter(x=>x.Operation===production.op_name)[0].OperationComponent;
   
    if ( production && production.op_name && production.op_name !==""){
      if(operation==="Yarı Mamül"){
        categories = categoriCode.map(x=>{return({label:messages["model.kategori"+x],value:x})});
      }else{
        categories = categoriCode = categoriCode.map(x=>{return({label:x,value:x})});
      }
    };

    if(production && production.material_category && production.material_category !==""){
      if(operation==="Yarı Mamül"){
        productList && productList.length   && 
        productList.filter(x=>x.model_category===Number(category)).forEach(row => {
          _productList.push({
                label: row.urun_adi[0],
                value: row._id,
                price: row.urun_fiyat,
                doviz: row.urun_doviz,
            });
        }) ;
      }else{
        [...new Map(materialList.map(x=> [x.material_group,x])).values()].forEach((row,i)=> { 
          if  (production.material_category === row.material_category){
              grupList.push({
                  label: row.material_group,
                  value: row.material_group,
              });
          }
        }) 
      }
    }
   }

   //Material Grupları Listeleme

  // console.log(categories)
  const GroupFilterResult =  production && materialList.length && materialList.filter(option => option.material_category === production.material_category  && option.material_group === production.material_group )        
  return (
  <>
  {proModalActive && production &&
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={proModalActive}
      footer={null}
      onCancel={() => toggleModal("proModalIndex",null,null)}
      forceRender
      closable={false}
      
    >
        <FormWrapper style={{height:'auto'}}>
          <Form form={form2} initialValues={production} >
            <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["product.operation"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item name='op_name'>
                  <Select  options={operationlist && operationlist.length && operationlist} onSelect={(eData) => updateProRow('op_name',eData)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["material.category"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item name='material_category'>
                  {categories && categories.length && <Select options={categories} onSelect={(eData) => updateProRow('material_category',eData)}/>}
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["material.group"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item name='material_group'>
                  <Select options={operation === "Yarı Mamül" ? _productList :grupList} onSelect={(eData) => updateProRow('material_group',eData)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["genel.qty"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item >
                  <NumericFormat
                    className='NumberFormatClass'   
                    displayType={'input'}
                    thousandSeparator={'.'} 
                    decimalSeparator={','}  
                    value={production.materialqyt}  
                    onValueChange={(e)=>updateProRow("materialqyt",e.floatValue)} 
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle">
                <Col md={6} xs={24}>
                  <label htmlFor="input-text">{messages["genel.price"]}</label>
                </Col>
                <Col md={18} xs={24}>
                  <Row >
                    <Form.Item style={{ display: 'inline-block', width: 'calc(35% - 10px)' }} >
                      <NumericFormat
                        className='NumberFormatClass'   
                        thousandSeparator={'.'} 
                        decimalSeparator={','}  
                        decimalScale={4}
                        value={production.materialprice}  
                        onValueChange={(e)=>updateProRow("materialprice",e.floatValue)} 
                      />
                    </Form.Item>
                    <Form.Item name='materialcurrency' style={{ display: 'inline-block', width: 'calc(30% - 10px)',marginLeft: '10px' }} >
                      <Select  options={CurrencyList} onSelect={(eData) => updateProRow('materialcurrency',eData)}/>
                    </Form.Item>
                    <Form.Item style={{ display: 'inline-block', width: 'calc(35% )', marginLeft: '10px' }} >
                      <NumericFormat
                        className='NumberFormatClass'   
                        thousandSeparator={'.'} 
                        decimalSeparator={','}  
                        decimalScale={2}
                        suffix={" "+production.materialcurrency} 
                        value={production.rowTotal}  

                      />
                    </Form.Item>
                  </Row>
                </Col>
              </Row>

             {operation==="Yarı Mamül" ? 
             null
             : <div className="user-info">
                <h5 className="user-info__title">Kullanılabilecek Malzemeler</h5>
                <div className="user-info__skills">
                  {GroupFilterResult.length ?
                  GroupFilterResult.map((material,i)=>{return(
                    <p key={i} >
                      <Tag type="light" outlined={"true"} className="btn-outlined" style={{margin:'1px', width:'48%'}}>
                        {material.material_name}
                      </Tag> 
                      <Tag type="light" outlined={"true"} className="btn-outlined" style={{margin:'1px', width:'48%',textAlign:'right'}}>
                        {material.material_price.toFixed(4)+" "+material.material_doviz}
                      </Tag> 
                    </p>
                  )}):"** Eşleşen Malzeme Yok "}
                </div>
              </div>}
   
          </Form>
        </FormWrapper>
    </Modal>
  }
  </>
  );
}

export  {ProModal};
