import {
    GET_IMAGE_LIST,
    GET_IMAGE_LIST_SUCCESS,
	GET_IMAGE_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	data: [],
	loading:true
};

const imageList = (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_IMAGE_LIST: // GEL LIST 
			return { ...state, loading: true };
		case GET_IMAGE_LIST_SUCCESS:
            return { ...state, 
                loading: false, 
                data: action.response.img
            };
		case GET_IMAGE_LIST_ERROR:
			return { ...state, loading: false };
		default: {
			return state
		}
	}
}
export default imageList