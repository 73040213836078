import React from 'react';
import {PDFViewer} from '@react-pdf/renderer'
import {useIntl} from "react-intl";
import { useSelector, useDispatch } from 'react-redux';
import InvoiceT0 from "./OfferPDF/Type0/Invoice";
import InvoiceT1 from "./OfferPDF/Type1/Invoice";
import { Spin} from 'antd';
import { getCompany } from '../redux/company/myCompanyActions';

const OfferReport = ()=> {
  const dispatch = useDispatch();
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const {myCompany,myCompanyLoading} = useSelector(state => state.myCompany);
  !myCompany.bwlogo && dispatch(getCompany({id:1})) 
  const { data, loading } = useSelector(state => state.offerDetail);
  const {offerReportType } = useSelector((state) => state.App);

  const { messages } = useIntl();
  const Lang = locale === 'tr' ? 0 :1




    return !loading &&  !myCompanyLoading ?  (
        <div>
                  
          <PDFViewer  width="100%" height={600}>
            {Number(offerReportType)  === 1 ? 
                <InvoiceT1 data={data} company={myCompany} Lang={Lang} messages={messages} />
            :
                <InvoiceT0 data={data} company={myCompany} Lang={Lang} messages={messages}/>
            }
          </PDFViewer>      
        </div>
    ) : (
      <div className="spin">
        <Spin />
      </div>
    );
    
}
export {OfferReport}
