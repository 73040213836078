import React from 'react';
import {  Card,Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { ActivityContents } from '../../../Style/style';
import blank from '../../../../static/img/resyok.jpeg';


const  Specifications = ({product,lang,loading,messages,tabID}) => {
  return (
    <ActivityContents>
      {tabID==='product' ? 
      <Card>
        <ul className="activity-list">
          {product.urun_ozellikler ? product.urun_ozellikler.map((row,index)=>{
            return(
              <li className="activity-list__single" key={index}>
                <span className="activity-icon primary">
                  <FontAwesomeIcon icon={faCircleInfo} size='1x' />
                </span>
                <div className="activity-content">
                  <div className="activity-info">
                    <img src={blank} alt="" />
                    <span className="inline-text color-primary" style={{width:'250px'}}>{row.urun_ozellik[lang]}</span>{' '}
                    <p>
                      {lang===0 ? row.urun_aciklama.map((x,i)=>{
                        return(
                          <span className="hour" key={i}><Tag color='blue'>{x}</Tag></span>
                        )
                      }):
                        row.product_description.map((x,i)=>{
                        return(
                          <span className="hour" key={i}><Tag color='blue'>{x}</Tag></span>
                      )})}
                      
                    </p>
                  </div>
                </div>
              </li>
            )
          }):null}
        </ul>
      </Card>
      :tabID==='model' ? 
      <Card>
        <ul className="activity-list">
          {product.modelDetails ? product.modelDetails.ModelFeatures.map((row,index)=>{
            return(
              <li className="activity-list__single" key={index}>
                <span className="activity-icon primary">
                  <FontAwesomeIcon icon={faCircleInfo} size='1x' />
                </span>
                <div className="activity-content">
                  <div className="activity-info">
                    <img src={blank} alt="" />
                    <span className="inline-text color-primary" style={{width:'250px'}}>{row.label[lang]==="" || row.label[lang]==="-" ? row.label[lang===1?0:1]: row.label[lang]}</span>{' '}
                    <p>
                      <span className="hour">{row.feature[lang]==="" || row.feature[lang]==="-" ? row.feature[lang===1?0:1]: row.feature[lang]}</span>
                    </p>
                  </div>
                </div>
              </li>
            )
          }):null}
        </ul>
      </Card>
      :tabID==='downloads' ?
      <Card>
      <ul className="activity-list">
        {product.modelDetails ? product.modelDetails.modelDownloads.map((row,index)=>{
          return(
            <li className="activity-list__single" key={index}>
              <span className="activity-icon primary">
                <FontAwesomeIcon icon={faCircleInfo} size='1x' />
              </span>
              <div className="activity-content">
                <div className="activity-info">
                  <img src={blank} alt="" />
                  <span className="inline-text color-primary" style={{width:'250px'}}>{row.file_descirpiption[lang]}</span>{' '}
                  <p>
                    <a href={row.base64} target="_blank"><span className="hour">{row.name}</span></a>
                  </p>
                </div>
              </div>
            </li>
          )
        }):null}
        {product.productDownloads ? product.productDownloads.map((row,index)=>{
          return(
            <li className="activity-list__single" key={index}>
              <span className="activity-icon primary">
                <FontAwesomeIcon icon={faCircleInfo} size='1x' />
              </span>
              <div className="activity-content">
                <div className="activity-info">
                  <img src={blank} alt="" />
                  <span className="inline-text color-primary" style={{width:'250px'}}>{row.file_descirpiption[lang]}</span>{' '}
                  <p>
                    <a href={row.base64} target="_blank"><span className="hour">{row.name}</span></a>
                  </p>
                </div>
              </div>
            </li>
          )
        }):null}
      </ul>
    </Card>
    :null
      }
    </ActivityContents>
  );
}

export {Specifications};
