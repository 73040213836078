
import React from 'react';
import { useSelector } from 'react-redux';
import {Row,Col,Tag,Popconfirm,Button,Spin, Popover} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faCirclePlus, faChevronRight, faSpinner} from '@fortawesome/free-solid-svg-icons';
import { ListRowContentT5} from '../../Style/style';
import {PopupChoser} from '../../../components/PopupChoser';
import moment from 'moment';
const TaskList = ({messages,task,noMaterialCount,openStepModal,deleteTaskStepRow,handleChangeTasks,onDeleteTask,index}) => {
  const { taskDetailLoading } = useSelector((state) => state.taskDetails);
  const {taskTypeList,statusList}= useSelector(state => state.App);
  const {task_qty,task_steps,status,title,importance,tasktype,_id} = task;
  return (
    <>
      <ListRowContentT5>
        <Row gutter={10}>
          <Col xxl={4} lg={5} md={6} sm={24} xs={24}>
            <div className="list-column">
              <span className="activity-month">{task_qty>0 ? task_steps.reduce((acc, curr) => acc + parseInt(curr.step_qty, 10), 0)+" / " + task_qty + " " + messages["offer.OfferStatus2"]:""}</span>
              <h1>{title}</h1>
              <Tag className={status===0 ? "red":status===1 ? "blue":"green"} >{messages["task.status"+status]}</Tag>
              <Tag className={importance===0 ? "green":importance===1 ? "blue":"red"}>{messages["task.importance"+importance]} Öncelikli</Tag>
            </div>
          </Col>
          <Col xxl={4} lg={5} md={6} sm={24} xs={24}>
            <div className="list-column">
              <Tag className="blue" >{task.startDate ? moment(task.startDate).format("DD.MM.YYYY"):"--.--.--"}</Tag>
              <Tag className="green" >{task.endDate? moment(task.endDate).format("DD.MM.YYYY"):"--.--.--"}</Tag>
              <Tag className="yellow" >{(((task.endDate ? Date.parse(task.endDate):(Date.parse(new Date()))) - (Date.parse(task.startDate)))/(1000 * 3600 * 24)).toFixed()}{' '}Gün Geçti</Tag>
              {task &&  (task.tasktype===0 || task.task_steps.length) ? 
                null
                :
                <PopupChoser
                  className={task.status===0 ? "red":task.status===1 ? "blue":"green"}
                  option={statusList}
                  choseData={(e)=>handleChangeTasks("status",e,task,index)} 
                  selectedData={task.status}
                />
              }
              {(task.task_steps && task.task_steps.length ) || (task.title==="Malzeme Kontrol")? null :
                <PopupChoser
                  className={task.tasktype===0 ? "green":task.tasktype===1 ? "yellow":"blue"}
                  option={taskTypeList}
                  choseData={(e)=>handleChangeTasks("tasktype",e,task,index)}
                  selectedData={task.tasktype}
                />
              } 
            </div>
          </Col>
          <Col xxl={15} lg={12} md={10} sm={18} xs={24}>
            {task_steps && task_steps.length ? task_steps.map((step,i)=>{
              const {step_qty,step_person,step_title,step_date,stock_opereration,step_status} =step;
             // console.log(step)
              return(
                <div className="activity-single flex" key={i}>
                  <Button shape="circle" size='small'>
                    <FontAwesomeIcon icon={faChevronRight} style={{color:' #e85967'}} size='sm'/>
                  </Button>  
                  <div className="activity-title">
                      {step_title.slice(0,80)} <strong>{step_qty} {messages["genel.Adet"]}</strong> {step_person}
                      <p className="activity-timeMeta">{step_date ? moment(step_date).format("DD.MM.YYYY"):"--.--.--"} *** {stock_opereration && stock_opereration.length && stock_opereration.length + " Kalem Malzeme kullanıldı"}</p>
                  </div>
                  {status <  2 && i+1 === task_steps.length && title!=="Yarı Mamül" &&
                    <Popover content="Yeni İşlem Ekle" placement="left">
                      <Button shape="circle" size='small' style={{ marginLeft:'10px'}}>
                        <FontAwesomeIcon icon={faCirclePlus} onClick={()=>openStepModal(task)} size='sm'/>
                      </Button>  
                    </Popover>}
                  {(title!=="Yarı Mamül" || (step_status && title!=="Yarı Mamül"))  &&  
                    <Popconfirm
                      title={messages["alert.deletequest"]}
                      okText={messages["genel.yes"]}
                      cancelText={messages["genel.no"]}
                      onConfirm={()=>deleteTaskStepRow(task,i)}
                    >
                      <Button shape="circle" size='small' style={{ marginLeft:'10px'}}>
                        <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
                      </Button>  
                    </Popconfirm>
                  }
                </div>
              )
            }):          
              <div className="activity-single">
                {status < 2 && tasktype<1 && (noMaterialCount && noMaterialCount.length) < 1 && 
                  <Popover content="Yeni İşlem Ekle" placement="bottom">
                    <Button shape="circle" size='small'>
                      <FontAwesomeIcon icon={faCirclePlus} onClick={()=>openStepModal(task)} size='sm'/>
                    </Button>  
                  </Popover>
                }
              </div>         
            }
          </Col>
          <Col xxl={1} lg={2} md={2} sm={6} xs={24} className="action-column">
              {taskDetailLoading?
              <Spin indicator={faSpinner} size='small'/>
              :
              <Popconfirm
                title={messages["alert.deletequest"]}
                okText={messages["genel.yes"]}
                cancelText={messages["genel.no"]}
                onConfirm={()=>onDeleteTask(_id)}
              >
                <Button shape="circle">
                  <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
                </Button>  
              </Popconfirm>}
          </Col>
        </Row>
      </ListRowContentT5>
    </>

  );

}



export  {TaskList};
