import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus,faEdit,faTrash,faCopy,faPaste,faSave,faCalculator,faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { Table ,Button,Popover} from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';


const ProductOperations = ({production,productList,summary,productDetailLoading,messages,removeProRow,toggleModal,productListLoading,addNewProRow,productProductionCopy,productProductionPaste,onSaveProduct,calculateProductionList}) => {
  let operations = production && production.length && 
  production
  .map((el, idx) => ({key: idx, ...el}));
  const moreContent = (
    <>
      <NavLink to="#" onClick={()=>addNewProRow()}>
        <span><FontAwesomeIcon icon={faCirclePlus} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.add"]}</span>
      </NavLink>
      <NavLink to="#" onClick={()=>productProductionCopy()}>
        <span><FontAwesomeIcon icon={faCopy} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.copy"]}</span>
      </NavLink>
      <NavLink to="#" onClick={()=>productProductionPaste()}>
        <span><FontAwesomeIcon icon={faPaste} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.paste"]}</span>
      </NavLink>

    </>
  );

  const productColumn = [
    {
      title: [messages["material.category"] +" / "+messages["material.group"]],
      dataIndex: 'material_category',
      key: 'material_category', 
      align: 'left',
      render: (text,record) => record.op_name !=='Yarı Mamül' ? 
        <Popover content={record.op_name} placement="right">
            <h4>{text}</h4>
            <p>{record.material_group}</p>
        </Popover>
      :
        <Popover content={record.op_name} placement="right">
            <h4>{messages["model.kategori"+text]}</h4>
            <p>{productList && productList.length && productList.filter(x=>x._id===record.material_group).length && productList.filter(x=>x._id===record.material_group)[0].urun_adi[0]}</p>
        </Popover>,
      width: '27%',
    },
    {
      title: [messages["genel.qty"]],
      dataIndex: 'materialqyt',
      key: 'materialqyt',
      render: (text) => text ,
      width: '10%',
    },
    {
      title: [messages["genel.price"]],
      dataIndex: 'materialprice',
      key: 'materialprice',
      render: (text,record) => text ? Number(text).toFixed(2) +" "+record.materialcurrency : 0 ,
      width: '13%',
    },
    {
      title: [messages["genel.totalprice"]],
      dataIndex: 'rowTotal',
      key: 'rowTotal',
      render: (text,record) => (text ? text.toFixed(2):0) +" $",
      width: '13%',
    },
    {
      title: 
        <Button style={{marginRight:'10px'}} onClick={()=>calculateProductionList()} disabled={productListLoading}>
          <FontAwesomeIcon icon={faCalculator} style={{marginRight:"5px",color:'#9299B8'}} size='sm'/>
          <FontAwesomeIcon icon={faDollarSign} size='sm'  style={{marginRight:"10px",color:'#9299B8'}} />{summary.toFixed(2)}
        </Button>
      ,
      dataIndex: 'action',
      key: 'action',
      width: '17%',
      render: (text,record) => 
      <>
        <Button
          shape="circle"
          size="small"
          style={{marginRight:"5px"}}
          onClick={()=>toggleModal("proModalIndex",record.key)}
        >
          <FontAwesomeIcon icon={faEdit} style={{color:'#9299B8'}} size='sm'/>
        </Button > 
        <Button
          shape="circle"
          size="small"
          onClick={()=>removeProRow(record.key)}
        >
          <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
        </Button>  
      </>
    }
  ];

  return (
    <div className="full-width-table">
      <Cards
        title={messages["product.production"]}
        size="large"
        bodypadding="0px"
        more={moreContent}
      >
        <div className="table-bordered top-seller-table table-responsive" style={{minHeight:'730px'}}>
          <Table 
            columns={productColumn} 
            dataSource={operations} 
            pagination={false} 
            loading={productDetailLoading}
            scroll={{ y: 660 }}
          />
        </div>
      </Cards>
    </div>
  );
}

export {ProductOperations};
