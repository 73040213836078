import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink,useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPrint, faRefresh, faHome} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import { Cards } from '../../components/cards/frame/cards-frame';
import { Scrollbars } from 'react-custom-scrollbars';
import { Row, Col, Input,message,Spin,Button,Badge,notification,Popover} from 'antd';
import { Main } from '../Style/styled';
import blank from '../../static/img/resyok.jpeg';
import { MenuListWrapper,ListWrapper,SearchListSidebar,ListContent} from '../Style/style';
import { PageHeader } from '../../components/page-headers/page-headers';
import {getModelList} from '../../redux/modelList/modelListActions';
import {ListMenu} from "../commonComponent/listMenu";
import appActions from '../../redux/app/actions';

const  Models =()=> {
  const { modelList, modelListLoading,modelListStatus } = useSelector((state) => state.modelList);
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const rtl = useSelector(state => state.ChangeLayoutMode.rtlData);
  const [dataSource, setDataSource] = React.useState(modelList);
  const {categoryList,modelFilter} = useSelector(state => state.App);
  let history = useHistory();

  const { messages } = useIntl();
  const dispatch = useDispatch();
  const lang = locale === 'tr' ? 0 :1;
  const left = !rtl ? 'left' : 'right';
  const { setModelFilter } = appActions;
  const [state, setState] = React.useState({
    visible: false,
    collapsed: false
  });
  const { collapsed } = state;
  const buttonList = [
    <div key={0}>
      <Popover content= {messages["button.refresh"]} placement="bottom">
        <Button shape="circle"  onClick={()=>refreshDataSource()} size='large' icon={<FontAwesomeIcon icon={faRefresh} color="#0328fc"/>} style={{marginRight:'10px'}}/>
      </Popover>           
      <Popover content= {messages["button.print"]} placement="bottom">
        <Button shape="circle"  size='large' icon={<FontAwesomeIcon icon={faPrint} />} style={{marginRight:'10px'}}/>
      </Popover> 
      <Popover content= {messages["sidebar.dashboard"]} placement="bottom">
        <NavLink key={4} to="/admin" className="project-edit">
          <Button shape="circle" type='primary' size='large' icon={<FontAwesomeIcon icon={faHome}/>}/>
        </NavLink>
      </Popover>
    </div>
  ];

  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(modelFilter==="Tümü"){
       dispatch(getModelList("list",""))
       .then(res=>{
          message.success(messages["alert.savesuccess"], 2 );
        })
    }else{
      dispatch(getModelList("category",modelFilter))
      .then(res=>{
        message.success(messages["alert.savesuccess"], 2 );
      })
    }
  },[]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    setDataSource(modelList)
  }, [modelList]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(modelListStatus===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
  }, [modelListStatus]);
  //******************************************************************************************************************************************************************/
  const onSearchKey = search => {
    let filteredList =[]; 
    filteredList =  modelDetail.filter(
        item => {return item.model_adi[lang].toLowerCase().includes(search.toLowerCase()) })
    if (search===""){
      setDataSource(modelDetail)
    }else{
      setDataSource(filteredList)
    }
  };
  //******************************************************************************************************************************************************************/
  const onChangeFilter = (filterVal) =>{
    if(filterVal==="Tümü"){
      dispatch(getModelList("list",""));
    }else{
      dispatch(getModelList("category",filterVal));
    }
    dispatch(setModelFilter(filterVal));
    setState({
      ...state,
      collapsed:false
    });
  }
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !collapsed,
    });
  };
  //******************************************************************************************************************************************************************/
  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  //******************************************************************************************************************************************************************/
  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };
  //******************************************************************************************************************************************************************/
  const NewModel = () => {
    history.push("/admin/modeldetails/new")
  }
  //******************************************************************************************************************************************************************/
  return (
    <>
      <PageHeader
        title={messages["sidebar.models"]}
        buttons={buttonList}
      />
      <Main>
        <MenuListWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={6} xl={7} lg={8} xs={24}>
                <ListMenu 
                  MenuList={categoryList} 
                  MenuList2={null} 
                  messages={messages} 
                  activeFilter={modelFilter} 
                  activeFilter2={null} 
                  newData={NewModel}       
                  collapsed={collapsed}
                  toggleCollapsed={toggleCollapsed}
                  onChangeFilter={onChangeFilter}
                  onChangeFilter2={null}
                />
            </Col>
            <Col xxl={18} xl={17} lg={16} xs={24}>
              <SearchListSidebar>
                  <Cards headless>
                    <div className="chatbox-search">
                      <Input  style={{width:'100%'}} onKeyDown={(e)=>onSearchKey(e.target.value)} placeholder={messages["genel.ara"]} />
                    </div>
                    <Spin spinning={modelListLoading} delay={100}>
                      <ListContent style={{height:'710px'}}>
                        <Scrollbars
                          className="custom-scrollbar"
                          autoHide
                          autoHideTimeout={500}
                          autoHideDuration={200}
                          renderThumbHorizontal={renderThumbHorizontal}
                          renderThumbVertical={renderThumbVertical}
                          renderView={renderView}
                          renderTrackVertical={renderTrackVertical}
                        >
                          <ListWrapper>
                            <ul>
                              {dataSource &&
                                dataSource
                                  .sort((a, b) => {
                                    return  a.model_sira - b.model_sira;
                                  })
                                  .map((model, key) => {
                                    const { model_adi,model_kategori,_id,model_kodu,model_durumu,imageData } = model;
                                    return (
                                      <li key={key} className="chat-link-signle" >
                                        <NavLink to={`/admin/modeldetails/${_id}`}>
                                          <div className="author-figure">
                                            <img src={imageData ? imageData:blank} alt="" />
                                            <span className={false ? 'active' : 'inactive'} />
                                          </div>
                                          <div className="author-info">
                                            <span className="author-name" style={{display: 'block'}}>{model_adi[lang]}</span>
                                            <span className="author-chatText" >
                                            {messages["model.kategori"+model_kategori]}
                                            </span>
                                          </div>
                                          <div className="author-chatMeta">
                                            <span>{model_kodu}</span>
                                            <Badge className={model_durumu? "badge-success":"badge-danger"} count={messages["genel."+model_durumu]} />
                                          </div>
                                        </NavLink>
                                      </li>
                                    );
                                  })}
                            </ul>
                          </ListWrapper>
                        </Scrollbars>
                      </ListContent>
                    </Spin>
                  </Cards>
                </SearchListSidebar>
            </Col>
          </Row>
        </MenuListWrapper>
      </Main> 

    </>
  );
}



export  {Models};
