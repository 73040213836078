import {
	PRODUCT_DETAILS_ACTION,
	PRODUCT_DETAILS_SUCCESS,
	PRODUCT_DETAILS_ERROR,
	CREATE_PRODUCT_ACTION,
	CREATE_PRODUCT_SUCCESS,
	CREATE_PRODUCT_ERROR,
	UPDATE_PRODUCT_ACTION,
	UPDATE_PRODUCT_SUCCESS,
	UPDATE_PRODUCT_FAILURE,
	DELETE_PRODUCT_ACTION,
	DELETE_PRODUCT_SUCCESS,
	DELETE_PRODUCT_FAILURE
} from '../actions';

const INIT_STATE = {
	productDetail: {},
	productDetailLoading:false,
	productDetailStatus:null
};

const productDetails  = (state = INIT_STATE, action) => {
	switch (action.type) {
		case PRODUCT_DETAILS_ACTION: //GET OFFER DETAILS
			return { 
				...state, 
				productDetailLoading: true,
				productDetailStatus:null,
				productDetail:{}
			};
		case PRODUCT_DETAILS_SUCCESS:
            return { 
				...state,
				productDetailLoading: false,  
				productDetail: action.response,
				productDetailStatus:200
				};
		case PRODUCT_DETAILS_ERROR:
            return { 
				...state, 
				productDetailLoading: false ,
				productDetailStatus:action.response
			};	
		case CREATE_PRODUCT_ACTION: //CREATE OFFER
			return { 
				...state, 
				productDetailLoading: true,
				productDetailStatus:null
			};
		case CREATE_PRODUCT_SUCCESS:
			return { 
				...state, 
				productDetailLoading: false, 
				productDetail: action.response,
				productDetailStatus:200
			};
		case CREATE_PRODUCT_ERROR:
			return { 
				...state, 
				productDetailLoading: false,
				productDetail:null,
				productDetailStatus:action.response 
			};	
		case UPDATE_PRODUCT_ACTION: //UPDATE OFFER
			return { 
				...state, 
				productDetailLoading: true,
				productDetailStatus:null
			};
		case UPDATE_PRODUCT_SUCCESS:
			return { 
				...state, 
				productDetailLoading: false, 
				productDetail: action.response,
				productDetailStatus:200
			};
		case UPDATE_PRODUCT_FAILURE:
			return { 
				...state, 
				productDetailLoading: false,
				productDetailStatus:action.response 
			}; 
		case DELETE_PRODUCT_ACTION: //UPDATE OFFER
			return { 
				...state, 
				productDetailLoading: true, 
				productDetailStatus:null
			};
		case DELETE_PRODUCT_SUCCESS:
			return { 
				...state, 
				productDetailLoading: false,
				productDetailStatus:200
			};
		case DELETE_PRODUCT_FAILURE:
			return { 
				...state, 
				productDetailLoading: false,
				productDetailStatus:action.response 
			};
		default: return state;
	}
}
export default productDetails
