import React from 'react';
import { Col,Row, Spin, Form, Input,Select,Switch, Divider } from 'antd';
import {currencyFormatter} from '../../../helpers/currencyFormat'
import { FormWrapper } from '../../Style/style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import Heading from '../../../components/heading/heading';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const ProductGenerals =({product,productDetailLoading,lang,messages,updateData,modelListOptions,CurrencyList,EURSatis,USDSatis,onChangeProductModel})=> {
  const [form3] = Form.useForm();
  React.useEffect(() => {
    form3.setFieldsValue(product)
   },[product])
   const values = Form.useWatch([], form3);
   let category = product.model_category;
   const [submittable, setSubmittable] = React.useState(false);
   React.useEffect(() => {
     form3.validateFields({ validateOnly: true }).then(
       () => {
         setSubmittable(true);
       },
       () => {
         setSubmittable(false);
       },
     );
   }, [values]);
   const TLPrice = Number(product.urun_doviz==="₺" ? Number(product.urun_fiyat).toFixed(2) : product.urun_doviz==="€" ? Number(product.urun_fiyat*EURSatis).toFixed(2) : Number(USDSatis*product.urun_fiyat).toFixed(2));
  return (

    <Cards
      title={<Heading as="h5">{messages["product.generalspecs"]}</Heading>}
      loading={productDetailLoading && <Spin indicator={faSpinner}  />}
      bodyStyle={{ minHeight: '730px' }}
    >
      <FormWrapper>
        <Spin spinning={productDetailLoading} delay={500}>
          <Form form={form3} initialValues={product}>
            <Row align="middle" gutter={10}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["genel.sira"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item name="urun_sira"  >
                  <Input type='number' onChange={(eData)=>updateData("urun_sira",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle" gutter={10}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["product.model"]}</label>
              </Col>
              <Col md={9} xs={12}>
                <Form.Item name={['modelDetails', `model_kodu`]} >
                  <Input readOnly/>
                </Form.Item>
              </Col>  
              <Col md={9} xs={12}>
                <Form.Item name="urun_model" rules={[{required: true, message: 'Please input Model Code!' }]} >
                  <Select options={modelListOptions} onChange={(e)=>onChangeProductModel(e)} />
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle" gutter={10}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["product.name"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item name={['urun_adi', `${lang}`]} rules={[{ required: true, message: 'Please input Product NAME!' }]}>
                  <Input onChange={(eData)=>updateData("urun_adi",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" gutter={10}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["product.dimensions"]}/{messages["product.weight"]} </label>
              </Col>
              <Col md={9} xs={12}>
                  <Form.Item name='product_dimensions'>
                    <Input onChange={(eData)=>updateData("product_dimensions",eData.target.value)}/>
                  </Form.Item>
              </Col>
              <Col md={9} xs={12}>
                  <Form.Item name='product_weight'>
                    <Input type='number' onChange={(eData)=>updateData("product_weight",eData.target.value)} addonAfter={" Kg"}/>
                  </Form.Item>
              </Col>
            </Row>
            <Row align="middle" gutter={10}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["product.power"]}/{messages["product.lumen"]} </label>
              </Col>
              <Col md={9} xs={12}>
                  {category <= 7 ? 
                  <Form.Item name='urun_guc'>
                    <Input type='number'  onChange={(eData)=>updateData("urun_guc",eData.target.value)} addonAfter={" W"}/>
                  </Form.Item>
                  :category === 8 ?
                  <Form.Item name='urun_kapasite'>
                    <Input type='number' onChange={(eData)=>updateData("urun_kapasite",eData.target.value)} addonAfter={" Ah"} />
                  </Form.Item>
                  :null}
              </Col>
              <Col md={9} xs={12}>
                  {category < 7  ? 
                    <Form.Item name='urun_lumen'>
                      <Input type='number' addonAfter={" lm"}  onChange={(eData)=>updateData("urun_lumen",eData.target.value)}/>
                    </Form.Item>
                  :category ===7 ?
                    <Form.Item name='urun_voltaj' >
                      <Input type='number' addonAfter={" V"}  onChange={(eData)=>updateData("urun_voltaj",eData.target.value)}/>
                    </Form.Item>
                  :category === 8 ?
                    <Form.Item name='urun_voltaj'>
                      <Input type='number' addonAfter={" V"}  onChange={(eData)=>updateData("urun_voltaj",eData.target.value)}/>
                    </Form.Item>
                  :null
                  }
              </Col>
            </Row>
            <Row align="middle" gutter={10}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["genel.price"]}</label>
              </Col>
              <Col md={6} xs={12}>
                <Form.Item name='urun_fiyat' >
                  <Input type='number' decimalseparator=',' onChange={(eData)=>updateData("urun_fiyat",eData.target.value)}/>
                </Form.Item>
              </Col>
              <Col md={6} xs={12}>
                <Form.Item name='urun_doviz' >
                  <Select  options={CurrencyList} onSelect={(eData)=>updateData("urun_doviz",eData)}/>
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item >
                  <Input readOnly decimalseparator=',' addonAfter={" ₺"} value={TLPrice && currencyFormatter(TLPrice)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" gutter={10}>
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["product.code"]}</label>
              </Col>
              <Col md={5} xs={6}>
                  <Form.Item name='urun_kodu'>
                    <Input onChange={(eData)=>updateData("urun_kodu",eData.target.value)} />
                  </Form.Item>
              </Col>
              {category < 7 ?
                <>
                  <Col md={3} xs={6}>             
                    <Form.Item name='urun_optik'>
                      <Input onChange={(eData)=>updateData("urun_optik",eData.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={6}>
                    <Form.Item name='urun_cct' >
                      <Input onChange={(eData)=>updateData("urun_cct",eData.target.value)} />
                    </Form.Item>
                    </Col>
              
                    {category === 3 ? 
                      <>
                      <Col md={3} xs={6}>
                        <Form.Item name='urun_panel'  >
                          <Input onChange={(eData)=>updateData("urun_panel",eData.target.value)} />
                        </Form.Item>
                      </Col>
                      <Col md={3} xs={6}>
                        <Form.Item name='urun_aku'  >
                          <Input onChange={(eData)=>updateData("urun_aku",eData.target.value)} />
                        </Form.Item>
                      </Col>
                      </>
                    :null}
                </>
                :null}
            </Row>

            <Divider/>
            <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["product.status"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Switch checked={product.product_active} onChange={(eData)=>updateData("product_active",eData)} />
              </Col>
            </Row>



          </Form>
        </Spin>
      </FormWrapper>

    </Cards>
  );
}

export  {ProductGenerals};
