import React from 'react';
import { View, StyleSheet,Image ,Text} from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const styles = StyleSheet.create({
    HeaderContainer:{
        fontFamily:'SourceSansPro',
        marginTop: 1,
        marginBottom: 1,
        flexDirection: "row",
        borderBottomWidth: 2,
        width:550,
        height:45,
        borderColor:"black"
    },
    AdressContainer:{
        marginLeft:20,
        marginTop: 1,
        flexDirection: "column",
        width:185,
    },
    ContactContainer:{
        marginTop: 1,
        marginLeft:20,
        flexDirection: "column",
        width:175,
    },
    logo: {
        width: 150,
        height: 42,
        marginLeft: '0',
        
    },  

});
const CompanyName = styled(Text)`
margin: 0px;
font-size: 10px;
text-align: center;
borderBottomWidth:1;
`;
const Email = styled(Text)`
margin: 0px;
font-size: 8px;
text-align: center;
color: #004678;
`;
const Web = styled(Text)`
margin: 0px;
font-size: 8px;
text-align: center;
color: #004678;
`;
const Adres = styled(Text)`
margin: 1px;
font-size: 8px;
text-align: right;

`;
const Adres2 = styled(Text)`
margin: 1px;
font-size: 8px;
text-align: right;
color: #004678;
`;

  const InvoiceTitle = ({company,messages}) => {
    return(
    <View style={styles.HeaderContainer}>

        <Image style={styles.logo} src={company.sirket_rapor_logo}/>
        <View style={styles.ContactContainer}>
            <CompanyName>{company.sirket_adi}</CompanyName>
            <Email>{messages["company.e-mail"]} : {company.sirket_email}</Email>
            <Web>{company.sirket_web} </Web>
        </View>
        <View style={styles.AdressContainer}>
            <Adres>{messages["company.address"]}</Adres>
            <Adres2>{company.sirket_adres +" "+company.sirket_ilce+" "+company.sirket_il}</Adres2>
            <Adres2>{messages["company.mobilephone"]} : {company.sirket_tel} {messages["company.fax"]} : {company.sirket_fax} </Adres2>
        </View>

       
    </View>
  )};
  
  export default InvoiceTitle