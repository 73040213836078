import React from 'react';
import { Table ,Button,Tag} from 'antd';
import { NavLink } from 'react-router-dom';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit,faTrash,faCirclePlus,faCopy,faPaste} from '@fortawesome/free-solid-svg-icons';

const ProductSpecifications = ({specifications,messages,lang,productDetailLoading,removeSpecRow,toggleModal,addNewSpecRow,productSpecificationsCopy,productSpecificationsPaste,onSaveProduct}) => {
  let specs = specifications && specifications.length && specifications.map((el, idx) => ({key: idx, ...el}))
  const moreContent = (
    <>
      <NavLink to="#" onClick={()=>addNewSpecRow()}>
        <span><FontAwesomeIcon icon={faCirclePlus} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.add"]}</span>
      </NavLink>
      <NavLink to="#" onClick={()=>productSpecificationsCopy()}>
        <span><FontAwesomeIcon icon={faCopy} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.copy"]}</span>
      </NavLink>
      <NavLink to="#" onClick={()=>productSpecificationsPaste()}>
        <span><FontAwesomeIcon icon={faPaste} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.paste"]}</span>
      </NavLink>

    </>
  );

  const specColumn = [

    {
      title: [messages["model.label"]],
      dataIndex: 'urun_ozellik',
      key: 'urun_ozellik',
      align: 'left',
      render: (text) => text[lang],
      width: '20%',
    },
    {
      title: [messages["product.specifications"]]+" TR",
      dataIndex: 'urun_aciklama',
      key: 'urun_aciklama',
      render: (text) => 
      <p>
        {text.map((x,y)=>{return(<Tag color="#f50" key={y}>{x}</Tag>)})}
      </p> ,
      width: '30%',
    },
    {
      title: [messages["product.specifications"]]+" EN",
      dataIndex: 'product_description',
      key: 'product_description',
      render: (text) => <p>{text.map((x,y)=>{return(<Tag color="#108ee9" key={y}>{x}</Tag>)})} </p>,
      width: '30%',
    },
    {
      title: "",
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      render: (text,record) => 
      <>
        <Button
          shape="circle"
          size="small"
          style={{marginRight:"5px"}}
          onClick={()=>toggleModal("specModalIndex",record.key)}
        >
          <FontAwesomeIcon icon={faEdit} style={{color:'#9299B8'}} size='sm'/>
        </Button > 
        <Button
          shape="circle"
          size="small"
          onClick={()=>removeSpecRow(record.key)}
        >
          <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
        </Button>  
      </>
    }
  ];

  return (
    <div className="full-width-table" >
      <Cards
        title={messages["product.specifications"]}
        size="large"
        bodypadding="0px"
        more={moreContent}
      >
        <div className="table-bordered top-seller-table table-responsive" style={{minHeight:'730px'}}>
          <Table 
            columns={specColumn} 
            dataSource={specs} 
            pagination={false} 
            loading={productDetailLoading}
            scroll={{ y: 660 }}
          />
        </div>
      </Cards>
    </div>
  );
}


export  {ProductSpecifications};
