import React from 'react';
import { Spin, Form, Input,Radio,Row,Col } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { FormWrapper } from '../../Style/style';
import Heading from '../../../components/heading/heading';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const CompanyGenerals =({company,loading,messages,updateData,setSubmittable})=> {
  const [form7] = Form.useForm();
  React.useEffect(() => {
    form7.setFieldsValue(company)
   },[company])
   const values = Form.useWatch([], form7);
   React.useEffect(() => {
     form7.validateFields({ validateOnly: true }).then(
       () => {
         setSubmittable(true);
       },
       () => {
         setSubmittable(false);
       },
     );
   }, [values]);
  return (
    <Cards
      title={<Heading as="h5">{messages["company.profile"]}</Heading>}
      loading={loading && <Spin indicator={faSpinner}  />}
      bodyStyle={{ minHeight: '730px' }}
    >
      <FormWrapper>
        <Spin spinning={loading} delay={100}>
          <Form form={form7} initialValues={company} onFinish={(eData) => updateGenerals(eData)} >
            <Form.Item name="company_name"  rules={[{ required: true, message: 'Please input Company NAME!' }]} label={messages["company.name"]}>
              <Input onChange={(eData)=>updateData("company_name",eData.target.value)}/>
            </Form.Item>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.address"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="company_adress" rules={[{ required: true, message: 'Please input Company ADDRESS!' }]}>
                  <Input onChange={(eData)=>updateData("company_adress",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.town"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="company_district" >
                  <Input onChange={(eData)=>updateData("company_district",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.city"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="company_city" >
                  <Input onChange={(eData)=>updateData("company_city",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.pk"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="" >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.country"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item name="company_country" >
                  <Input onChange={(eData)=>updateData("company_country",eData.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.type"]}</label>
              </Col>
              <Col md={16} xs={24}>
                  <Form.Item>
                    <Radio.Group value={company.company_type} onChange={(eData)=>updateData("company_type",eData.target.value)} size='small'>
                      <Radio value="real">{messages["company.real"]}</Radio>
                      <Radio value="legal">{messages["company.legal"]}</Radio>
                    </Radio.Group>
                  </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={8} xs={24}>
                <label htmlFor="input-text">{messages["company.category"]}</label>
              </Col>
              <Col md={16} xs={24}>
                <Form.Item>
                  <Radio.Group onChange={(eData)=>updateData("company_category",eData.target.value)} value={company.company_category}>
                    <Radio value="Suppliers">{messages["company.Suppliers"]}</Radio>
                    <Radio value="Customer">{messages["company.Customer"]}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </FormWrapper>
    </Cards>
  );
}

export  {CompanyGenerals};
