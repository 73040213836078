import React from 'react';
import { Button,Badge,Upload } from 'antd';
import { DetailMenuListContent,DetailMenuNav} from '../Style/style';
import { NavLink} from 'react-router-dom';
import { Cards } from '../../components/cards/frame/cards-frame';
import ImgCrop from 'antd-img-crop';
import Heading from '../../components/heading/heading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAlignRight,faX,faAlignLeft,faUserPen,faUpload, faTrash} from '@fortawesome/free-solid-svg-icons';

const DetailMenu = ({MenuList, messages,image,imageWidth,imageField,data0,data1,data2,toggleCollapsed,tabID,collapsed,gotoPage,beforeUpload,updateData}) => {
  
  return (
  <>
    {window.innerWidth <= 991 && (
      <Button type="link" className="mail-sidebar-trigger" style={{ marginTop: 0 }} onClick={toggleCollapsed}>
        <FontAwesomeIcon icon={collapsed ? faAlignLeft : faAlignRight} />
      </Button>
    )}
    {window.innerWidth > 991 ? (
      <div className="sidebar-card">
        <Cards headless bodyStyle={{ minHeight: '780px'}}>
          <div className="note-sidebar-bottom">
            <DetailMenuNav>
              <div className="author-info">
                <figure>
                  <img src={image} alt="" height={imageWidth}/>
                </figure>
                {beforeUpload && 
                <ImgCrop rotationSlider>
                  <Upload
                    showUploadList={false}
                    beforeUpload={(file)=>beforeUpload(file)}
                  >
                      <Button shape='circle'>
                        <FontAwesomeIcon icon={faUpload} />
                      </Button>
                  </Upload>
                </ImgCrop>}
                {image && beforeUpload &&
                <Button shape='circle' onClick={() => updateData(imageField,null)} style={{marginLeft:"10px",marginBottom:"5px"}}>
                  <FontAwesomeIcon icon={faTrash}/>
                </Button>}
                <figcaption>
                    <Heading as="h6">{data0 && data0.substring(0,26) }</Heading>
                    <p>{data1}</p>
                </figcaption>
              </div>
              <div className="nav-labels">
                <p style={{marginBottom:'5px',marginTop:'5px'}}><Badge color={'green'}  status="processing" text={messages["genel.sekmeler"]}/></p>
                <ul>
                  <DetailMenuListContent style={{height:'450px'}}>
                    {MenuList && MenuList.length>0 ? MenuList.map((menu,index)=>{
                      return(
                        <li key={index}>
                          <NavLink to='#' onClick={()=>gotoPage(menu.tabID)} activeClassName={tabID === menu.tabID ? "active":""}>
                            <FontAwesomeIcon icon={faUserPen} size={"1x"} />
                            {menu.MenuName}
                          </NavLink>
                        </li>
                      )
                    }):null}
                  </DetailMenuListContent>
                </ul>
              </div>
            </DetailMenuNav>
          </div>
        </Cards>
      </div>
    ) : (
      <div className={collapsed ? 'sidebar-card note-sideabr show' : 'sidebar-card note-sideabr hide'}>
        <Cards headless bodyStyle={{ minHeight: '780px'}}>
          <Button
            type="link"
            className="mail-sidebar-trigger trigger-close"
            style={{ marginTop: 0 }}
            onClick={toggleCollapsed}
          >
            <FontAwesomeIcon icon={faX} />
          </Button>
          <div className="note-sidebar-bottom">
            <DetailMenuNav>
              <div className="author-info">
                <figure>
                  <img src={image} alt="" width={imageWidth}/>
                </figure>
                  <ImgCrop rotationSlider>
                    <Upload
                      showUploadList={false}
                      beforeUpload={(file)=>beforeUpload(file)}
                    >
                        <Button shape='circle' >
                          <FontAwesomeIcon icon={faUpload} />
                        </Button>
                    </Upload>
                  </ImgCrop>

                  {image && 
                  <Button shape='circle' onClick={() => updateData(imageField,null)} style={{marginLeft:"10px",marginBottom:"10px"}}>
                    <FontAwesomeIcon icon={faTrash}/>
                  </Button>}
                <figcaption >
                    <Heading as="h6">{data0 ? data0 :""}</Heading>
                    <p>{data1 ? data1 : "" }</p>
                </figcaption>
              </div>
              <div className="nav-labels">
                <p style={{marginBottom:'5px',marginTop:'5px'}}><Badge color={'green'}  status="processing" text={messages["genel.sekmeler"]}/></p>
                <ul>
                    {MenuList && MenuList.length>0 ? MenuList.map((menu,index)=>{
                      return(
                        <li key={index}>
                          <NavLink to='#' onClick={()=>gotoPage(menu.tabID)} activeClassName={tabID === menu.tabID ? "active":""}>
                            <FontAwesomeIcon icon={faUserPen} size={"1x"} />
                            {menu.MenuName}
                          </NavLink>
                        </li>
                      )
                    }):null}
                </ul>
              </div>
            </DetailMenuNav>
          </div>
        </Cards>
      </div>
    )}
  </>
  );
}

export  {DetailMenu};
