import React from 'react';
import { Input, Modal,Button,Form,Select,Row,Col, InputNumber,Tooltip,Radio } from 'antd';
import { BasicFormWrapper} from '../../Style/style';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {currencyFormatter} from '../../../helpers/currencyFormat';

const OfferRowModal = ({messages,offer,offeritem,offerRowModalActive,toggleModal,UnitList,CurrencyList,KDVList,onUpdateOfferRow,onChangeItemType,optikList,CCTList}) => {
  let doviz;let price;let qty;;let TopTL;let TopDoviz;
  let TLPrice = Number(offeritem.urun_dovizi==="₺" ? Number(offeritem.urun_fiyati).toFixed(2) : offeritem.urun_dovizi==="€" ? Number(offeritem.urun_fiyati*offer.teklif_eurkur ).toFixed(2) : Number(offer.teklif_usdkur *offeritem.urun_fiyati).toFixed(2));
  
  const [form8] = Form.useForm();
  const values = Form.useWatch([], form8);
  const urun_id = values && values.urun_id && values.urun_id;
  const category = urun_id && urun_id.model_category ? urun_id.model_category: (offeritem.urun_id && offeritem.urun_id.model_category ? offeritem.urun_id.model_category : offeritem.productCategory); 
  React.useEffect(() => {
    form8.setFieldsValue(offeritem)
   },[offer]);

   React.useEffect(() => {
    form8.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
    );
  }, [values]);
  const [submittable, setSubmittable] = React.useState(false);

  const onChange=()=>{
    doviz = form8.getFieldValue("urun_dovizi");
    price = form8.getFieldValue("urun_fiyati");
    qty = form8.getFieldValue("urun_miktari");
    TLPrice = Number(doviz==="₺" ? Number(price).toFixed(2) : doviz==="€" ? Number(price*offer.teklif_eurkur ).toFixed(2) : Number(offer.teklif_usdkur *price).toFixed(2));
    TopTL = TLPrice * qty;
    TopDoviz = offer.teklif_dovizi === "€" ? (TopTL / offer.teklif_eurkur) : ( TopTL / offer.teklif_usdkur);
    form8.setFieldsValue({'toplamDoviz':currencyFormatter(TopDoviz)});
    form8.setFieldsValue({'toplamTL':currencyFormatter(TopTL)});
  }

  return (
  <>
  {offeritem && offerRowModalActive && 
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={offerRowModalActive}
      footer={null}
      closable={false}
      //onCancel={() => toggleModal("materialListModalActive",null)}
      width={800}
      forceRender
    >
        <BasicFormWrapper>
          <div className="sDash_addTask-modal-inner-modal">
            <Form form={form8} onValuesChange={()=>onChange()} onFinish={(eData)=>onUpdateOfferRow(eData)}>

              <div className="radio-size-wrap" style={{marginBottom:"20px"}}>
                <div>
                  <Radio.Group defaultValue={offeritem.order_type} onChange={(e)=>onChangeItemType(e.target.value)}>
                    <Radio value={"product"}>{messages["product.name"]}</Radio>
                    <Radio value={"material"}>{messages["material.material"]}</Radio>
                    <Radio value={"default"}>{messages["product.default"]}</Radio>
                  </Radio.Group>
                </div>
              </div>

              <Form.Item name="urun_adi" label={messages["product.name"]} rules={[{ required: true, message: 'Please input Product Name!' }]} >
                <Input  addonAfter={offeritem.order_type !== "default" && <FontAwesomeIcon icon={faMagnifyingGlass} size='1x' onClick={()=>toggleModal((offeritem.order_type==='product' ? "productListModalActive":"materialListModalActive"),true)} style={{marginRight:'20px'}}/>}/>
              </Form.Item>
              
              <Form.Item name='urun_id' hidden rules={[{ required: (offeritem.order_type === "product"), message: 'Lütfen Ürün Seçiniz!' }]} >
                <Input />
              </Form.Item>
              <Form.Item name='material_id' hidden rules={[{ required: (offeritem.order_type === "material"), message: 'Lütfen Malzeme Seçiniz!' }]}>
                <Input />
              </Form.Item>
              <Form.Item name={['urun_kodu', `${0}`]}  style={{ display: 'inline-block', width: 'calc(20% - 5px)' }} label={messages["product.modelcode"]}>
                <Input />
              </Form.Item>
              <Form.Item name={['urun_kodu', `${1}`]} style={{ display: 'inline-block', width: 'calc(20% - 5px)',marginLeft:'5px' }} label={messages["product.pcb"]}>
                <Input />
              </Form.Item>
              <Form.Item 
                name={['urun_kodu', `${2}`]} 
                style={{ display: 'inline-block', width: 'calc(15% - 5px)',marginLeft:'5px' }} label={messages["product.optik"]} 
                rules={[{ required: (offeritem.order_type === "product" && category<7), message: 'Lütfen Optik Regini Seçiniz!' }]}>
                <Select options={optikList} allowClear />
              </Form.Item>
              <Form.Item name={['urun_kodu', `${3}`]} style={{ display: 'inline-block', width: 'calc(15% - 5px)',marginLeft:'5px' }} label={messages["product.cct"]} rules={[{ required: (offeritem.order_type === "product" && category<7) , message: 'Lütfen Işık Rengi Seçiniz.!' }]}>
                <Select options={CCTList} allowClear/>
              </Form.Item>
              <Form.Item name={['urun_kodu', `${4}`]} style={{ display: 'inline-block', width: 'calc(15% - 5px)',marginLeft:'5px' }} label={messages["product.panel"]}>
                <Input />
              </Form.Item>
              <Form.Item name={['urun_kodu', `${5}`]} style={{ display: 'inline-block', width: 'calc(15% - 5px)',marginLeft:'5px' }} label={messages["product.battery"]}>
                <Input />
              </Form.Item>

              <Form.Item 
                name={['urun_kodu', `${6}`]}  
                style={{ display: 'inline-block', width: 'calc(35% - 5px)' }} 
                label={messages["product.bodycolor"]}
                rules={[
                  {required:( offeritem.order_type==="product" && category<7),message: 'Gövde Rengini yazın !'},
                  () => ({
                    validator(_, value) {
                      if (value && value.toLowerCase() === "standar" ||value && value.toLowerCase() === "standa" || "standart" === value &&  value.toLowerCase()) {
                        return Promise.reject(new Error('Standart diye RENK yok Silver Gri yazın!'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}>
                <Input />
              </Form.Item>
              <Form.Item name={['urun_kodu', `${7}`]} style={{ display: 'inline-block', width: 'calc(35% - 5px)',marginLeft:'5px' }} label={messages["product.ledmark"]}>
                <Input />
              </Form.Item>
              <Form.Item name={['urun_kodu', `${8}`]} style={{ display: 'inline-block', width: 'calc(30% - 5px)',marginLeft:'5px' }} label={messages["product.leddriver"]}>
                <Input />
              </Form.Item>



              <Form.Item name="urun_miktari" style={{ display: 'inline-block', width: 'calc(15% - 5px)' }} label={messages["genel.qty"]} rules={[{ required: true, message: 'Please input Product Qty!' },{type:'number'}]}>
                <InputNumber decimalSeparator=',' />
              </Form.Item>
              <Form.Item name="urun_birimi" style={{ display: 'inline-block', width: 'calc(15% - 5px)',marginLeft:'5px' }} label={messages["genel.unit"]}>
                <Select options={UnitList.map(x=>{return({label:messages[x.label],value:x.value})})} />
              </Form.Item>
              <Tooltip title={TLPrice+" ₺"}>
                <Form.Item name="urun_fiyati" style={{ display: 'inline-block', width: 'calc(15% - 5px)',marginLeft:'5px' }} label={messages["genel.price"]} rules={[{ required: true, message: 'Please input Product Price!' }]}>
                  <InputNumber decimalSeparator=','/>
                </Form.Item>
              </Tooltip>
              <Form.Item name="urun_dovizi" style={{ display: 'inline-block', width: 'calc(15% - 5px)',marginLeft:'5px' }} label={messages["genel.doviz"]}>
                <Select options={CurrencyList} />
              </Form.Item>
              <Form.Item name="urun_kdv" style={{ display: 'inline-block', width: 'calc(20% - 5px)',marginLeft:'5px' }} label={messages["genel.kdv"]}>
                <Select options={KDVList} />
              </Form.Item>
              <Form.Item name="toplamDoviz"  style={{ display: 'inline-block', width: 'calc(20% - 5px)',marginLeft:'5px' }} label={messages["genel.totalprice"]}>
                <InputNumber readOnly addonAfter={offer.teklif_dovizi}  decimalSeparator=','/>
              </Form.Item>
              <div className="sDash-modal-actions">
                <Button  type="white" onClick={() => toggleModal("offerRowModalActive",null)}  >
                  {messages["button.cancel"]}
                </Button>
                <Button   type="primary" htmlType="submit" style={{marginLeft:'10px'}} disabled={!submittable}>
                  {messages["button.save"]}
                </Button>
              </div>
            </Form>
          </div> 
        </BasicFormWrapper>
    </Modal>
  }
  </>
  );
}

export  {OfferRowModal};
