import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Modal,Button,Spin,Badge } from 'antd';
import { MenuListWrapper,SearchListSidebar,ListContent,ListWrapper} from '../Style/style';
import { NavLink} from 'react-router-dom';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Scrollbars } from 'react-custom-scrollbars';

const CompanyListModal = ({loading, messages,companyList,companyListModalActive,toggleModal,searchCompany,selectCompany}) => {
  
  
  const rtl = useSelector(state => state.ChangeLayoutMode.rtlData);
  const left = !rtl ? 'left' : 'right';

  const renderView = ({ style, ...reset }) => {
    const customStyle = {
      marginRight: 'auto',
      [rtl ? 'left' : 'right']: '2px',
      [rtl ? 'marginLeft' : 'marginRight']: '-19px',
    };
    return <div {...reset} style={{ ...style, ...customStyle }} />;
  };

  const renderThumbVertical = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };

  const renderThumbHorizontal = ({ style, ...reset }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...reset} />;
  };

  return (
  <>
  {companyList && companyListModalActive && 
    <Modal
      title={messages["sidebar.companies"]}
      type='primary'
      open={companyListModalActive}
      closable={false}
      footer={[
        <div key="1" className="project-modal-footer">
          <Button size="default" type="white"   onClick={() => toggleModal("companyListModalActive",null)}>
            {messages["button.cancel"]}
          </Button>
        </div>,
      ]}
      onCancel={() => toggleModal("companyListModalActive",null)}
      width={600}
      forceRender
    >
    
        <MenuListWrapper>
          <SearchListSidebar>
            <Cards headless>
              <div className="chatbox-search">
                <Input  style={{width:'100%'}} onKeyDown={(e)=>searchCompany(e.target.value)} placeholder={messages["genel.ara"]} />
              </div>
              <ListContent style={{height:'500px'}}>
                <Scrollbars
                  className="custom-scrollbar"
                  autoHide
                  autoHideTimeout={500}
                  autoHideDuration={200}
                  renderThumbHorizontal={renderThumbHorizontal}
                  renderThumbVertical={renderThumbVertical}
                  renderView={renderView}
                  renderTrackVertical={renderTrackVertical}
                >
                  <ListWrapper>
                    {loading ? 
                    <div className="spin"><Spin/></div>:
                    <ul>
                      {companyList &&
                        companyList.map((company, key) => {
                            const { company_name,company_adress,_id,company_city,company_district,company_country,company_img,company_category,company_status } = company;
                            return (
                              <li key={key} className="chat-link-signle" >
                                <NavLink to='#' onClick={()=>selectCompany(company)}>
                                  <div className="author-info">
                                    <span className="author-name" style={{display: 'block'}}>{company_name}</span>
                                    <span className="author-chatText" >
                                    {company_adress +" "+company_district+" "+company_city+" "+company_country}
                                    </span>
                                  </div>
                                  <div className="author-chatMeta">
                                    <Badge className={company_category==="Suppliers" ? "badge-success":"badge-danger"} count={messages["company."+company_category]} />
                                  </div>
                                </NavLink>
                              </li>
                            );
                          })}
                    </ul>}
                  </ListWrapper>
                </Scrollbars>
              </ListContent>
            </Cards>
          </SearchListSidebar>
        </MenuListWrapper>

   
    </Modal>
  }
  </>
  );
}

export  {CompanyListModal};
