import React, {  Fragment } from 'react';
import {PDFViewer} from '@react-pdf/renderer';
import { useSelector, useDispatch } from 'react-redux';
import {useIntl} from "react-intl";
import Label10x10 from "./Label10x10/label10x10";

import { getCompany } from '../redux/company/myCompanyActions';

const ProductBoxLabel = () => {
  const dispatch = useDispatch();
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const {myCompany,myCompanyLoading} = useSelector(state => state.myCompany);
  !myCompany.bwlogo && dispatch(getCompany({id:1})) 

  const {logoOpen}= useSelector(state => state.App);
  const { orderItemDetail, orderItemDetailLoading,orderItemDetailStatus } = useSelector(state => state.orderItemDetail);
  const { messages } = useIntl();
  const Lang = locale === 'tr' ? 0 :1
  return !orderItemDetailLoading &&  !myCompanyLoading ?  
  (
    <Fragment>
      {  myCompany && orderItemDetail ?                
      <PDFViewer width="100%" height={600}>
          {
            <Label10x10  company={myCompany} data={orderItemDetail} messages={messages} logoOpen={logoOpen}/>
          }
      </PDFViewer>:
            <div className="spin">
              <Spin />
            </div>
          }       
    </Fragment>
  ):null

}


export  {ProductBoxLabel};
