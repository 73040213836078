import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams,NavLink ,useHistory} from 'react-router-dom';
import {Button,message,Popconfirm,Alert,notification} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars,faSave,faPrint, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {useIntl} from "react-intl";
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../Style/styled';
import {getCompanyList} from '../../redux/CompanyList/companyListActions';
import { getCompany } from '../../redux/company/myCompanyActions';
import {getOfferDetails,updateOffer,deleteOffer,createOffer} from '../../redux/offerDetails/offerDetailActions';
import {getProductList} from '../../redux/productList/productListActions';
import {getMaterialsList} from '../../redux/materials/materialsActions';
import appActions from '../../redux/app/actions';

import {OfferInfo} from "./offers/offerInfo";
import {OfferRows} from "./offers/offerRows";
import {OfferSummary} from "./offers/offerSummary";
import {CompanyListModal} from "../commonComponent/companyListModal";
import {OfferRowModal} from "./offers/offerRowModal";
import {ProductListModal} from "../commonComponent/productListModal";
import {MaterialListModal} from "../commonComponent/materialListModal";
import {ReportSetting} from "./offers/reportSetting";
import {PrintModal} from "./offers/printModal";
const OfferDetails = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { offerID } = useParams();
  const { messages } = useIntl();

  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const { data, loading,status } = useSelector(state => state.offerDetail);
  const [dataSource, setDataSource] = React.useState(data);
  const {companyList,companyListLoading} = useSelector((state) => state.companyList);

  const [companies, setCompanies] = React.useState(companyList);
  const products= useSelector((state) => state.productList.data);
  const materials = useSelector(state => state.materials.data);
  const materialListLoading = useSelector(state => state.materials.loading);
  const productListLoading= useSelector((state) => state.productList.loading);
  const [productList, setProductList] = React.useState(products);
  const [materialList, setMaterialList] = React.useState(materials);
  const [submittable, setSubmittable] = React.useState(false);
  const {UnitList,KDVList,OfferStatus,offerReportType,CurrencyList,CCTList,optikList,materialFilter,productsFilter} = useSelector(state => state.App);
  const User = useSelector((state) => state.auth.user);
  const {tcmb} = useSelector(state => state.tcmb);

  const USDSatis = tcmb && tcmb.Data[0].ForexSelling;
  const USDAlis = tcmb && tcmb.Data[0].ForexBuying;
  const EURSatis = tcmb && tcmb.Data[1].ForexSelling;
  const EURAlis = tcmb && tcmb.Data[1].ForexBuying;
  const lang = locale === 'tr' ? 0 :1
  const [state, setState] = React.useState({
    offerRowModalIndex:null,
    offerRowModalActive:null,
    OfferSettingModalActive:null,
    productListModalActive:null,
    companyListModalActive:null,
    materialListModalActive:null,
    printModalActive:null,
    reportSettingModalActive:null,
    itemtype:"product",
  });
  const { setOfferReportType } = appActions;

  //******************************************************************************************************************************************************************/
  const  initData  = {
    firma_adi : null,
    teklif_tarihi : moment(new Date()),
    offer_creator_user: User,
    teklif_tarih:true,
    teklif_konu : "",
    teklif_notu :"",
    firma_id:"",
    firma_adres : "",
    firma_il : "",
    buyer_phone:"",
    firma_eposta:"",
    firma_ilce : "",
    firma_ulke : "",
    teklif_durumu : 0,
    teklif_dovizi : "$",
    teklif_usdkur :USDSatis,
    teklif_eurkur : EURSatis,
    teklif_sevkadresi:"",
    teklif_referansno:" ",
    related_person:"",
    teklif_no:"",
    offeritem : [{
      urun_id:"",
      urun_adi: "",
      urun_kodu: "",
      urun_kdv:20,
      urun_miktari: 1.00,
      urun_birimi:"Adet",
      urun_fiyati: 0.00,
      urun_dovizi : "$",
      toplamTL:0.00,
      toplamDoviz:0.00,
      toplamKDVTL:0.00,
      toplamKDVDoviz:0.00,
      siparis_tarihi:"",
      baslama_tarihi:"",
      bitis_tarihi:"",
      order_type:"default"
    }]
  }
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if (offerID !=="new" && dataSource._id !== offerID) {
      dispatch((getOfferDetails(offerID,history)));
    }else{
      if(offerID ==="new"){
        setDataSource(initData)
      }
    }
  },[])
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(offerID !=="new" && data!==dataSource){
      setDataSource(data);
    }
  },[data]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    setCompanies(companyList)
  },[companyList]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(state.itemtype==="product"){
      setProductList(products);
    }else{
      setMaterialList(materials);
    };
  },[products,materials]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(status===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
  }, [status]);
  //******************************************************************************************************************************************************************/
  const updateValues = (offer) => {
    const newItemArray = offer.offeritem;
    let GtoplamTL = 0;
    let GtoplamDoviz = 0;
    let toplamKDVTL = 0;
    let toplamKDVDoviz = 0;
    for(var i=0; i < newItemArray.length ;i++ ){ 
        let TL = 0 ; 
        if (newItemArray[i].urun_dovizi === "$"){
            TL = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * dataSource.teklif_usdkur;
        }else if (newItemArray[i].urun_dovizi === "€"){
            TL = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * dataSource.teklif_eurkur;
        }else{
            TL = (newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari);} 
            
        let DOVIZ = 0 ; 

        if(dataSource.teklif_dovizi==="€"){
          if (newItemArray[i].urun_dovizi === "$"){
              DOVIZ = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * (dataSource.teklif_usdkur/dataSource.teklif_eurkur);
          }else if (newItemArray[i].urun_dovizi === "€"){
              DOVIZ = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * 1;
          }else{
              DOVIZ = (newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari) / dataSource.teklif_eurkur;} 
        }else if (dataSource.teklif_dovizi==="$"){
          if (newItemArray[i].urun_dovizi === "$"){
              DOVIZ = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * 1;
          }else if (newItemArray[i].urun_dovizi === "€"){
              DOVIZ = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * (dataSource.teklif_eurkur/dataSource.teklif_usdkur);
          }else{
              DOVIZ = (newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari) / dataSource.teklif_usdkur;} 
        }else{
          if (newItemArray[i].urun_dovizi === "$"){
              DOVIZ = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * dataSource.teklif_usdkur;
          }else if (newItemArray[i].urun_dovizi === "€"){
              DOVIZ = newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari * (dataSource.teklif_eurkur);
          }else{
              DOVIZ = (newItemArray[i].urun_fiyati * newItemArray[i].urun_miktari);} 
        }
        newItemArray[i].toplamTL=parseFloat(TL);
        newItemArray[i].toplamDoviz=parseFloat(DOVIZ); 
        newItemArray[i].toplamKdvTL=parseFloat((TL/100)*newItemArray[i].urun_kdv); 
        newItemArray[i].toplamKdvDoviz=parseFloat((DOVIZ/100)*newItemArray[i].urun_kdv); 
        GtoplamTL = GtoplamTL+ TL;
        GtoplamDoviz = GtoplamDoviz + DOVIZ ;
        toplamKDVTL = toplamKDVTL + ((TL/100)*newItemArray[i].urun_kdv)
        toplamKDVDoviz = toplamKDVDoviz + ((DOVIZ/100)*newItemArray[i].urun_kdv)
      }
      offer.teklif_tutartl = parseFloat(GtoplamTL);                  
      offer.teklif_kdvtl = parseFloat(toplamKDVTL); 
      offer.teklif_kdvdoviz = parseFloat(toplamKDVDoviz);              
      offer.teklif_tutardoviz = parseFloat(GtoplamDoviz); 
      offer.teklif_gtoplamtl = parseFloat(GtoplamTL+toplamKDVTL);
      offer.teklif_gtoplamdoviz = parseFloat(GtoplamDoviz+toplamKDVDoviz);
    return offer;
  };
  //******************************************************************************************************************************************************************/
  const updateOfferData = (field,value) => {
    if(field==="teklif_dovizi" || field==="teklif_eurkur" || field==="teklif_usdkur"){
      let tempData = dataSource;
      tempData[field]=value;
      tempData = updateValues(tempData);
      setDataSource({
        ...dataSource,
        tempData
      });
    }else{
      setDataSource({
        ...dataSource,
        [field]:value
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const searchCompany = search => {
    let filteredList =[]; 
    filteredList =  companyList.filter(
        item => {return item.company_name.toLowerCase().includes(search.toLowerCase()) })
    if (search===""){
      setCompanies(companyList)
    }else{
      setCompanies(filteredList)
    }
  };
  //******************************************************************************************************************************************************************/
  const setOfferReport = (e) =>{
    dispatch(setOfferReportType(e))
  }
  //******************************************************************************************************************************************************************/
  const onChangeSetting = (name,e) => {
    setDataSource({
      ...dataSource,
      [name]:e
    });
  };
  //******************************************************************************************************************************************************************/
  const toggleModal = (name,val,index) =>{
    if(name==="companyListModalActive"){
      setState({
        ...state,
        companyListModalActive:val
      });
      dispatch(getCompanyList(null))
    }else if (name==="offerRowModalActive"){
      setState({
        ...state,
        offerRowModalActive:val,
        offerRowModalIndex:index
      });
    }else if (name==="productListModalActive"){
      dispatch(getProductList("list","model_category"));
      setState({
        ...state,
        productListModalActive:val
      });
    }else if (name==="materialListModalActive"){
      dispatch(getMaterialsList('list',null));
      setState({
        ...state,
        materialListModalActive:val
      });
    }else if (name==="printModalActive"){
      setState({
        ...state,
        printModalActive:val
      });
    }else if (name==="reportSettingModalActive"){
      setState({
        ...state,
        reportSettingModalActive:val
      });
    }
  };
  //******************************************************************************************************************************************************************/
  const selectCompany = (company) => {
    setDataSource({
      ...dataSource,
      firma_id:company._id,
      firma_adi:company.company_name,
      firma_adres:company.company_adress,
      firma_ilce:company.company_district,
      firma_il:company.company_city,
      firma_ulke:company.company_country,
      firma_eposta:company.company_email
    });
    setState({
      ...state,
      companyListModalActive:null
    });
  };
  //******************************************************************************************************************************************************************/
  const onUpdateOfferRow = (eData) => {
    let tempdata = dataSource;
    tempdata.offeritem[state.offerRowModalIndex].urun_id = eData.urun_id;
    tempdata.offeritem[state.offerRowModalIndex].urun_adi = eData.urun_adi;
    tempdata.offeritem[state.offerRowModalIndex].urun_kodu =[eData.urun_kodu[0], eData.urun_kodu[1], eData.urun_kodu[2], eData.urun_kodu[3], eData.urun_kodu[4], eData.urun_kodu[5], eData.urun_kodu[6], eData.urun_kodu[7], eData.urun_kodu[8]];
    tempdata.offeritem[state.offerRowModalIndex].urun_kdv = eData.urun_kdv;
    tempdata.offeritem[state.offerRowModalIndex].urun_miktari = eData.urun_miktari;
    tempdata.offeritem[state.offerRowModalIndex].urun_birimi = eData.urun_birimi;
    tempdata.offeritem[state.offerRowModalIndex].urun_fiyati = eData.urun_fiyati;
    tempdata.offeritem[state.offerRowModalIndex].urun_dovizi = eData.urun_dovizi;

    let temp = updateValues(tempdata)
    setDataSource({
      ...dataSource,
      temp
    });
    setState({
      ...state,
      offerRowModalActive:null,
      offerRowModalIndex:null
    });
  }
  //******************************************************************************************************************************************************************/
  const addNewOfferRow = () => {
    if(dataSource.teklif_durumu===0){
      let temp = dataSource.offeritem;
      temp.push({
        urun_resim:"",
        //urun_id:"IDYOK0000",
        urun_adi: "",
        urun_kodu: "",
        urun_kdv:20,
        urun_miktari: 1.00,
        urun_birimi:"Adet",
        urun_fiyati: 0.00,
        urun_dovizi : "$",
        toplamTL:0.00,
        toplamDoviz:0.00,
        toplamKDVTL:0.00,
        toplamKDVDoviz:0.00,
        order_type:"product"
      })
      setDataSource({
        ...dataSource,
        offeritem:temp
      });
      setState({
        ...state,
        offerRowModalActive:true,
        offerRowModalIndex:dataSource.offeritem.length-1,

      });
    }else{
      message.warning("Teklif Surumu sadece 'Teklif' olan tekliflere satır eklenebilir",3);
    }
  }  
  //******************************************************************************************************************************************************************/
  const removeOfferRow = (index) => {
    if(index===0){
      message.warning("Tek Kalan Satırı Silemezsiniz !!!",3);
    }else{
      setState({
        ...state,
        offerRowModalIndex:null
      });
      let tempData = dataSource;
      let temp = dataSource.offeritem;
      if (temp.length>0){ 
        temp.splice(index, 1);
        tempData.offeritem = temp;
        let response = updateValues(tempData);
        setDataSource(response);
      }
    }
  }
  //******************************************************************************************************************************************************************/
  const onSaveOffer = () => {

    if( offerID!=='new'){
      if(dataSource._id ){
        dispatch(updateOffer({...dataSource}))
        .then(res=>{

          message.success(messages["alert.savesuccess"], 2 );
        })
      }else{
        message.info("Sayfayı Yenileyiniz !!", 10 );
      }
    }else{
      if(offerID==='new'){
        dispatch(createOffer({...dataSource}, history))
        .then(res=>{
          message.success(messages["alert.createsuccess"], 2);
        });        
      }
    }
    
  };
  //******************************************************************************************************************************************************************/
  const searchProduct = search => {
    let filteredList =[]; 
    filteredList =  products.filter(
        item => {return item.urun_adi[lang].toLowerCase().includes(search.toLowerCase()) || item.modelDetails.model_kodu.toLowerCase().includes(search.toLowerCase())})
    if (search===""){
      setProductList(products)
    }else{
      setProductList(filteredList)
    }
  };
  //******************************************************************************************************************************************************************/
  const selectProduct = (product) =>{

    let tempdata = dataSource;
    if(tempdata.offeritem[state.offerRowModalIndex].order_type==="product"){
      let PrdouctCodeList = [];
      PrdouctCodeList.push(product.modelDetails.model_kodu);
      if(product.model_category===8){
        PrdouctCodeList.push(product.urun_kapasite);
      }else{
        PrdouctCodeList.push(product.urun_kodu);
      }
     
      PrdouctCodeList.push(product.urun_optik);
      PrdouctCodeList.push(product.urun_cct);

      PrdouctCodeList.push(product.urun_panel);
      PrdouctCodeList.push(product.urun_aku);

      
      if(product){
        tempdata.offeritem[state.offerRowModalIndex].urun_id = product._id;
        tempdata.offeritem[state.offerRowModalIndex].urun_adi = product.urun_adi[lang];
        tempdata.offeritem[state.offerRowModalIndex].urun_kodu = PrdouctCodeList;
        tempdata.offeritem[state.offerRowModalIndex].urun_kdv = product.modelDetails.model_kdv ;
        tempdata.offeritem[state.offerRowModalIndex].urun_birimi = product.modelDetails.model_birimi ;
        tempdata.offeritem[state.offerRowModalIndex].urun_fiyati = product.urun_fiyat;
        tempdata.offeritem[state.offerRowModalIndex].urun_dovizi = product.urun_doviz;
        tempdata.offeritem[state.offerRowModalIndex].stockSum = product.stockSum;
        tempdata.offeritem[state.offerRowModalIndex].order_type =  "product";
        tempdata.offeritem[state.offerRowModalIndex].productCategory = product.model_category;
      }
    }else if (tempdata.offeritem[state.offerRowModalIndex].order_type==="material"){

      tempdata.offeritem[state.offerRowModalIndex].material_id = product._id;
      tempdata.offeritem[state.offerRowModalIndex].urun_adi = product.material_name;
      tempdata.offeritem[state.offerRowModalIndex].urun_kodu = ["","","","","","",""];
      tempdata.offeritem[state.offerRowModalIndex].urun_kdv = 20 ;
      tempdata.offeritem[state.offerRowModalIndex].urun_birimi = product.material_unit ;
      tempdata.offeritem[state.offerRowModalIndex].urun_fiyati = product.material_price;
      tempdata.offeritem[state.offerRowModalIndex].urun_dovizi = product.material_doviz;
      tempdata.offeritem[state.offerRowModalIndex].stockSum = product.stockSum;
      tempdata.offeritem[state.offerRowModalIndex].order_type =  "material"   
    }

    let temp = updateValues(tempdata)
    setDataSource({
      ...dataSource,
      temp
    });
    setState({
      ...state,
      productListModalActive:null,
      materialListModalActive:null
    });
  }
  //******************************************************************************************************************************************************************/
  const onChangeItemType = (e) => {

    let tempdata = dataSource;
    if(e==='default'){
      let PrdouctCodeList = [];
      PrdouctCodeList.push("");
      PrdouctCodeList.push("");
      PrdouctCodeList.push("");
      PrdouctCodeList.push("");
      PrdouctCodeList.push("");
      PrdouctCodeList.push("");
      tempdata.offeritem[state.offerRowModalIndex].urun_id = null
      tempdata.offeritem[state.offerRowModalIndex].urun_kodu = PrdouctCodeList;
      tempdata.offeritem[state.offerRowModalIndex].order_type =  "default";   
    }else if(e==='material'){
      let PrdouctCodeList = [];
      PrdouctCodeList.push("");
      PrdouctCodeList.push("");
      PrdouctCodeList.push("");
      PrdouctCodeList.push("");
      PrdouctCodeList.push("");
      PrdouctCodeList.push("");
      tempdata.offeritem[state.offerRowModalIndex].urun_id = null;
      tempdata.offeritem[state.offerRowModalIndex].urun_kodu = PrdouctCodeList;
      tempdata.offeritem[state.offerRowModalIndex].order_type =  "material";   
    }else{
      tempdata.offeritem[state.offerRowModalIndex].order_type =  "product";  
    }
    setDataSource({
      ...dataSource,
      tempdata
    });
  }
  //******************************************************************************************************************************************************************/
  const Submittable = (e) => {
    setSubmittable(e)
  }
  //******************************************************************************************************************************************************************/
  const searchMaterial = search => {
    let filteredList =[]; 
    filteredList =  materials.filter(
        item => {return item.material_name.toLowerCase().includes(search.toLowerCase()) || item.material_category.toLowerCase().includes(search.toLowerCase())})
    if (search===""){
      setMaterialList(materials)
    }else{
      setMaterialList(filteredList)
    }
  };
  //******************************************************************************************************************************************************************/
  const printOffers=()=>{
    //window.open(`/admin/workorder/${projectID}`,"_blank");
    dispatch((getCompany({id:1})));
    setState({
      ...state,
      printModalActive:true,

    });
  }
  //******************************************************************************************************************************************************************/
  const sendTrackEmail = () => {
    message.loading(messages["alert.updated"]);
    let tempData = dataSource
    tempData.orderTrackingEmail=true;
    if(tempData._id){
      dispatch(updateOffer(tempData))
      .then(res=>{
        message.success(messages["alert.savesuccess"], 2 );
      })
    }
  };
//console.log(dataSource)
 //******************************************************************************************************************************************************************/
  return (
    <>
      <PageHeader
        ghost
        title={messages["offer.details"]}
        buttons={[
          <div key="1" className="page-header-actions">
            {dataSource.teklif_durumu === 0 && (dataSource.firma_id) && 
            <Popconfirm
              title={messages["alert.deletequest"]}
              okText={messages["button.delete"]}
              cancelText={messages["button.cancel"]}
              onConfirm={() => {
                dispatch(deleteOffer(dataSource._id));
                history.push("/admin/offers");
               
              }}
            >
              <Button type="danger" >
                {messages["button.delete"]}
              </Button>
            </Popconfirm>}
            {dataSource.teklif_durumu === 1 && (dataSource.firma_id) && 
            <Button type="warning"  onClick={()=>sendTrackEmail()}>
              <FontAwesomeIcon icon={faEnvelope} size='1x' />
              {messages["button.email"]}
            </Button >}
        
            {dataSource._id &&
              <Button type="warning"  onClick={()=>toggleModal("reportSettingModalActive",true)}>
              <FontAwesomeIcon icon={faPrint} size='1x' />
              {messages["button.print"]}
            </Button >}
     
            <Button  type="success" disabled={!submittable} onClick={()=>onSaveOffer()}>
              <FontAwesomeIcon icon={faSave} size='1x' />
              {messages["button.save"]}
            </Button>
    
            <NavLink to="/admin/offers">
              <Button  type="primary">
                <FontAwesomeIcon icon={faBars} size={"1x"} />
                {messages["sidebar.offers"]}
              </Button>
            </NavLink>
          </div>
        ]}
      />
      
      <Main>   
      {!dataSource.firma_id && dataSource.firma_adi && <Alert message="Firma Kayıtlı değil " type="error"/>} 
        <OfferInfo
          messages={messages}
          lang={lang}
          loading={loading}
          offer={dataSource}
          CurrencyList={CurrencyList}
          USDSatis={USDSatis}
          EURSatis={EURSatis}
          USDAlis={USDAlis}
          EURAlis={EURAlis}
          updateOfferData={updateOfferData}
          toggleModal={toggleModal}
          setSubmittable={Submittable}
        />

        <OfferRows
          messages={messages}
          lang={lang}
          loading={loading}
          offer={dataSource}
          CurrencyList={CurrencyList}
          USDSatis={USDSatis}
          EURSatis={EURSatis}
          toggleModal={toggleModal}
          addNewOfferRow={addNewOfferRow}
          removeOfferRow={removeOfferRow}
        
        />
        <OfferSummary
          messages={messages}
          loading={loading}
          offer={dataSource}
          updateOfferData={updateOfferData}
        />


      </Main>

      <CompanyListModal
        companyList={companies}
        messages={messages}
        lang={lang}
        loading={companyListLoading}
        companyListModalActive={state.companyListModalActive}
        searchCompany={searchCompany}
        selectCompany={selectCompany}
        toggleModal={toggleModal}
      />
      {state.offerRowModalActive && 
      <OfferRowModal
        messages={messages}
        offeritem={dataSource.offeritem[state.offerRowModalIndex]}
        offer={dataSource}
        offerRowModalActive={state.offerRowModalActive}
        CurrencyList={CurrencyList}
        toggleModal={toggleModal}
        UnitList={UnitList}
        KDVList={KDVList}
        onUpdateOfferRow={onUpdateOfferRow}
        itemtype={state.itemtype}
        onChangeItemType={onChangeItemType}
        CCTList={CCTList}
        optikList={optikList}
      />}
      <ProductListModal
        productList={productList}
        messages={messages}
        lang={lang}
        loading={productListLoading}
        productListModalActive={state.productListModalActive}
        searchProduct={searchProduct}
        selectProduct={selectProduct}
        toggleModal={toggleModal}
      />
      <MaterialListModal
        materialList={materialList}
        messages={messages}
        lang={lang}
        loading={materialListLoading}
        materialListModalActive={state.materialListModalActive}
        searchMaterial={searchMaterial}
        selectMaterial={selectProduct}
        toggleModal={toggleModal}
      />
      <ReportSetting
        messages={messages}
        toggleModal={toggleModal}
        printOffers={printOffers}
        reportSettingModalActive={state.reportSettingModalActive}
        offer={dataSource}
        offerReportType={offerReportType}
        setOfferReportType={setOfferReport}
        onChangeSetting={onChangeSetting}
        onSaveOffer={onSaveOffer}
      />
      <PrintModal
        messages={messages}
        toggleModal={toggleModal}
        printModalActive={state.printModalActive}
      />
    </>
  );
}



export  {OfferDetails};
