import React, { useEffect, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card,Spin,message } from 'antd';
import { useParams,NavLink } from 'react-router-dom';
import {useIntl} from "react-intl";
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../Style/styled';
import { ProductDetailsWrapper,ProjectTabContent } from '../../Style/style';
import blank from '../../../static/img/resyok.jpeg';
import {getProductDetails} from '../../../redux/productDetails/productDetailActions';
import {getModelList} from '../../../redux/modelList/modelListActions';
import { Specifications } from './products/SpecTable';
const DetailsRight = lazy(() => import('./products/DetailsRight'));

const ProductDetails = ({  }) => {
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const {categoryList} = useSelector(state => state.App);
  const { productDetail, productDetailLoading,status} = useSelector(state => state.productDetail);

  const [dataSource, setDataSource] = React.useState(productDetail);

  const company = useSelector(state => state.myCompany.myCompany);
  const {tcmb} = useSelector(state => state.tcmb);
  // const USDSatis = tcmb && tcmb.Data[0].ForexSelling;
  // const EURSatis = tcmb && tcmb.Data[1].ForexSelling;

  const dispatch = useDispatch();
  const { productID } = useParams();
  const { messages } = useIntl();
  const lang = locale === 'tr' ? 0 :1;
  const [tabID,setTabID] = React.useState("model")
  //******************************************************************************************************************************************************************/
  useEffect(() => {
    setDataSource(productDetail)
},[productDetail]);
  //******************************************************************************************************************************************************************/
  useEffect(() => {
    if (productID !=="new" && dataSource._id !== productID) {
      dispatch((getProductDetails({id:productID})))
      .then(res=>{
        message.success(messages["alert.savesuccess"],  2 );
      });
    }
  },[]);
  //******************************************************************************************************************************************************************/
  const gotoPage = (i) => {
    setTabID(i)
  }
  //******************************************************************************************************************************************************************/
  const  mainImage = dataSource && dataSource.imageList && dataSource.imageList.length ? dataSource.imageList[0].image : blank

  return (
    <>
      <PageHeader
        ghost
        title={messages["product.details"]}
      />
      <Main>
        <Card>
          <ProductDetailsWrapper>
            <div className="product-details-box">
              <Row gutter={25}>
                <Col xs={24} lg={10}>
                  {productDetailLoading ? 
                    <div className="spin">
                      <Spin/>
                    </div>  
                  :
                    <div className="product-details-box__left pdbl">
                    <figure>
                      <img style={{ width: '100%' }} src={mainImage} alt="" />
                    </figure>
                    <div className="pdbl__slider pdbs">
                      <Row gutter={5}>
                        {dataSource && dataSource.productImages &&  dataSource.productImages.length
                          ? dataSource.productImages
                              .map((image, index) => {
                                return (
                                  index <= 3 && (
                                    <Col md={4} key={index}>
                                      <div className="pdbl__image">
                                        <figure>
                                          <NavLink to='#'>
                                            <img
                                              style={{ width: '100%' }}
                                              src={image.image.image}
                                              alt=""
                                            />
                                          </NavLink>
                                        </figure>
                                      </div>
                                    </Col>
                                  )
                                );
                              })
                          : null}
                      </Row>
                    </div>
                  </div>}
                </Col>
                <Col xs={24} lg={14}>
                  <DetailsRight 
                    product={dataSource} 
                    messages={messages}
                    loading={productDetailLoading}
                    lang={lang}                    
                  />
                </Col>
              </Row>
            </div>
          </ProductDetailsWrapper>
        </Card>
        <ProjectTabContent style={{marginTop:"25px"}}>
          <Card
            title={
              <nav>
                <NavLink to={`#`} onClick={()=>gotoPage("model")}  activeClassName={tabID === "model" ? "active":""}>{messages["model.specifications"]}</NavLink>
                &nbsp; &nbsp;
                <NavLink to={`#`} onClick={()=>gotoPage("product")} activeClassName={tabID === "product" ? "active":""}>{messages["product.specifications"]}</NavLink>
                &nbsp; &nbsp;
                <NavLink to={`#`} onClick={()=>gotoPage("downloads")} activeClassName={tabID === "downloads" ? "active":""}>{messages["model.downloads"]}</NavLink>
              </nav>
            }
          >
            {productDetailLoading ? 
            <div className="spin">
              <Spin />
            </div>  
              :
            <Specifications
              product={dataSource} 
              messages={messages}
              loading={productDetailLoading}
              lang={lang}      
              tabID={tabID}
            />}
          </Card>
        </ProjectTabContent>
      </Main>
    </>
  );
}


export {ProductDetails};
