import React from 'react';
import { Col,Row,Button, Spin, Form, Input,Select,InputNumber,Card,DatePicker,Collapse } from 'antd';
import {currencyFormatter} from '../../../helpers/currencyFormat';
import { HorizontalFormStyleWrap,BasicFormWrapper,OrderSummary } from '../../Style/style';
import Heading from '../../../components/heading/heading';
import { Cards } from '../../../components/cards/frame/cards-frame';
const { Panel } = Collapse;
const defaultOptions = {
  significantDigits: 4,
  thousandsSeparator: '.',
  decimalSeparator: ',',
  symbol: ""
}
const { TextArea } = Input;
const OfferSummary =({offer,loading,messages,updateOfferData})=> {
  const {teklif_tutardoviz,teklif_kdvdoviz,teklif_gtoplamdoviz,teklif_dovizi,teklif_notu,teklif_usdkur,teklif_eurkur} = offer


  return (
 
      <BasicFormWrapper>
        <HorizontalFormStyleWrap>
        <Row gutter={25}>
          <Col md={16} xs={24}>
            <Cards
              bodyStyle={{
                backgroundColor: '#ffffff',
                borderRadius: '20px'
              }}
              headless
            >
                <Form.Item  label={messages["offer.info"]}>
                  <TextArea rows={5} value={teklif_notu} onChange={(e)=>updateOfferData("teklif_notu",e.target.value)}/>
                </Form.Item>
            </Cards>
          </Col>
          <Col md={8} xs={24}>
            <OrderSummary>
              <Cards
                bodyStyle={{
                  backgroundColor: '#ffffff',
                  borderRadius: '20px'
                }}
                headless
              >
                <div className="order-summary-inner">
                  <ul className="summary-list">
                    <li>
                      <span className="summary-list-title">{messages["genel.totalprice"]}</span>
                      <span className="summary-list-text">{currencyFormatter(teklif_tutardoviz)+" "+(teklif_dovizi ? teklif_dovizi :"")}</span>
                    </li>
                    <li>
                      <span className="summary-list-title">{messages["genel.sumkdv"]}</span>
                      <span className="summary-list-text">{currencyFormatter(teklif_kdvdoviz)+" "+(teklif_dovizi ? teklif_dovizi:"")}</span>
                    </li>
                    <li>
                      <span className="summary-list-title"> --</span>
                      <span className="summary-list-text">0</span>
                    </li>
                  </ul>

            
                  <Heading className="summary-total" as="h4">
                    <span className="summary-total-label">{messages["genel.gtotal"]}</span>
                    <span className="summary-total-amount">{currencyFormatter(teklif_gtoplamdoviz)+" "+(teklif_dovizi ? teklif_dovizi:"")}</span>
                  </Heading>
                </div>
              </Cards>
            </OrderSummary>
          </Col>
        </Row>
        </HorizontalFormStyleWrap>
      </BasicFormWrapper>
  
    
  );
}

export  {OfferSummary};
