import { createStore,applyMiddleware, compose } from 'redux';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import reduxWebsocket from '@giantmachines/redux-websocket';
import { persistStore, persistReducer } from 'redux-persist';

import { user_reauth_request } from './auth/actions';
import rootReducer from './rootReducer';
import { SetTransform } from './transform';
import { wsMiddleware } from '../middleware/ws';


const persistConfig = {
	key: 'root',
	storage,
	transforms: [SetTransform],
	whitelist: ['auth', 'App','myCompany','LanguageSwitcher']
}

const reduxWebsocketMiddleware = reduxWebsocket();
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
    compose(
      	 applyMiddleware(thunk,reduxWebsocketMiddleware ,wsMiddleware),
      	// window.navigator.userAgent.includes('Chrome') ?
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : 
		// null,
      ),
);

export const persistor = persistStore(store, {}, () => {
    if(store.getState().auth.token && store.getState().auth.token !== "")
      	store.dispatch(user_reauth_request(store.getState().auth.token));
});
