import {
    OFFER_GET_LIST,
    OFFER_GET_LIST_SUCCESS,
	OFFER_GET_LIST_ERROR,
	ORDER_SUM_LIST,
    ORDER_SUM_LIST_SUCCESS,
	ORDER_SUM_LIST_ERROR,
	COMPANY_OFFER_GET_LIST,
    COMPANY_OFFER_GET_LIST_SUCCESS,
	COMPANY_OFFER_GET_LIST_ERROR,
} from '../actions';
const INIT_STATE = {
	offerList: [],
	companyOfferList:[],
	offerListLoading:false,
	offerListStatus:null
};

const offerList =  (state = INIT_STATE, action) => {
	switch (action.type) {
		case OFFER_GET_LIST: // GEL LIST 
			return { 
				...state, 
				offerListLoading: true 
			};
		case OFFER_GET_LIST_SUCCESS:
            return { ...state, 
                offerListLoading: false, 
                offerList: action.response.offers,  
				offerListStatus:200
            };
		case OFFER_GET_LIST_ERROR:
			return { 
				...state, 
				offerListLoading: false,
				offerListStatus: action.response 
			};
		case ORDER_SUM_LIST: // GEL LIST 
			return { 
				...state, 
				offerListLoading: true 
			};
		case ORDER_SUM_LIST_SUCCESS:
            return { 
				...state, 
                offerListLoading: false, 
                ordersum: action.response.ordersummary,  
				offerListStatus:200
            };
		case ORDER_SUM_LIST_ERROR:
			return { 
				...state, 
				offerListLoading: false ,
				offerListStatus: action.response 
			};
		case COMPANY_OFFER_GET_LIST: // GEL LIST 
			return { 
				...state, 
				offerListLoading: true,
				companyOfferList:[]
			};
		case COMPANY_OFFER_GET_LIST_SUCCESS:
            return { 
				...state, 
                offerListLoading: false, 
                companyOfferList: action.response.offers,  
				offerListStatus:200
            };
		case COMPANY_OFFER_GET_LIST_ERROR:
			return { 
				...state, 
				offerListLoading: false,
				offerListStatus: action.response  
			};		
		default: {
			return state
		}
	}
}
export default offerList