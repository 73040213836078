import React, {  Fragment } from 'react';
import {PDFViewer} from '@react-pdf/renderer'
import { useSelector } from 'react-redux';
import {Spin} from 'antd';
import LEDLightDataSheet from "./product.Datasheet/led.dataSheet";
import SolarDataSheet from "./product.Datasheet/solar.dataSheet";
import {useIntl} from "react-intl";
//import logo  from '@iso/assets/Moduledlogos/Moduled-light.svg';

const DataSheet =()=>{

  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const { productDetail, productDetailLoading } = useSelector(state => state.productDetail);
  const { messages } = useIntl();
  const Lang = locale === 'tr' ? 0 :1
  const {myCompany,myCompanyLoading} = useSelector(state => state.myCompany);


    return !productDetailLoading && !myCompanyLoading ? 
    (
        <div>
            <Fragment>
              { productDetail.modelDetails && myCompany  ?                
              <PDFViewer  width="100%"  style={{height: '100vh',top: 0}} >
                {productDetail.model_category === 7 ?
                 null// <SolarDataSheet company={myCompany} product={productDetail} messages={messages}  Lang={Lang}/>
                  :
                  <LEDLightDataSheet company={myCompany} product={productDetail} messages={messages}  Lang={Lang}/>
                  }
              </PDFViewer>:
                    <div className="spin">
                      <Spin />
                    </div>
              }       
            </Fragment>     
        </div>
    ) : (
      <Spin />
    );
    
}


export {DataSheet}





