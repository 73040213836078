import React, {  useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Progress, Spin,Tag ,Popover,Modal,message,Popconfirm,notification,Steps,Button} from 'antd';
import { NavLink, useParams,useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb,faPalette,faSpinner,faEye,faCircleArrowRight,faListCheck,faPenToSquare,faSave,faGear,faList, faCirclePause, faCircleCheck, faCirclePlus, faRefresh, faFileCirclePlus, faTrash, faBoxOpen, faFileLines, faEdit } from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import { GeneralContent,ProjectImageCard,ProjectResult,ProjectDetail,ProjectProductDetail,ProjectTabContent} from '../Style/style';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../Style/styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import {getProductCode} from '../../helpers/getProductCode';
import blank from '../../static/img/resyok.jpeg';
import moment from 'moment';
import {getOrderItemDetails,orderItemActions,updateOrderItem,deleteOfferItem} from '../../redux/orderItemDetail/orderItemActions';
import {deleteTask,createTask,updateTask} from '../../redux/taskDetails/taskDetailActions';
import {createDelivery,updateDelivery, deleteDelivery,getdeliveryDetails} from '../../redux/deliveryDetails/deliveryDetailActions';
import {updateComponent,deleteComponent,createComponent,getComponentDetails} from '../../redux/componentDetails/componentDetailsAction';
import {getProductList} from '../../redux/productList/productListActions';
import { deleteAcTest } from '../../redux/acTestDetails/acTestDetailActions';
import { getMaterialsList } from '../../redux/materials/materialsActions';
import { MaterialList } from './commonComponents/materialList';
import {TaskList} from './commonComponents/taskList';
import {DeliveryList} from './commonComponents/deliveryList';
import { LastCheckList } from './commonComponents/LastCheckList';
import { OrderNoteModal } from './Project/orderNoteModal';
import {StepModal} from './Project/stepModal';
import {ComponentModal} from './Project/componentModal';
import {MaterialListModal} from '../commonComponent/materialListModal';
import {ProductListModal} from '../commonComponent/productListModal';
import {OrderInfoModal} from './Project/orderInfoModal';
import {OptionModal} from './Project/optionModal';
import {DeliveryModal} from './Project/deliveryModal';
import {ActivitesModal} from './Project/activitesModal';
import {DeliveryPrintModal} from './Project/deliveryPrintModal';
import {BoxLabelSetting} from './Project/boxLabelSetting';
import {ReportModal} from './Project/orderReportModal';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {PopupButtons} from '../../components/PopupButtons';
import appActions from '../../redux/app/actions';

const componentRowInit = {
  task:null,
  material_category:"",
  material_group:"",
  materialQty:1,
  material:null,
  stockSum:0,
  totalQty:null
}
const  ProjectDetails = ()=> {
  const dispatch = useDispatch();
  let history = useHistory();
  const { messages } = useIntl();
  const { projectID } = useParams();
  const { orderItemDetail, orderItemDetailLoading,orderItemDetailStatus } = useSelector(state => state.orderItemDetail);
  const { deliveryDetailStatus} = useSelector(state => state.deliveryDetails);
  const { componentDetail,componentDetailStatus } = useSelector(state => state.componentDetails);
  const { productList } = useSelector((state) => state.productList);
  const [task, setTask] = React.useState(null);
  const [componentRow, setComponentRow] = React.useState();
  const [dataSource, setDataSource] = React.useState(orderItemDetail);
  const [taskTitleList, setTaskList] = React.useState();
 
  const {taskTypeList,statusList,UnitList,CCTList,optikList,logoOpen}= useSelector(state => state.App);
  const [delivery, setDelivery] = React.useState(null);
  const { setLogoOpen } = appActions;
  const User = useSelector((state) => state.auth.user);
  const [state, setState] = React.useState({
    stepModalActive:null,
    componentRowModalActive:null,
    stepComponentListActive:null,
    stepIndex:null,
    orderNotesModalActive:null,
    notesModalIndex:null,
    deliveryModalActive:null,
    orderInfoModalActive:null,
    materialListModalActive:null,
    productListModalActive:null,
    optionModalActive:null,
    deliveryPrintModalActive:null,
    printModalActive:null,
    taskModalActive:null,
    orderNotesModalActive:null,
    boxLabelSettingActive:null,
    notesModalIndex:null,
    option:null,
    productCodeIndex:null,
    printType:null,
    projectSteps:[],
    activeTab:"1",
    itemtype:"product",
    tabID:"componentlist"
  });
  const {tabID,stepIndex,productCodeIndex,printType} = state;

  let color; let orderType = "";let orderClass = "";

  const {
    product,offer,
    _id,
    urun_id,
    material_id,
    urun_adi,
    urun_kodu,
    urun_birimi,
    urun_fiyati,
    urun_dovizi,
    siparis_durumu,
    siparis_tarihi,
    teslim_edilmis,
    baslama_tarihi,
    bitis_tarihi,
    status,
    ready,
    order_type,
    tamamlanmaOrani,
    urun_miktari,
    componentList,
    taskList,
    deliveryList,
    lastCheckList,
    lastCheckLength,
    notComplatedComponentLength,
    notComplatedHalfComponentLength,
    deliveried_id,
    teklifno,
    deliveriedSum
  } = dataSource;
  const productImage =product && product.imageData ? product.imageData: blank;
  if (siparis_durumu === 0 ){color = 'magenta'} 
  else if (siparis_durumu === 1){color = 'red'} 
  else if (siparis_durumu === 2){color = 'default'} 
  else if (siparis_durumu === 3){color = 'default'} 
  else if (siparis_durumu === 4){color = "blue"} 
  else if (siparis_durumu === 5){color = 'green'};
  //******************************************************************************************************************************************************************/
  if (order_type==="material"){
    orderType=messages["material.material"];
    orderClass="malzeme"
  }else if(order_type==="product"){
    orderType=messages["product.name"];
    orderClass="urun"
  }else if(order_type==="subproduction"){
    orderType=messages["product.subproduction"];
    orderClass="subproduction"
  }else{
    orderType="default";
    orderClass="default"
  };
  //******************************************************************************************************************************************************************/
  const buttons =[
    {value:"workorder",icon:<FontAwesomeIcon icon={faFileLines} />,popovercontent:messages["order.workorder"]},
    {value:"packetlabel",icon:<FontAwesomeIcon icon={faBoxOpen} />,popovercontent:messages["product.packetlabel"]},
    {value:"setting",icon:<FontAwesomeIcon icon={faGear} />,popovercontent:messages["button.settings"]}
  ];
  //******************************************************************************************************************************************************************/
  const buttonList = [
    <div key={0}>
      <PopupButtons
        option={buttons}
        choseData={(e)=>toggleModal("printModalActive",true,e)}
      />
      {dataSource && dataSource.order_type==='subproduction' && 
      <Popconfirm
        title={messages["alert.deletequest"]}
        okText={messages["genel.yes"]}
        cancelText={messages["genel.no"]}
        onConfirm={()=>deleteProject()}
      >
        <Button shape="circle" size='large' icon={<FontAwesomeIcon icon={faTrash} color="#ce1818"/>}  style={{marginRight:'10px'}}/>
      </Popconfirm>}
      <Popover content= {messages["button.refresh"]} placement="bottom">
        <Button shape="circle"  onClick={()=>refreshDataSource()} size='large' icon={<FontAwesomeIcon icon={faRefresh} color="#0328fc"/>} style={{marginRight:'10px'}}/>
      </Popover>
      <Popover content= {messages["order.newNote"]} placement="bottom">
        <Button shape="circle"  onClick={()=>addNewOrderNote()} size='large' icon={<FontAwesomeIcon icon={faFileCirclePlus}/>} style={{marginRight:'10px'}}/>
      </Popover>
      <Popover content= {messages["button.edit"]} placement="bottom">
        <Button shape="circle"  onClick={()=>orderInfoModalOpen()} size='large' icon={<FontAwesomeIcon icon={faPenToSquare}/>} style={{marginRight:'10px'}}/>
      </Popover>
      <Popover content= {messages["button.save"]} placement="bottom">
        <Button shape="circle"  onClick={()=>onSaveOrderItem()} size='large' icon={<FontAwesomeIcon icon={faSave} />} style={{marginRight:'10px'}}/>
      </Popover>           
      <Popover content= {messages["sidebar.projects"]} placement="bottom">
        <NavLink key={4} to="/admin/project" className="project-edit">
          <Button shape="circle" type='primary' size='large' icon={<FontAwesomeIcon icon={faList}/>}/>
        </NavLink>
      </Popover>
    </div>
  ];
  //******************************************************************************************************************************************************************/
  useEffect(() => {
    if (projectID !=="new" && _id !== projectID) {
      dispatch(getOrderItemDetails({id:projectID}))
      .then(res=>{
        if(order_type==='subproduction'){
          dispatch(getComponentDetails({id:dataSource.main_Production_Component_id}))
        }
      })
    }
    productList && !productList.length && dispatch(getProductList("list","model_category"));
  },[]);
  //******************************************************************************************************************************************************************/
  useEffect(() => {
    setDataSource(orderItemDetail);
  }, [orderItemDetail]);
  //******************************************************************************************************************************************************************/
  useEffect(() => {
      const {controlled,_id} = componentDetail;
      if(dataSource.main_Production_Component_id===_id)
      {
        if(orderItemDetail.tamamlanmaOrani===100 && order_type==='subproduction'){
          if(controlled===3){
            return ;
          }else{
            let data = componentDetail;          
            data.controlled=3;
            data.command="chanceContolled";
            dispatch(updateComponent(data));
          }
        }else{
          if(componentDetail && controlled===3 && order_type==='subproduction'){
            let data = componentDetail;
            data.command = "chanceContolled";
            data.controlled=1;
            dispatch(updateComponent(data)); 
          }
        }
      }


  }, [componentDetail.controlled]);
  //******************************************************************************************************************************************************************/
  useEffect(() => {
    const projectSteps = [];
    taskList && taskList.length && taskList.sort((a, b) => (a.importance > b.importance) ? -1 : 1 ).forEach(row=>{
      const description= row.task_qty>0 ? row.task_steps.reduce((acc, curr) => acc + parseInt(curr.step_qty, 10), 0)+" / " + row.task_qty+messages["offer.OfferStatus2"]:"";
      const icon = row.status === 0 ? <FontAwesomeIcon icon={faCirclePause} /> : row.status === 1 ?  <FontAwesomeIcon icon={faSpinner} spin/> : <FontAwesomeIcon icon={faCircleCheck}/>
      projectSteps.push({'title':row.title,'description':description,'icon':icon})
    })
    setState({
      ...state,
      projectSteps:projectSteps
    });


  
  }, [taskList,componentList]);

  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(orderItemDetailStatus===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
    if(orderItemDetailStatus === 200 && !orderItemDetail.urun_id && orderItemDetail.order_type==="product"){
      notification.error({
        message: 'Uyarı !',
        description: "Bu Operasyon ÜRÜN veya Malzeme Olamaz !!",
      });
    }
    if(orderItemDetailStatus===404){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu kayıt bulunamadı silinmiş veya iptal edilmiş olabilir.',
      });
      history.push({pathname:"/admin/404"})
    }
  }, [orderItemDetailStatus]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(deliveryDetailStatus && deliveryDetailStatus.status===404){
      notification.warning({
        message: 'Uyarı !',
        description: deliveryDetailStatus.data ? deliveryDetailStatus.data:messages["alert.error"],
      });
    }
  }, [deliveryDetailStatus]);
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const gotoPage = (page) => {
    setState({
      ...state,
      tabID:page
    });
  }
  //******************************************************************************************************************************************************************/
  const handleChangeTasks = (name,e,data) => {
    let temp_task = data;
    temp_task[name]=e;
    temp_task.oldRange=tamamlanmaOrani;
    temp_task.order_type=order_type;
    temp_task.urun_adi=urun_adi;
    temp_task.main_Production_Component_id=dataSource.main_Production_Component_id;
    temp_task.main_Production_Task_id=dataSource.main_Production_Task_id;
    dispatch(updateTask(temp_task))
    .then(res => {
        message.success(messages["alert.savesuccess"]);
        dispatch(getOrderItemDetails({id:projectID}))
    })
    .catch(err=>{
      message.error(messages["alert.error"]);
    })
  };
  //******************************************************************************************************************************************************************/
  const toggleModal = (modalname,value,index,id) => {

    //console.log(modalname,value,index,id)
    if(modalname === "stepModalActive"){
      let temp = task.task_steps;
      if (temp.length>0){ 
        temp.splice(stepIndex, 1);
        setTask({
          ...task,
          task_steps:temp,
        });
      };
      setState({
        ...state,
        stepIndex:null,
        stepModalActive:null
      });
    }else if(modalname==="stepComponentListActive"){
      setState({
        ...state,
        stepComponentListActive:null
      });
    }else if(modalname==="deliveryModalActive"){
      setState({
        ...state,
        deliveryModalActive:value
      });
    }else if(modalname==="orderNotesModalActive"){
      setState({
        ...state,
        orderNotesModalActive:null,
        notesModalIndex:null
      });
    }else if(modalname==="orderInfoModalActive"){
      setState({
        ...state,
        orderInfoModalActive:null,
      });
    }else if(modalname==="componentRowModalActive"){
      setComponentRow(componentRowInit)
      let taskTitleList = [];
      [...new Map(taskList && taskList.map(x=> [x.title,x])).values()].forEach(row => {
        taskTitleList.push({
          value: row.title,
          label: row.title
        });
      });
      setTaskList(taskTitleList);
      setState({
        ...state,
        componentRowModalActive:value,
      });
    }else if(modalname==="materialListModalActive"){
      setState({
        ...state,
        materialListModalActive:value,
      });
      dispatch(getMaterialsList('list',null));
    }else if(modalname==="productListModalActive"){
      setState({
        ...state,
        productListModalActive:value,
      });
      dispatch(getMaterialsList('list',null));
    }else if(modalname==="optionModalActive"){
      setState({
        ...state,
        optionModalActive:value,
        productCodeIndex: value ? index : null
      });
    }else if(modalname==="orderNotesModalActive"){
      setState({
        ...state,
        orderNotesModalActive:null,
        notesModalIndex:null
      });
    }else if(modalname==="printModalActive"){
      if (index==="setting"){
        setState({
          ...state,
          boxLabelSettingActive:value,
          printType:null
        });
      }else{
        setState({
          ...state,
          printModalActive:value,
          printType:index
        });
      }
    }else if (modalname==="deliveryPrintModalActive"){
      setState({
        ...state,
        deliveryPrintModalActive:value
      });
      value && dispatch(getdeliveryDetails({id:id}))
    }else if (modalname==="taskModalActive"){
      setState({
        ...state,
        taskModalActive:value
      });
      const {teklifno,offer,urun_id} = dataSource;
      setTask({
        tasktype:0,
        task_qty:1,
        date:moment(new Date()),
        title:"",
        description:"",
        importance:1,
        status:0,
        order_id:dataSource._id,
        offer_id:teklifno && teklifno,
        company_id:offer && offer.firma_id && offer.firma_id,
        product_id:urun_id
      })
    }else if (modalname==="boxLabelSettingActive"){
      setState({
        ...state,
        boxLabelSettingActive:value
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const openStepModal = (task) => {
    let temp = task.task_steps;
    const maxStepQty= task.task_qty  - temp.reduce((acc, curr) => acc + parseInt(curr.step_qty, 10), 0)
    temp.push({
      step_title:"Yeni İşlem",
      step_qty: maxStepQty,
      step_status:true,
      step_person: User,
      step_date:new Date(),
      step_bolen:task.task_qty/urun_miktari,
      maxStepQty:maxStepQty
    })
    setTask({
      ...task,
      task_steps:temp,
      command:"newStep",   
      urun_adi:urun_adi,
      order_type:order_type,
      main_Production_Component_id:dataSource.main_Production_Component_id,
      main_Production_Task_id:dataSource.main_Production_Task_id
    });
    setState({
      ...state,
      stepIndex:temp.length-1,
      stepModalActive:true,
      tabID:"tasklist"
    })
  }
  //******************************************************************************************************************************************************************/
  const updateTaskStep = (field,value) => {
    let tempdata = task.task_steps;
    tempdata[stepIndex][field] = value;
    setTask({
      ...task,
      task_steps:tempdata,
    });
  }
  //******************************************************************************************************************************************************************/
  const saveTaskStep = () => {
    let tempdata = task;
    tempdata.oldRange=tamamlanmaOrani;
    tempdata.componentList=componentList && componentList.length ? componentList.filter(x=>x.task===task.title):null;
    if(task._id){
      dispatch(updateTask(task))
      .then(res => {
        dispatch(getOrderItemDetails({id:projectID}))
          setState({
            ...state,
            stepModalActive:null,
            stepIndex:null,
            tabID:"tasklist"
          });
          message.success(messages["alert.savesuccess"],2 );
      })
      .catch(err=>{
  
      })
    }
  }
  //******************************************************************************************************************************************************************/
  const onSaveNewTaskRow = () => {
    let tempdata = task
    tempdata.oldRange=tamamlanmaOrani;
    dispatch(createTask(tempdata))
    .then(res => {
      setTimeout(() => {
        dispatch(getOrderItemDetails({id:projectID}))
      }, 1000);
        setState({
          ...state,
          tabID:"tasklist",
          taskModalActive:null
        });
        message.success(messages["alert.createsuccess"], 2);
    })
    .catch(err=>{

    })
  }
  //******************************************************************************************************************************************************************/
  const deleteTaskStepRow = (data,index) => {
    const {title,task_steps,task_qty,} = data ; 
    let _taskQty = task_qty;
     let taskdata = data;
     let temp = task_steps;
     let tempdata = temp[index].stock_opereration;

    if (temp.length>0){ 
      temp.splice(index, 1);
      taskdata.task_steps=temp;
      taskdata.task_qty=_taskQty;
      taskdata.command='deleteTaskStep';
      taskdata.oldRange=tamamlanmaOrani;
      taskdata.deleteids=tempdata;
      dispatch(updateTask(taskdata))
      .then(res => {
        setTimeout(() => {
          dispatch(getOrderItemDetails({id:projectID}))
        }, 100);
        setState({
          ...state,
          tabID:"tasklist"
        });
        message.success(messages["alert.savesuccess"],2 );
      })
    };
  }
  //******************************************************************************************************************************************************************/
  const onDeleteTask = (id) => {
    if(id){
      dispatch(deleteTask(id))
      .then(res => {
        setTimeout(() => {
          dispatch(getOrderItemDetails({id:projectID}))
        }, 100);
        message.success(messages["alert.savesuccess"],2);
        setState({
          ...state,
          tabID:"tasklist"
        })
      })
      .catch(err=>{
  
      })
    }
  }
  //******************************************************************************************************************************************************************/
  const onChangeComponent =(componentData,value)=>{
    let temp = componentData;
    temp.material = value;
    //temp.command ="chanceContolled";
    dispatch(updateComponent(temp))
    .then(res => {
      setTimeout(() => {
        dispatch(getOrderItemDetails({id:projectID}))
      }, 100);
    })
  }
 //******************************************************************************************************************************************************************/
  const onChangeControl = (componentData,value) => {
    if (value===4) return;
    let data = componentData;
    data.controlled=value;
    data.newTodo = false //Number(value)===Number(1) ? true : false;
    data.command = "chanceContolled"
    dispatch(updateComponent(data))
    .then(res => {
      setTimeout(() => {
        dispatch(getOrderItemDetails({id:projectID}))
      }, 2000);
    })
    if(componentData.task==="Yarı Mamül" && value === 1 ){
      Modal.confirm({
        title: 'Onaylıyor musunuz?',
        icon: <ExclamationCircleOutlined />,
        content: 'Yarı Mamül Üretimi Tanımlansın mı ?',
        okText: 'Evet',
        cancelText: 'Hayır',
        onOk:()=>createSubProduction(componentData)
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const createSubProduction = (data) =>{
    const  newOrderItem = {
      urun_id: data.material_group,
      urun_miktari: data.totalQty, 
      MalzemeKontrol_ID:data._id,
      YariMamul_ID:data.YariMamul_ID,
      offer_id:data.offer_id,
      order_id:data.order_id,
      mainproduct:urun_adi,
      companyname:offer.firma_adi,
      command:'createSubProduction'
    };
    dispatch(orderItemActions("createSubProduction",newOrderItem));

  }
  //******************************************************************************************************************************************************************/
  const updateNewComponentData = (Field,eData) => {
    setComponentRow({
      ...componentRow,
      [Field]:eData
    });
  }
  //******************************************************************************************************************************************************************/
  const updateNewTaskData = (Field,eData) => {
    setTask({
      ...task,
      [Field]:eData
    });
  }
  //******************************************************************************************************************************************************************/
  const updateNewDeliveryData = (Field,eData) => {
    setDelivery({
      ...delivery,
      [Field]:eData
    });
  }
  //******************************************************************************************************************************************************************/
  const selectMaterial = (material) => {
    setComponentRow({
      ...componentRow,
      material_category:material.material_category.trim(),
      material_group:material.material_group.trim(),
      material_name:material.material_name,
      material:material._id,
      status:0,
      materialSelectList:[{
        material_name:material.material_name,
        material_category:material.material_category.trim(),
        material_group:material.material_group.trim(),
        material_price:material.material_price,
        material_doviz:material.material_doviz,
        material_unit:material.material_unit,
        stockSum:material.stockSum,
        _id:material._id
      }],
      controlled:(componentRow.materialQty * urun_miktari )<= material.stockSum ? 3:0,
      totalQty:componentRow.materialQty * urun_miktari,
    });
    setState({
      ...state,
      materialListModalActive:null,
    })
  }
  //******************************************************************************************************************************************************************/
  const selectProduct = (product) => {
    setComponentRow({
      ...componentRow,
      material_category:product.model_category,
      material_group:product._id,
      material_name:null,
      material:null,
      status:0,
      materialSelectList:[{
        urun_adi:product.urun_adi,
        stockSum:product.stockSum

      }],
      controlled:(componentRow.materialQty * urun_miktari )<= product.stockSum ? 3:0,
      totalQty:componentRow.materialQty * urun_miktari,
    });
    setState({
      ...state,
      productListModalActive:null,
    })
  }
  //******************************************************************************************************************************************************************/
  const onSaveComponentRow = () => {
    let tempdata = componentRow;
    tempdata.status=0;
    tempdata.order_id=_id;
    tempdata.offer_id=teklifno;
    tempdata.company_id=offer && offer.firma_id;
    tempdata.product_id=urun_id && urun_id;
    tempdata.totalQty=componentRow.materialQty * urun_miktari;
    tempdata.product_id=urun_id && urun_id;
    dispatch(createComponent(tempdata))
    .then(res => {
      setTimeout(() => {
        dispatch(getOrderItemDetails({id:projectID}))
      }, 100);
      message.success(messages["alert.createsuccess"],2 );
      setState({
        ...state,
        componentRowModalActive:null
      })
    });
  }
  //******************************************************************************************************************************************************************/
  const deleteComponentRow = (record) => {
    if(record._id){
      dispatch(deleteComponent(record._id))
      .then(res=>{
        setTimeout(() => {
          dispatch(getOrderItemDetails({id:projectID}))
        }, 1000);
        message.success( messages["alert.deletesuccess"], 2 );
      })
    }
  }
  //******************************************************************************************************************************************************************/
  const createComponentLists=()=>{
    dispatch(orderItemActions("createComponentLists",dataSource))
    .then(x=>{
      setTimeout(() => {
        dispatch(getOrderItemDetails({id:projectID}))
      }, 200);
      message.success(messages["alert.savesuccess"],2);
    })
  }
  //******************************************************************************************************************************************************************/
  const orderInfoModalOpen = () => {
    setState({
      ...state,
      orderInfoModalActive:true
    });
  }
  //******************************************************************************************************************************************************************/
  const onChangeOrderInfo =(Field,eData)=>{
    setDataSource({
      ...dataSource,
      [Field]:eData,
      tamamlanmaOrani: Field==="siparis_durumu" && eData === 4 ? 100: tamamlanmaOrani
    });
  }
  //******************************************************************************************************************************************************************/
  const bg_logo_Open =(e)=>{
    dispatch(setLogoOpen(e))
  }
  //******************************************************************************************************************************************************************/
  const onChangeOptionData = (value ) => {
    let tempdata = dataSource;
    let kode = dataSource.urun_kodu;
    kode[productCodeIndex]=value;
    tempdata.urun_kodu = kode;
    setDataSource({
     ...dataSource,
     tempdata
    });
  }
  //******************************************************************************************************************************************************************/
  const onSaveOrderItem =()=>{
    if(dataSource._id){
      dispatch(updateOrderItem(dataSource))
      .then(res=>{
        message.success(messages["alert.savesuccess"],2);
      })
    }
  }
  //******************************************************************************************************************************************************************/
  const createTaskList = () => {
    dispatch(orderItemActions("createTasks",dataSource))
    .then(res=>{
      setTimeout(() => {
        dispatch(getOrderItemDetails({id:projectID}))
        message.success(messages["alert.savesuccess"], 2 );
      }, 200);
        
    })
  }
  //******************************************************************************************************************************************************************/
  const printOrders=()=>{
    setState({
      ...state,
      printModalActive:true,

    });
  }
  //******************************************************************************************************************************************************************/
  const onChangeDeliveryRowStatus =(data,field,value)=>{
    let temp = data;
    temp[field]=value
    dispatch(updateDelivery(temp))
    .then(res => {
      setTimeout(() => {
        dispatch(getOrderItemDetails({id:projectID}))
      }, 100);
      message.success(messages["alert.savesuccess"],2);
        
    })
  }
  //******************************************************************************************************************************************************************/
  const newDeliveryRow = () => {
    const teslimedilmis = deliveriedSum;
    const initDelivery = {
      date : moment(new Date()),
      component_type:order_type,
      company_id : offer.firma_id,
      company : offer.firma_adi, 
      product_id : order_type==="product" ? urun_id : material_id ,
      productname : urun_adi,
      order_id : teklifno,
      orderitem_id :_id,
      qty : urun_miktari - teslimedilmis,
      unit : urun_birimi,
      description: "" ,
      price : urun_fiyati ,
      doviz : urun_dovizi ,
      sumPrice : urun_fiyati *  (urun_miktari - teslimedilmis),
      status : 0,
      statusdescription : 0,
      deliverer : User,
      receiver : "",
      deliveried_id : deliveried_id,
      max:urun_miktari - teslimedilmis,
      orderQty:urun_miktari,
      deliveriedSum:deliveriedSum,
      command:"Uretimden_Teslimat"
    };
    setDelivery(initDelivery);
    setState({
      ...state,
      deliveryModalActive:true,
    });

  }
  //******************************************************************************************************************************************************************/
  const onSaveNewDelivery = () => {
    dispatch(createDelivery(delivery))
    .then(res => {
      setTimeout(() => {
        dispatch(getOrderItemDetails({id:projectID}))
      }, 100);
      message.success(messages["alert.createsuccess"],2);
      setState({
        ...state,
        deliveryModalActive:null,
      })
    })
  }
  //******************************************************************************************************************************************************************/
  const deleteLastCheckListRow = (acTestRow) =>{
    dispatch(deleteAcTest(acTestRow._id))
  }
  //******************************************************************************************************************************************************************/
  const deleteDeliveryRow = (record) => {
    record.totaldeliveried = teslim_edilmis;//back ende veri gönderiyoruz teslim edilmiş adeti
    if(record._id){
      dispatch(deleteDelivery(record))
      .then(x=>{
        setTimeout(() => {
          dispatch(getOrderItemDetails({id:projectID}))
        }, 100);
          message.success(messages["alert.deletesuccess"],2);
      })   
    }
  }
  //******************************************************************************************************************************************************************/
  const refreshDataSource = () => {
    setTimeout(() => {
      dispatch(getOrderItemDetails({id:projectID}))
    }, 100);
    message.success(messages["alert.updated"],2);
  }
  //******************************************************************************************************************************************************************/
  const addNewOrderNote = () => {
    let temp = dataSource.orderNotes;
    temp.push("")
    setDataSource({
      ...dataSource,
      orderNotes:temp
    });
    setState({
      ...state,
      notesModalIndex: temp.length-1,
      orderNotesModalActive:true
    });
  };
  //******************************************************************************************************************************************************************/
  const onChangeNotesField  = (value) => {
    let tempdata = dataSource.orderNotes;
    tempdata[state.notesModalIndex] = value;
    setDataSource({
      ...dataSource,
      orderNotes:tempdata,
    });
  }
  //******************************************************************************************************************************************************************/
  const onDeleteOrderNotes  = (index) => {
    dataSource.orderNotes.splice(index, 1);
  }
  //******************************************************************************************************************************************************************/
  const deleteProject  = () => {
    dispatch(deleteOfferItem({id:dataSource._id}))
    .then(x=>{
     history.push({pathname:"/admin/project"})
    })
  }
  //******************************************************************************************************************************************************************/

  console.log(dataSource)
  return (
    <>
      <PageHeader
        title={messages["production.details"]}
        buttons={buttonList}
      />
      <Main>
        <Spin spinning={orderItemDetailLoading} delay={500}>
        <Cards headless>
          <GeneralContent>
            <Row gutter={20}>
              <Col xxl={4} lg={4} md={6} sm={6} xs={24}>
                <ProjectImageCard>
                  <div className="card team-card">
                    <Cards headless>
                        <figure>
                          <NavLink to={`/admin/projectdetails/`+_id}>
                            <img src={productImage} alt="" />
                          </NavLink>
                        </figure>
                    </Cards>
                  </div>
                </ProjectImageCard>
              </Col>
              <Col xxl={12} lg={12} md={18} sm={18} xs={24}>
                <ProjectDetail>
                  <div className="project-title">
                    <h2>{offer && offer.firma_adi.slice(0,40)}</h2>
                  </div>
                  <div className="project-desc">
                    <h1>{urun_adi && urun_adi.length> 50 ? urun_adi.slice(0,50):urun_adi}</h1>
                    <h2>{urun_kodu && getProductCode("",urun_kodu[0],urun_kodu[1],urun_kodu[2],urun_kodu[3],urun_kodu[4],urun_kodu[5]) } <FontAwesomeIcon style={{marginLeft:'15px'}}icon={faEdit} size="sm" onClick={()=>toggleModal("optionModalActive",true,1)}/></h2>
                  </div>
                  <div className="project-timing">
                    <div>
                      <span>{messages["order.date"]}</span>
                      <strong>{siparis_tarihi ? moment(siparis_tarihi).format("DD.MM.YYYY"):"--.--.---"}</strong>
                    </div>
                    <div>
                      <span>{messages["order.startdate"]}</span>
                      <strong>{baslama_tarihi && baslama_tarihi ? moment(baslama_tarihi).format("DD.MM.YYYY"): "--.---.----"}</strong>
                    </div>
                    <div>
                      <span>{messages["order.enddate"]}</span>
                      <strong>{bitis_tarihi && bitis_tarihi ?  moment(bitis_tarihi).format("DD.MM.YYYY") : "--.--.----"}</strong>
                    </div>
                  </div>
                </ProjectDetail>
              </Col>
              <Col xxl={8} lg={8} md={24} sm={24} xs={24}>
                <ProjectResult>
                    <div className="project-tags">
                      <Tag  className={orderClass}>{orderType}</Tag>
                      <Tag  className={color}>{messages["order.orderStatus"+siparis_durumu]}</Tag>
                      {orderItemDetailLoading && <Spin indicator={faSpinner} style={{marginLeft:'10px'}} size='small'/>}
                    </div>
                    <div className="project-qty">
                      <div>
                        <span>{messages["order.componentstatus2"]}</span>
                        <strong>{urun_miktari} {messages["genel.Adet"]}</strong>
                      </div>
                      <div>
                        <span>{messages["order.orderStatus5"]}</span>
                        <strong>{teslim_edilmis} {messages["genel.Adet"]}</strong>
                      </div>
                    </div>
                    <div className="project-progress">
                      <Progress
                        percent={tamamlanmaOrani > 0 ? tamamlanmaOrani.toFixed(1):0}
                        strokeWidth={5}
                        status="primary"
                        className="progress-primary"
                      />
                      {order_type==="product"  ? 
                        <Tag className={(!notComplatedHalfComponentLength && notComplatedComponentLength===0   || ready)  ?"green":"red"} >
                            {(!notComplatedHalfComponentLength && notComplatedComponentLength===0   || ready)   ? (siparis_durumu < 4 ? " Üretim için Hazır" :siparis_durumu === 5 ?" Teslimat Tamamlanmış" :" Teslimat için Hazır"):" Malzeme Listesi Tamamlanmamış"}
                        </Tag>:
                        <Tag className="green">
                            { (!notComplatedHalfComponentLength && notComplatedComponentLength===0   || ready)   ? (siparis_durumu < 4 ? " Hazırlanıyor.." :siparis_durumu === 5 ?" Teslimat Tamamlanmış" :" Teslimat için Hazır"):" Malzeme Listesi Tamamlanmamış"}
                        </Tag>}
                        {order_type==="product"  && (product.model_category===0 || product.model_category===1 || product.model_category===4 || product.model_category===2) && <Tag className={lastCheckLength < urun_miktari*2 ? "red":"green"} >{lastCheckLength < urun_miktari*2 ?(lastCheckLength.length ?  "AC Test Yapılamamış":(lastCheckLength+ " Adet - Test Devam Ediyor")):(lastCheckLength + " Adet Test Tamamlanmış")} </Tag>}
                    </div>
                </ProjectResult>
              </Col>
            </Row>
          </GeneralContent>
          <Col xs={24}>
            {urun_kodu && urun_kodu.length ?  
            <ProjectProductDetail>
              <Row>
                <Col xxl={5} lg={5} md={5} sm={24} xs={24}>
                  <div className="state-single">
                    <div className="color-primary">
                      <NavLink to="#" onClick={()=>toggleModal("optionModalActive",true,3)}>
                        <FontAwesomeIcon icon={faLightbulb} size="2x"/>
                      </NavLink>
                    </div>
                    <div>
                      <h1>{(urun_kodu[3] ? urun_kodu[3]: " ---- ")}</h1>
                      <p>{messages["product.cct"]}</p>
                    </div>
                  </div> 
                </Col>
                <Col xxl={4} lg={4} md={4} sm={24} xs={24}>
                  <div className="state-single">
                    <div className="color-secondary">
                      <NavLink to="#" onClick={()=>toggleModal("optionModalActive",true,2)}>
                        <FontAwesomeIcon icon={faEye} size="2x"/>
                      </NavLink>
                    </div>
                    <div>
                      <h1>{(urun_kodu[2] ? urun_kodu[2]: " ---- ")}</h1>
                      <p>{messages["product.optik"]}</p>
                    </div>
                  </div>
                </Col>
                <Col xxl={5} lg={5} md={5} sm={24} xs={24}>
                  <div className="state-single">
                    <div className="color-secondary">
                      <NavLink to="#" onClick={()=>toggleModal("optionModalActive",true,6)}>
                        <FontAwesomeIcon icon={faPalette} size="2x"/>
                      </NavLink>
                    </div>
                    <div>
                      <h1>{(urun_kodu[6] ? urun_kodu[6].slice(0,6): " ---- ")}</h1>
                      <p>{messages["product.bodycolor"]}</p>
                    </div>
                  </div>
                </Col>
                <Col xxl={5} lg={5} md={5} sm={24} xs={24}>
                  <div className="state-single">
                    <div className="color-primary">
                      <NavLink to="#" onClick={()=>toggleModal("optionModalActive",true,7)}>
                        <FontAwesomeIcon icon={faCircleArrowRight} size="2x"/>
                      </NavLink>
                    </div>
                    <div>
                      <h1>{(urun_kodu[7] ? urun_kodu[7]: " ---- ")}</h1>
                      <p>{messages["product.ledmark"]}</p>
                    </div>
                  </div>
                </Col>
                <Col xxl={5} lg={5} md={5} sm={24} xs={24}>
                  <div className="state-single">
                    <div className="color-primary">
                      <NavLink to="#" onClick={()=>toggleModal("optionModalActive",true,8)}>
                        <FontAwesomeIcon icon={faCircleArrowRight} size="2x"/>
                      </NavLink>
                    </div>
                    <div>
                      <h1>{(urun_kodu[8] ? urun_kodu[8]: " ---- ")}</h1>
                      <p>{messages["product.leddriver"]}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </ProjectProductDetail>
            :null}
          </Col>
          <GeneralContent>
            <Steps
              size='small'
              current={state.projectSteps.length}
              items={state.projectSteps}
            />
          </GeneralContent>
          {dataSource && dataSource.orderNotes && dataSource.orderNotes.length ?
          <GeneralContent>
            {dataSource && dataSource.orderNotes && dataSource.orderNotes.length ? dataSource.orderNotes.map((x,i)=>{
              return(
                <Tag key={i} style={{marginRight:'10px'}} className='red' onClose={()=>onDeleteOrderNotes(i)} closable>{x}</Tag>
              )
            }):null}
          </GeneralContent>
          :null}
        </Cards>
        {((orderItemDetail.urun_id  || orderItemDetail.material_id) && (order_type==="product"  || order_type==="material" || order_type==="subproduction")) ?
        <Col xs={24}>
          <ProjectTabContent style={{marginTop:"25px"}}>
            <Cards
              bodyStyle={{minHeight:'360px'}}
              title={
                <nav>
                  <NavLink to={`#`} onClick={()=>gotoPage("componentlist")} activeClassName={tabID === "componentlist" ? "active":""}>{messages["production.components"]}</NavLink>
                  &nbsp; &nbsp;
                  <NavLink to={`#`} onClick={()=>gotoPage("tasklist")}  activeClassName={tabID === "tasklist" ? "active":""}>{messages["task.list"]}</NavLink>
                  &nbsp; &nbsp;
                  <NavLink to={`#`} onClick={()=>gotoPage("deliveryList")} activeClassName={tabID === "deliveryList" ? "active":""}>{messages["production.delivery"]}</NavLink>
                  &nbsp; &nbsp;
                  <NavLink to={`#`} onClick={()=>gotoPage("lastCheckList")} activeClassName={tabID === "lastCheckList" ? "active":""}>{messages["production.lastcheck"]}</NavLink>
                </nav>
              }
              isbutton={
                tabID==="tasklist" ?
                <>
                  <Popover content={messages["button.add"]+" "+messages["company.task"]}>
                    <Button shape="circle"  onClick={()=>toggleModal("taskModalActive",true)} size='small' icon={<FontAwesomeIcon icon={faCirclePlus}/>} style={{marginRight:'10px'}}/>
                  </Popover>
                    &nbsp;
                    &nbsp;
                  <Popconfirm
                    title="Görev Listesi Silinecek Yenisi oluşturulacak Onaylıyor musunuz ?"
                    okText={messages["genel.yes"]}
                    cancelText={messages["genel.no"]}
                    onConfirm={()=>createTaskList()}
                  >
                    <Button shape="circle" size='small' icon={<FontAwesomeIcon icon={faListCheck}/>} style={{marginRight:'10px'}}/>
                  </Popconfirm>
                </>
                :tabID==="componentlist" ?
                <>
                  <Popover content={messages["button.add"]+" "+messages["material.material"]}>
                    <Button shape="circle" size='small' onClick={()=>toggleModal("componentRowModalActive",true)} icon={<FontAwesomeIcon icon={faCirclePlus}/>} style={{marginRight:'10px'}}/>
                  </Popover>
                  &nbsp;
                  &nbsp;
                  <Popconfirm
                    title="Malzeme Listesi Silinecek Yenisi oluşturulacak Onaylıyor musunuz ?"
                    okText={messages["genel.yes"]}
                    cancelText={messages["genel.no"]}
                    onConfirm={()=>createComponentLists()}
                  >
                    <Button shape="circle" size='small' icon={<FontAwesomeIcon icon={faListCheck}/>} style={{marginRight:'10px'}}/> 
                  </Popconfirm>
                </>
                :tabID==="deliveryList" ?
                <Popover content={messages["button.add"]+" "+messages["production.delivery"]}>
                  <Button shape="circle" size='small'onClick={()=>newDeliveryRow()} icon={<FontAwesomeIcon icon={faCirclePlus}/>} style={{marginRight:'10px'}}/>
                </Popover>
                :tabID==="lastCheckList" ?
                  <Popover content={messages["button.update"]}>
                    <Button shape="circle" size='small' onClick={()=>refreshDataSource()} icon={<FontAwesomeIcon icon={faRefresh}/>} style={{marginRight:'10px'}}/>
                  </Popover>
                :null
              }
            >
            {tabID==="componentlist" ?
                (componentList && componentList.length ? componentList.map((component,index)=>{
                  const SelectedMaterial = component.materialSelectList && component.materialSelectList.length && component.materialSelectList.filter(x=>x._id===component.material)[0]
                  return(
                    <MaterialList 
                      key={index}
                      messages={messages}
                      component={component}
                      product={dataSource}
                      SelectedMaterial={SelectedMaterial}
                      onChangeComponent={onChangeComponent}
                      onChangeControl={onChangeControl}
                      deleteComponentRow={deleteComponentRow}
                    />
                  )}):null)
            :tabID==="tasklist" ?
                (taskList && taskList.length ? 
                taskList.sort((a, b) => (a.importance > b.importance) ? -1 : 1 ).map((task,i)=>{
                  const noMaterialCount = componentList && componentList.length && componentList.filter(x=>x.task === task.title && x.material===null)
                  return(
                    <TaskList 
                      key={i}
                      index={i}
                      messages={messages}
                      task={task}
                      noMaterialCount={noMaterialCount}
                      taskloading={orderItemDetailLoading}
                      openStepModal={openStepModal}
                      deleteTaskStepRow={deleteTaskStepRow}
                      taskStatusOptions={statusList}
                      taskTypeList={taskTypeList}
                      onDeleteTask={onDeleteTask}
                      handleChangeTasks={handleChangeTasks}
                    />
                  )
                }):null)
            :tabID==="deliveryList" ?
                (deliveryList && deliveryList.length ? deliveryList.map((delivery,i)=>{
                  return(
                    <DeliveryList
                      key={i}
                      messages={messages}
                      delivery={delivery}
                      onChangeDeliveryRowStatus={onChangeDeliveryRowStatus}
                      deleteDeliveryRow={deleteDeliveryRow}
                      toggleModal={toggleModal}
                    />
                  )}):null)
            :tabID==="lastCheckList" ?
                (lastCheckList && lastCheckList.length ? lastCheckList.map((lastCheckRow,i)=>{
                  return(
                    <LastCheckList
                      key={i}
                      messages={messages}
                      lastCheckRow={lastCheckRow}
                      deleteLastCheckListRow={deleteLastCheckListRow}
                    />
                  )}):null)
            :null}
            </Cards>
          </ProjectTabContent>
        </Col>
        :null
        }
        
        <StepModal
          messages={messages}
          stepIndex={state.stepIndex}
          stepModalActive={state.stepModalActive}
          task_steps={task && task.task_steps}
          toggleModal={toggleModal}
          saveTask={saveTaskStep}
          onSaveComponentRow={onSaveComponentRow}
          updateTaskStep={updateTaskStep}
          TaskList={taskTitleList}
        />
        <OptionModal 
          messages={messages}
          option={urun_kodu[productCodeIndex]}
          optionModalActive={state.optionModalActive}
          productCodeIndex={state.productCodeIndex}
          toggleModal={toggleModal}
          onChangeOptionData={onChangeOptionData}
          CCTList={CCTList}
          optikList={optikList}
        />
        {state.componentRowModalActive &&
        <ComponentModal
          messages={messages}
          componentRow={componentRow}
          TaskList={taskTitleList}
          componentRowModalActive={state.componentRowModalActive}
          toggleModal={toggleModal}
          updateNewComponentData={updateNewComponentData}
          onSaveComponentRow={onSaveComponentRow}
          
        />}
        <MaterialListModal
          messages={messages}
          materialListModalActive={state.materialListModalActive}
          toggleModal={toggleModal}
          selectMaterial={selectMaterial}
        />
        <ProductListModal
          messages={messages}
          productListModalActive={state.productListModalActive}
          toggleModal={toggleModal}
          selectProduct={selectProduct}
        />
        <ActivitesModal 
          messages={messages}
          task={task}
          taskModalActive={state.taskModalActive}
          toggleModal={toggleModal}
          updateNewTaskData={updateNewTaskData}
          onSaveNewTaskRow={onSaveNewTaskRow}
        />
        <DeliveryModal 
          messages={messages}
          delivery={delivery}
          deliveryModalActive={state.deliveryModalActive}
          toggleModal={toggleModal}
          onSaveNewDelivery={onSaveNewDelivery}
          updateNewDeliveryData={updateNewDeliveryData}
          UnitList={UnitList}
        />
        <ReportModal
          messages={messages}
          toggleModal={toggleModal}
          printModalActive={state.printModalActive}
          printType={printType}
        />
        <DeliveryPrintModal 
          messages={messages}
          deliveryPrintModalActive={state.deliveryPrintModalActive}
          toggleModal={toggleModal}
        />
        <OrderInfoModal 
          messages={messages}
          order={dataSource}
          orderInfoModalActive={state.orderInfoModalActive}
          toggleModal={toggleModal}
          onChangeOrderInfo={onChangeOrderInfo}
        />
        {state.orderNotesModalActive && 
        <OrderNoteModal 
          messages={messages}
          orderNote={dataSource.orderNotes[state.notesModalIndex]}
          orderNotesModalActive={state.orderNotesModalActive}
          toggleModal={toggleModal}
          onChangeNotesField={onChangeNotesField}
        />}
        <BoxLabelSetting
          messages={messages}
          logoOpen={logoOpen}
          boxLabelSettingActive={state.boxLabelSettingActive}
          toggleModal={toggleModal}
          bg_logo_Open={bg_logo_Open}
        />
      </Spin>
      </Main>
    </>
  );
}


export {ProjectDetails};
