export const CHANGE_LOCALE = "CHANGE_LOCALE";



export const COMPANY_ACTION = "COMPANY_ACTION";
export const COMPANY_SUCCESS = "COMPANY_SUCCESS";
export const COMPANY_ERROR = "COMPANY_ERROR";
export const UPDATE_COMPANY_ACTION = "UPDATE_COMPANY_ACTION";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";
export const CREATE_COMPANY0_ACTION = "CREATE_COMPANY0_ACTION";
export const CREATE_COMPANY0_SUCCESS = "CREATE_COMPANY0_SUCCESS";
export const CREATE_COMPANY0_FAILURE = "CREATE_COMPANY0_FAILURE";



/* AUTH */

export const USER_LOGIN_ACTION = "USER_LOGIN_ACTION";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_REAUTH_ACTION = "USER_REAUTH_ACTION";
export const USER_REAUTH_FAILURE = "USER_REAUTH_FAILURE";
export const USER_REAUTH_SUCCESS = "USER_REAUTH_SUCCESS";
export const USER_LOGOUT_ACTION = "USER_LOGOUT_ACTION";
export const CLEAR_ERRORS = "CLEAR_ERRORS";


/* USERS  ********************************************************/
export const USERS_GET_LIST= "USERS_GET_LIST";
export const USERS_GET_LIST_SUCCESS= "USERS_GET_LIST_SUCCESS";
export const USERS_GET_LIST_ERROR= "USERS_GET_LIST_ERROR";



export const SET_USER_DATA= "SET_USER_DATA";
export const USER_DETAILS_ACTION= "USER_DETAILS_ACTION";
export const USER_DETAILS_SUCCESS= "USER_DETAILS_SUCCESS";
export const USER_DETAILS_ERROR= "USER_DETAILS_ERROR";
export const CHANGE_PASSWORD_ACTION= "CHANGE_PASSWORD_ACTION";
export const CHANGE_PASSWORD_SUCCESS= "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE= "CHANGE_PASSWORD_FAILURE";
export const FORGOT_PASSWORD_ACTION= "FORGOT_PASSWORD_ACTION";
export const FORGOT_PASSWORD_SUCCESS= "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE= "FORGOT_PASSWORD_FAILURE";
export const RESET_PASSWORD_ACTION= "RESET_PASSWORD_ACTION";
export const RESET_PASSWORD_SUCCESS= "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE= "RESET_PASSWORD_FAILURE";
export const CREATE_USER_ACTION= "CREATE_USER_ACTION";
export const CREATE_USER_SUCCESS= "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR= "CREATE_USER_ERROR";
export const UPDATE_USER_ACTION= "UPDATE_USER_ACTION";
export const UPDATE_USER_SUCCESS= "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE= "UPDATE_USER_FAILURE";
export const DELETE_USER_ACTION= "DELETE_USER_ACTION";
export const DELETE_USER_SUCCESS= "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE= "DELETE_USER_FAILURE";

/* COMPANIES  ********************************************************/
export const COMPANY_LIST_GET_LIST= "COMPANY_LIST_GET_LIST";
export const COMPANY_LIST_GET_LIST_SUCCESS= "COMPANY_LIST_GET_LIST_SUCCESS";
export const COMPANY_LIST_GET_LIST_ERROR= "COMPANY_LIST_GET_LIST_ERROR";

export const COMPANY_DETAILS_ACTION= "COMPANY_DETAILS_ACTION";
export const COMPANY_DETAILS_SUCCESS= "COMPANY_DETAILS_SUCCESS";
export const COMPANY_DETAILS_ERROR= "COMPANY_DETAILS_ERROR";
export const UPDATE_COMPANY_DETAILS_ACTION= "UPDATE_COMPANY_DETAILS_ACTION";
export const UPDATE_COMPANY_DETAILS_SUCCESS= "UPDATE_COMPANY_DETAILS_SUCCESS";
export const UPDATE_COMPANY_DETAILS_FAILURE= "UPDATE_COMPANY_DETAILS_FAILURE";
export const CREATE_COMPANY_ACTION= "CREATE_COMPANY_ACTION";
export const CREATE_COMPANY_SUCCESS= "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAILURE= "CREATE_COMPANY_FAILURE";
export const DELETE_COMPANY_ACTION= "DELETE_COMPANY_ACTION";
export const DELETE_COMPANY_SUCCESS= "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE= "DELETE_COMPANY_FAILURE";

/* ORDERITEM LIST   ********************************************************/
export const ORDER_GET_LIST= "ORDER_GET_LIST";
export const ORDER_GET_LIST_SUCCESS= "ORDER_GET_LIST_SUCCESS";
export const ORDER_GET_LIST_ERROR= "ORDER_GET_LIST_ERROR";

export const ORDERITEM_DETAILS_ACTION= "ORDERITEM_DETAILS_ACTION";
export const ORDERITEM_DETAILS_SUCCESS= "ORDERITEM_DETAILS_SUCCESS";
export const ORDERITEM_DETAILS_ERROR= "ORDERITEM_DETAILS_ERROR";
export const UPDATE_ORDERITEM_ACTION= "UPDATE_ORDERITEM_ACTION";
export const UPDATE_ORDERITEM_SUCCESS= "UPDATE_ORDERITEM_SUCCESS";
export const UPDATE_ORDERITEM_FAILURE= "UPDATE_ORDERITEM_FAILURE";
export const CREATE_ORDERITEM_ACTION= "CREATE_ORDERITEM_ACTION";
export const CREATE_ORDERITEM_SUCCESS= "CREATE_ORDERITEM_SUCCESS";
export const CREATE_ORDERITEM_FAILURE= "CREATE_ORDERITEM_FAILURE";
export const DELETE_ORDERITEM_ACTION= "DELETE_ORDERITEM_ACTION";
export const DELETE_ORDERITEM_SUCCESS= "DELETE_ORDERITEM_SUCCESS";
export const DELETE_ORDERITEM_FAILURE= "DELETE_ORDERITEM_FAILURE";

export const ORDERITEM_ACTION= "ORDERITEM_ACTION";
export const ORDERITEM_ACTION_SUCCESS= "ORDERITEM_ACTION_SUCCESS";
export const ORDERITEM_ACTION_FAILURE= "ORDERITEM_ACTION_FAILURE";
/* TASK LIST   ********************************************************/
export const TASK_GET_LIST= "TASK_GET_LIST";
export const TASK_GET_LIST_SUCCESS= "TASK_GET_LIST_SUCCESS";
export const TASK_GET_LIST_ERROR= "TASK_GET_LIST_ERROR";

export const TASK_DETAILS_ACTION= "TASK_DETAILS_ACTION";
export const TASK_DETAILS_SUCCESS= "TASK_DETAILS_SUCCESS";
export const TASK_DETAILS_ERROR= "TASK_DETAILS_ERROR";

export const CREATE_TASK_ACTION= "CREATE_TASK_ACTION";
export const CREATE_TASK_SUCCESS= "CREATE_TASK_SUCCESS";
export const CREATE_TASK_ERROR= "CREATE_TASK_ERROR";

export const UPDATE_TASK_ACTION= "UPDATE_TASK_ACTION";
export const UPDATE_TASK_SUCCESS= "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_FAILURE= "UPDATE_TASK_FAILURE";

export const DELETE_TASK_ACTION= "DELETE_TASK_ACTION";
export const DELETE_TASK_SUCCESS= "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAILURE= "DELETE_TASK_FAILURE";
/* COMPONENT LIST   ********************************************************/
export const COMPONENT_GET_LIST= "COMPONENT_GET_LIST";
export const COMPONENT_GET_LIST_SUCCESS= "COMPONENT_GET_LIST_SUCCESS";
export const COMPONENT_GET_LIST_ERROR= "COMPONENT_GET_LIST_ERROR";

export const COMPONENT_DETAILS_ACTION= "COMPONENT_DETAILS_ACTION";
export const COMPONENT_DETAILS_SUCCESS= "COMPONENT_DETAILS_SUCCESS";
export const COMPONENT_DETAILS_ERROR= "COMPONENT_DETAILS_ERROR";
export const CREATE_COMPONENT_ACTION= "CREATE_COMPONENT_ACTION";
export const CREATE_COMPONENT_ERROR= "CREATE_COMPONENT_ERROR";
export const CREATE_COMPONENT_SUCCESS= "CREATE_COMPONENT_SUCCESS";

export const UPDATE_COMPONENT_ACTION= "UPDATE_COMPONENT_ACTION";
export const UPDATE_COMPONENT_SUCCESS= "UPDATE_COMPONENT_SUCCESS";
export const UPDATE_COMPONENT_FAILURE= "UPDATE_COMPONENT_FAILURE";
export const DELETE_COMPONENT_ACTION= "DELETE_COMPONENT_ACTION";
export const DELETE_COMPONENT_SUCCESS= "DELETE_COMPONENT_SUCCESS";
export const DELETE_COMPONENT_FAILURE= "DELETE_COMPONENT_FAILURE";


export const COMPONENT_SUM_GET_LIST= "COMPONENT_SUM_GET_LIST";
export const COMPONENT_SUM_GET_LIST_SUCCESS= "COMPONENT_SUM_GET_LIST_SUCCESS";
export const COMPONENT_SUM_GET_LIST_ERROR= "COMPONENT_SUM_GET_LIST_ERROR";


/* MATERIALS */
export const MATERIALS_GET_LIST= "MATERIALS_GET_LIST";
export const MATERIALS_GET_LIST_SUCCESS= "MATERIALS_GET_LIST_SUCCESS";
export const MATERIALS_GET_LIST_ERROR= "MATERIALS_GET_LIST_ERROR";

export const MATERIAL_DETAILS_ACTION= "MATERIAL_DETAILS_ACTION";
export const MATERIAL_DETAILS_SUCCESS= "MATERIAL_DETAILS_SUCCESS";
export const MATERIAL_DETAILS_ERROR= "MATERIAL_DETAILS_ERROR";
export const CREATE_MATERIAL_ACTION= "CREATE_MATERIAL_ACTION";
export const CREATE_MATERIAL_SUCCESS= "CREATE_MATERIAL_SUCCESS";
export const CREATE_MATERIAL_ERROR= "CREATE_MATERIAL_ERROR";
export const UPDATE_MATERIAL_ACTION= "UPDATE_MATERIAL_ACTION";
export const UPDATE_MATERIAL_SUCCESS= "UPDATE_MATERIAL_SUCCESS";
export const UPDATE_MATERIAL_FAILURE= "UPDATE_MATERIAL_FAILURE";
export const DELETE_MATERIAL_ACTION= "DELETE_MATERIAL_ACTION";
export const DELETE_MATERIAL_SUCCESS= "DELETE_MATERIAL_SUCCESS";
export const DELETE_MATERIAL_FAILURE= "DELETE_MATERIAL_FAILURE";

/* OFFERS  */
export const OFFER_GET_LIST= "OFFER_GET_LIST";
export const OFFER_GET_LIST_SUCCESS= "OFFER_GET_LIST_SUCCESS";
export const OFFER_GET_LIST_ERROR= "OFFER_GET_LIST_ERROR";

export const ORDER_SUM_LIST= "ORDER_SUM_LIST";
export const ORDER_SUM_LIST_SUCCESS= "ORDER_SUM_LIST_SUCCESS";
export const ORDER_SUM_LIST_ERROR= "ORDER_SUM_LIST_ERROR";

export const COMPANY_OFFER_GET_LIST= "COMPANY_OFFER_GET_LIST";
export const COMPANY_OFFER_GET_LIST_SUCCESS= "COMPANY_OFFER_GET_LIST_SUCCESS";
export const COMPANY_OFFER_GET_LIST_ERROR= "COMPANY_OFFER_GET_LIST_ERROR";

export const OFFER_DETAILS_ACTION= "OFFER_DETAILS_ACTION";
export const OFFER_DETAILS_SUCCESS= "OFFER_DETAILS_SUCCESS";
export const OFFER_DETAILS_ERROR= "OFFER_DETAILS_ERROR";
export const CREATE_OFFER_ACTION= "CREATE_OFFER_ACTION";
export const CREATE_OFFER_SUCCESS= "CREATE_OFFER_SUCCESS";
export const CREATE_OFFER_ERROR= "CREATE_OFFER_ERROR";
export const UPDATE_OFFER_ACTION= "UPDATE_OFFER_ACTION";
export const UPDATE_OFFER_SUCCESS= "UPDATE_OFFER_SUCCESS";
export const UPDATE_OFFER_FAILURE= "UPDATE_OFFER_FAILURE";
export const DELETE_OFFER_ACTION= "DELETE_OFFER_ACTION";
export const DELETE_OFFER_SUCCESS= "DELETE_OFFER_SUCCESS";
export const DELETE_OFFER_FAILURE= "DELETE_OFFER_FAILURE";

/* PRODUCTSS */
export const PRODUCT_GET_LIST= "PRODUCT_GET_LIST";
export const PRODUCT_GET_LIST_SUCCESS= "PRODUCT_GET_LIST_SUCCESS";
export const PRODUCT_GET_LIST_ERROR= "PRODUCT_GET_LIST_ERROR";

export const PRODUCT_DETAILS_ACTION= "PRODUCT_DETAILS_ACTION";
export const PRODUCT_DETAILS_SUCCESS= "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_ERROR= "PRODUCT_DETAILS_ERROR";
export const CREATE_PRODUCT_ACTION= "CREATE_PRODUCT_ACTION";
export const CREATE_PRODUCT_SUCCESS= "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_ERROR= "CREATE_PRODUCT_ERROR";
export const UPDATE_PRODUCT_ACTION= "UPDATE_PRODUCT_ACTION";
export const UPDATE_PRODUCT_SUCCESS= "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE= "UPDATE_PRODUCT_FAILURE";
export const DELETE_PRODUCT_ACTION= "DELETE_PRODUCT_ACTION";
export const DELETE_PRODUCT_SUCCESS= "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE= "DELETE_PRODUCT_FAILURE";

/* MODELS */
export const MODEL_GET_LIST= "MODEL_GET_LIST";
export const MODEL_GET_LIST_SUCCESS= "MODEL_GET_LIST_SUCCESS";
export const MODEL_GET_LIST_ERROR= "MODEL_GET_LIST_ERROR";

export const MODEL_DETAILS_ACTION= "MODEL_DETAILS_ACTION";
export const MODEL_DETAILS_SUCCESS= "MODEL_DETAILS_SUCCESS";
export const MODEL_DETAILS_ERROR= "MODEL_DETAILS_ERROR";
export const CREATE_MODEL_ACTION= "CREATE_MODEL_ACTION";
export const CREATE_MODEL_SUCCESS= "CREATE_MODEL_SUCCESS";
export const CREATE_MODEL_ERROR= "CREATE_MODEL_ERROR";
export const UPDATE_MODEL_ACTION= "UPDATE_MODEL_ACTION";
export const UPDATE_MODEL_SUCCESS= "UPDATE_MODEL_SUCCESS";
export const UPDATE_MODEL_FAILURE= "UPDATE_MODEL_FAILURE";
export const DELETE_MODEL_ACTION= "DELETE_MODEL_ACTION";
export const DELETE_MODEL_SUCCESS= "DELETE_MODEL_SUCCESS";
export const DELETE_MODEL_FAILURE= "DELETE_MODEL_FAILURE";

/* TCMB */
export const TCMB_ACTION= "TCMB_ACTION";
export const TCMB_SUCCESS= "TCMB_SUCCESS";
export const TCMB_ERROR= "TCMB_ERROR";


export const TCMB_GET_LIST= "TCMB_GET_LIST";
export const TCMB_GET_LIST_SUCCESS= "TCMB_GET_LIST_SUCCESS";
export const TCMB_GET_LIST_ERROR= "TCMB_GET_LIST_ERROR";

/* IMAGE */
export const GET_IMAGE_LIST= "GET_IMAGE_LISTGET_IMAGE_LIST";
export const GET_IMAGE_LIST_SUCCESS= "GET_IMAGE_LIST_SUCCESS";
export const GET_IMAGE_LIST_ERROR= "GET_IMAGE_LIST_ERROR";

/* STOCK */

export const STOCK_GET_LIST= "STOCK_GET_LIST";
export const STOCK_GET_LIST_SUCCESS= "STOCK_GET_LIST_SUCCESS";
export const STOCK_GET_LIST_ERROR= "STOCK_GET_LIST_ERROR";
export const STOCK_OUTGET_LIST= "STOCK_OUTGET_LIST";
export const STOCK_GET_OUTLIST_SUCCESS= "STOCK_GET_OUTLIST_SUCCESS";
export const STOCK_GET_OUTLIST_ERROR= "STOCK_GET_OUTLIST_ERROR";

export const STOCK_DETAILS_ACTION= "STOCK_DETAILS_ACTION";
export const STOCK_DETAILS_SUCCESS= "STOCK_DETAILS_SUCCESS";
export const STOCK_DETAILS_ERROR= "STOCK_DETAILS_ERROR";
export const CREATE_STOCK_ACTION= "CREATE_STOCK_ACTION";
export const CREATE_STOCK_SUCCESS= "CREATE_STOCK_SUCCESS";
export const CREATE_STOCK_ERROR= "CREATE_STOCK_ERROR";
export const UPDATE_STOCK_ACTION= "UPDATE_STOCK_ACTION";
export const UPDATE_STOCK_SUCCESS= "UPDATE_STOCK_SUCCESS";
export const UPDATE_STOCK_FAILURE= "UPDATE_STOCK_FAILURE";
export const DELETE_STOCK_ACTION= "DELETE_STOCK_ACTION";
export const DELETE_STOCK_SUCCESS= "DELETE_STOCK_SUCCESS";
export const DELETE_STOCK_FAILURE= "DELETE_STOCK_FAILURE";

/* DELIVERY */
export const GET_DELIVERY_LIST= "GET_DELIVERY_LIST";
export const GET_DELIVERY_LIST_SUCCESS= "GET_DELIVERY_LIST_SUCCESS";
export const GET_DELIVERY_LIST_ERROR= "GET_DELIVERY_LIST_ERROR";

export const DELIVERY_DETAILS_ACTION= "DELIVERY_DETAILS_ACTION";
export const DELIVERY_DETAILS_SUCCESS= "DELIVERY_DETAILS_SUCCESS";
export const DELIVERY_DETAILS_ERROR= "DELIVERY_DETAILS_ERROR";

export const CREATE_DELIVERY_ACTION= "CREATE_DELIVERY_ACTION";
export const CREATE_DELIVERY_SUCCESS= "CREATE_DELIVERY_SUCCESS";
export const CREATE_DELIVERY_ERROR= "CREATE_DELIVERY_ERROR";

export const UPDATE_DELIVERY_ACTION= "UPDATE_DELIVERY_ACTION";
export const UPDATE_DELIVERY_SUCCESS= "UPDATE_DELIVERY_SUCCESS";
export const UPDATE_DELIVERY_ERROR= "UPDATE_DELIVERY_ERROR";
export const DELETE_DELIVERY_ACTION= "DELETE_DELIVERY_ACTION";
export const DELETE_DELIVERY_SUCCESS= "DELETE_DELIVERY_SUCCESS";
export const DELETE_DELIVERY_FAILURE= "DELETE_DELIVERY_FAILURE";

/* LOGLIST */
export const LOG_GET_LIST= "LOG_GET_LIST";
export const LOG_GET_LIST_SUCCESS= "LOG_GET_LIST_SUCCESS";
export const LOG_GET_LIST_ERROR= "LOG_GET_LIST_ERROR";
/* TODO */
export const TODO_GET_LIST= "TODO_GET_LIST";
export const TODO_GET_LIST_SUCCESS= "TODO_GET_LIST_SUCCESS";
export const TODO_GET_LIST_ERROR= "TODO_GET_LIST_ERROR";


export const UPDATE_TODO_ACTION= "UPDATE_TODO_ACTION";
export const UPDATE_TODO_SUCCESS= "UPDATE_TODO_SUCCESS";
export const UPDATE_TODO_ERROR= "UPDATE_TODO_ERROR";
export const DELETE_TODO_ACTION= "DELETE_TODO_ACTION";
export const DELETE_TODO_SUCCESS= "DELETE_TODO_SUCCESS";
export const DELETE_TODO_FAILURE= "DELETE_TODO_FAILURE";

/* BUYING */
export const BUYINGS_GET_LIST = "BUYINGS_GET_LIST";
export const BUYINGS_GET_LIST_SUCCESS = "BUYINGS_GET_LIST_SUCCESS";
export const BUYINGS_GET_LIST_ERROR = "BUYINGS_GET_LIST_ERROR";

export const BUYING_DETAILS_ACTION = "BUYING_DETAILS_ACTION";
export const BUYING_DETAILS_SUCCESS = "BUYING_DETAILS_SUCCESS";
export const BUYING_DETAILS_ERROR = "BUYING_DETAILS_ERROR";
export const CREATE_BUYING_ACTION = "CREATE_BUYING_ACTION";
export const CREATE_BUYING_SUCCESS = "CREATE_BUYING_SUCCESS";
export const CREATE_BUYING_ERROR = "CREATE_BUYING_ERROR";
export const UPDATE_BUYING_ACTION = "UPDATE_BUYING_ACTION";
export const UPDATE_BUYING_SUCCESS = "UPDATE_BUYING_SUCCESS";
export const UPDATE_BUYING_FAILURE = "UPDATE_BUYING_FAILURE";
export const DELETE_BUYING_ACTION = "DELETE_BUYING_ACTION";
export const DELETE_BUYING_SUCCESS = "DELETE_BUYING_SUCCESS";
export const DELETE_BUYING_FAILURE = "DELETE_BUYING_FAILURE";


/* EMPLOYEE */
export const EMPLOYEE_GET_LIST= "EMPLOYEE_GET_LIST";
export const EMPLOYEE_GET_LIST_SUCCESS= "EMPLOYEE_GET_LIST_SUCCESS";
export const EMPLOYEE_GET_LIST_ERROR= "EMPLOYEE_GET_LIST_ERROR";

export const EMPLOYEE_DETAILS_ACTION= "EMPLOYEE_DETAILS_ACTION";
export const EMPLOYEE_DETAILS_SUCCESS= "EMPLOYEE_DETAILS_SUCCESS";
export const EMPLOYEE_DETAILS_ERROR= "EMPLOYEE_DETAILS_ERROR";
export const CREATE_EMPLOYEE_ACTION= "CREATE_EMPLOYEE_ACTION";
export const CREATE_EMPLOYEE_SUCCESS= "CREATE_EMPLOYEE_SUCCESS";
export const CREATE_EMPLOYEE_ERROR= "CREATE_EMPLOYEE_ERROR";

export const UPDATE_EMPLOYEE_ACTION= "UPDATE_EMPLOYEE_ACTION";
export const UPDATE_EMPLOYEE_SUCCESS= "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_FAILURE= "UPDATE_EMPLOYEE_FAILURE";
export const DELETE_EMPLOYEE_FAILURE= "DELETE_EMPLOYEE_FAILURE";
export const DELETE_EMPLOYEE_ACTION= "DELETE_EMPLOYEE_ACTION";
export const DELETE_EMPLOYEE_SUCCESS= "DELETE_EMPLOYEE_SUCCESS";
/* EMPLOYEE FILES */
export const EMPLOYEE_FILE_GET_LIST= "EMPLOYEE_FILE_GET_LIST";
export const EMPLOYEE_FILE_GET_LIST_SUCCESS= "EMPLOYEE_FILE_GET_LIST_SUCCESS";
export const EMPLOYEE_FILE_GET_LIST_ERROR= "EMPLOYEE_FILE_GET_LIST_ERROR";

export const EMPLOYEE_FILE_DETAILS_ACTION= "EMPLOYEE_FILE_DETAILS_ACTION";
export const EMPLOYEE_FILE_DETAILS_SUCCESS= "EMPLOYEE_FILE_DETAILS_SUCCESS";
export const EMPLOYEE_FILE_DETAILS_ERROR= "EMPLOYEE_FILE_DETAILS_ERROR";
export const CREATE_EMPLOYEE_FILE_ACTION= "CREATE_EMPLOYEE_FILE_ACTION";
export const CREATE_EMPLOYEE_FILE_SUCCESS= "CREATE_EMPLOYEE_FILE_SUCCESS";
export const CREATE_EMPLOYEE_FILE_ERROR= "CREATE_EMPLOYEE_FILE_ERROR";

export const UPDATE_EMPLOYEE_FILE_ACTION= "UPDATE_EMPLOYEE_FILE_ACTION";
export const UPDATE_EMPLOYEE_FILE_SUCCESS= "UPDATE_EMPLOYEE_FILE_SUCCESS";
export const UPDATE_EMPLOYEE_FILE_FAILURE= "UPDATE_EMPLOYEE_FILE_FAILURE";
export const DELETE_EMPLOYEE_FILE_FAILURE= "DELETE_EMPLOYEE_FILE_FAILURE";
export const DELETE_EMPLOYEE_FILE_ACTION= "DELETE_EMPLOYEE_FILE_ACTION";
export const DELETE_EMPLOYEE_FILE_SUCCESS= "DELETE_EMPLOYEE_FILE_SUCCESS";

/* PRODUCT STOCK  LİST */
export const PRODUCT_STOCK_GET_LIST= "PRODUCT_STOCK_GET_LIST";
export const PRODUCT_STOCK_GET_LIST_SUCCESS= "PRODUCT_STOCK_GET_LIST_SUCCESS";
export const PRODUCT_STOCK_GET_LIST_ERROR= "PRODUCT_STOCK_GET_LIST_ERROR";

export const DELETE_PRODUCT_STOCK_ACTION= "DELETE_PRODUCT_STOCK_ACTION";
export const DELETE_PRODUCT_STOCK_SUCCESS= "DELETE_PRODUCT_STOCK_SUCCESS";
export const DELETE_PRODUCT_STOCK_FAILURE= "DELETE_PRODUCT_STOCK_FAILURE";

export const CREATE_PRODUCT_STOCK_ACTION= "CREATE_PRODUCT_STOCK_ACTION";
export const CREATE_PRODUCT_STOCK_SUCCESS= "CREATE_PRODUCT_STOCK_SUCCESS";
export const CREATE_PRODUCT_STOCK_ERROR= "CREATE_PRODUCT_STOCK_ERROR";
export const UPDATE_PRODUCT_STOCK_ACTION= "UPDATE_PRODUCT_STOCK_ACTION";
export const UPDATE_PRODUCT_STOCK_SUCCESS= "UPDATE_PRODUCT_STOCK_SUCCESS";
export const UPDATE_PRODUCT_STOCK_ERROR= "UPDATE_PRODUCT_STOCK_ERROR";
/* ACTEST  LİST */
export const GET_ACTEST_LIST= "GET_ACTEST_LIST";
export const GET_ACTEST_LIST_SUCCESS= "GET_ACTEST_LIST_SUCCESS";
export const GET_ACTEST_LIST_ERROR= "GET_ACTEST_LIST_ERROR";

export const ACTEST_DETAILS_ACTION= "ACTEST_DETAILS_ACTION";
export const ACTEST_DETAILS_ERROR= "ACTEST_DETAILS_ERROR";
export const ACTEST_DETAILS_SUCCESS= "ACTEST_DETAILS_SUCCESS";

export const CREATE_ACTEST_ACTION= "CREATE_ACTEST_ACTION";
export const CREATE_ACTEST_SUCCESS= "CREATE_ACTEST_SUCCESS";
export const CREATE_ACTEST_FAILURE= "CREATE_ACTEST_FAILURE";

export const UPDATE_ACTEST_DETAILS_ACTION= "UPDATE_ACTEST_DETAILS_ACTION";
export const UPDATE_ACTEST_DETAILS_SUCCESS= "UPDATE_ACTEST_DETAILS_SUCCESS";
export const UPDATE_ACTEST_DETAILS_FAILURE= "UPDATE_ACTEST_DETAILS_FAILURE";
export const DELETE_ACTEST_ACTION= "DELETE_ACTEST_ACTION";
export const DELETE_ACTEST_SUCCESS= "DELETE_ACTEST_SUCCESS";
export const DELETE_ACTEST_FAILURE= "DELETE_ACTEST_FAILURE";

/* MAINTENANCE */
export const LIST_MAINTENANCE= "LIST_MAINTENANCE";
export const MAINTENANCE_SUCCESS= "MAINTENANCE_SUCCESS";
export const MAINTENANCE_ERROR= "MAINTENANCE_ERROR";
