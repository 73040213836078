import React from 'react';
import { Tag, Row, Col,Progress,Steps} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye,faCircleCheck,faSpinner,faCirclePause} from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { GeneralContent,ProjectImageCard,ProjectDetail,ProjectResult,ProjectTabContent} from '../../Style/style';
import {getProductCode} from '../../../helpers/getProductCode';
import blank from '../../../static/img/resyok.jpeg';
import moment from 'moment';

const ProjectListCard = ({messages,projectProduct,openComponentListModal,index})=> {
  const {product,siparis_durumu,order_type,_id,offer,urun_kodu,urun_adi,siparis_tarihi,baslama_tarihi,bitis_tarihi,urun_miktari,notComplatedComponentLength,notComplatedHalfComponentLength,ready,teslim_edilmis,status,tamamlanmaOrani,taskList} = projectProduct;
  const productImage = product && product.imageData ? product.imageData: blank;

  let color;let orderType = "";let orderClass = "";
  if (siparis_durumu === 0 ){color = 'magenta'} 
  else if (siparis_durumu === 1){color = 'red'} 
  else if (siparis_durumu === 2){color = 'default'} 
  else if (siparis_durumu === 3){color = 'default'} 
  else if (siparis_durumu === 4){color = "blue"} 
  else if (siparis_durumu === 5){color = 'green'} 
  if (order_type==="material"){
    orderType=messages["material.material"];
    orderClass="malzeme"
  }else if(order_type==="product"){
    orderType=messages["product.name"];
    orderClass="urun"
  }else if(order_type==="subproduction"){
    orderType=messages["product.subproduction"];
    orderClass="subproduction"
  }else{
    orderType="default";
    orderClass="default"
  }
  const projectSteps = [];
  taskList && taskList.length && taskList.sort((a, b) => (a.importance > b.importance) ? -1 : 1 ).forEach(row=>{
    const description= row.task_qty>0 ? row.task_steps.reduce((acc, curr) => acc + parseInt(curr.step_qty, 10), 0)+" / " + row.task_qty+messages["offer.OfferStatus2"]:"";
    const icon = row.status === 0 ? <FontAwesomeIcon icon={faCirclePause} /> : row.status === 1 ?  <FontAwesomeIcon icon={faSpinner} spin/> : <FontAwesomeIcon icon={faCircleCheck}/>
    projectSteps.push({'title':row.title,'description':description,'icon':icon})
  })

  return (
    < >
    <GeneralContent>
      <Row gutter={20}>
        <Col xxl={4} lg={4} md={6} sm={6} xs={24}>
          <ProjectImageCard>
            <div className="card team-card">
              <Cards headless>
                  <figure>
                    <NavLink to={`/admin/projectdetails/`+_id}>
                      <img src={productImage} alt="" />
                    </NavLink>
                  </figure>
              </Cards>
            </div>
          </ProjectImageCard>
        </Col>
        <Col xxl={12} lg={12} md={18} sm={18} xs={24}>
          <ProjectDetail>
            <div className="project-title">
              <h2>{offer.firma_adi.slice(0,50)}</h2>
            </div>
            <div className="project-desc">
              <h1>{urun_adi.slice(0,50)}</h1>
              <h2>{urun_kodu && getProductCode("",urun_kodu[0],urun_kodu[1],urun_kodu[2],urun_kodu[3],urun_kodu[4],urun_kodu[5])}</h2>
            </div>
            <div className="project-timing">
              <div>
                <span>{messages["order.date"]}</span>
                <strong>{moment(siparis_tarihi).format("DD.MM.YYYY")}</strong>
              </div>
              <div>
                <span>{messages["order.startdate"]}</span>
                <strong>{baslama_tarihi ? moment(baslama_tarihi).format("DD.MM.YYYY"): "--.---.----"}</strong>
              </div>
              <div>
                <span>{messages["order.enddate"]}</span>
                <strong>{bitis_tarihi ?  moment(bitis_tarihi).format("DD.MM.YYYY") : "--.--.----"}</strong>
              </div>
            </div>
          </ProjectDetail>
        </Col>
        <Col xxl={8} lg={8} md={24} sm={24} xs={24}>
          <ProjectResult>
              <div className="project-tags">
                <Tag  className={orderClass}>{orderType}</Tag>
                <Tag  className={color}>{messages["order.orderStatus"+siparis_durumu]}</Tag>
              </div>
              <div className="project-qty">
                <div>
                  <span>{messages["order.componentstatus2"]}</span>
                  <strong>{urun_miktari} {messages["genel.Adet"]}</strong>
                </div>
                <div>
                  <span>{messages["order.orderStatus5"]}</span>
                  <strong>{teslim_edilmis} {messages["genel.Adet"]}</strong>
                </div>
              </div>
              <div className="project-progress">
                <Progress
                  percent={tamamlanmaOrani&& tamamlanmaOrani > 0 ? tamamlanmaOrani.toFixed(1):0}
                  strokeWidth={5}
                  status="primary"
                  className="progress-primary"
                />
              </div>
              <div className="project-tags">
                  {order_type==="product"  ? 
                    <Tag 
                      icon= {<FontAwesomeIcon icon={faEye}/>} 
                      className={(!notComplatedHalfComponentLength && notComplatedComponentLength===0   || ready)  ?'green':'red'} 
                      onClick={()=>openComponentListModal(index)}>
                        
                        {(!notComplatedHalfComponentLength && notComplatedComponentLength===0   || ready)  ? (siparis_durumu < 4 ? " Üretim için Hazır" :siparis_durumu === 5 ? " Teslimat Tamamlanmış" :" Teslimat için Hazır"):" Malzeme Listesi Tamamlanmamış"}
                    </Tag>
                    :
                    <Tag className='green'>
                        {(!notComplatedHalfComponentLength && notComplatedComponentLength===0   || ready) ? (siparis_durumu < 4 ? " Hazırlanıyor.." :(siparis_durumu === 5 ? " Teslimat Tamamlanmış" :" Teslimat için Hazır")):" Malzeme Listesi Tamamlanmamış"}
                    </Tag>                  
                    }
              </div>
          </ProjectResult>
        </Col>
      </Row>
    </GeneralContent>
   {order_type==="product" &&
   <GeneralContent>
      <Steps
        size='small'
        current={projectSteps.length}
        items={projectSteps}
      />
    </GeneralContent>}
    </>
  );
}
export {ProjectListCard};
