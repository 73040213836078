import React from 'react';
import { Input, Form, Modal,Button, Select,Row,Col, Divider } from 'antd';
import { FormWrapper } from '../../Style/style';
const SpecModal = ({ specification,specModalActive,updateSpecRowField,lang,messages,toggleModal}) => {
  const [form2] = Form.useForm();
  React.useEffect(() => {
    form2.setFieldsValue(specification)
   }, [specification])
  return (
  <>
  {specModalActive && specification &&
    <Modal
      title={messages["genel.edit"]}
      type='primary'
      open={specModalActive}
      footer={
        <Button  size="large" type="primary" onClick={() => toggleModal("specModalIndex",null)}>
          {messages["button.close"]}
        </Button>
      }
      onCancel={() => toggleModal("specModalIndex",null)}
      forceRender
      closable={false}
    >
      <FormWrapper style={{height:'auto'}}>
          <Form form={form2}  initialValues={specification}>
            <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["genel.sira"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item name='sira'>
                  <Input type='number' onChange={(eData=>updateSpecRowField("sira",eData.target.value))}/>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col md={6} xs={24}>
                <label htmlFor="input-text">{messages["model.label"]}</label>
              </Col>
              <Col md={18} xs={24}>
                <Form.Item name={['urun_ozellik', `${lang}`]}>
                  <Input  onChange={(eData=>updateSpecRowField("urun_ozellik",eData.target.value))}/>
                </Form.Item>
              </Col>
            </Row>
            <Divider/>
            <Row align="middle">
              <Col md={24} xs={24}>
                <label htmlFor="input-text">{messages["product.specifications"]+" TR"}</label>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item  name="urun_aciklama">
                <Select mode="tags" tokenSeparators={[","]} open={false} onChange={(eData=>updateSpecRowField("urun_aciklama",eData))}/>
                </Form.Item>
              </Col>
            </Row>
            <Divider/>
            <Row align="middle">
              <Col md={24} xs={24}>
                <label htmlFor="input-text">{messages["product.specifications"]+" EN"}</label>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item  name="product_description">
                  <Select mode="tags" tokenSeparators={[","]} open={false} onChange={(eData=>updateSpecRowField("product_description",eData))}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
      </FormWrapper>
    </Modal>
  }
  </>
  );
}

export  {SpecModal};
