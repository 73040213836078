import React from 'react';
import {Spin,Button } from 'antd';
import { NavLink } from 'react-router-dom';
import { ActivityContents } from '../../Style/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit,faTrash,faCirclePlus,faImage, faSpinner} from '@fortawesome/free-solid-svg-icons';
import { Cards } from '../../../components/cards/frame/cards-frame';

const ProductImages = ({images,messages,removeImageRow,toggleModal,addImageRow,productDetailLoading}) => {
  const moreContent = (
      <NavLink to="#" onClick={()=>addImageRow()}>
        <span><FontAwesomeIcon icon={faCirclePlus} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.add"]}</span>
      </NavLink>
  );

  return (
  <ActivityContents>

      <Cards
        title={messages["product.images"]}
        more={moreContent}
        loading={productDetailLoading && <Spin indicator={faSpinner}  />}
        bodyStyle={{ minHeight: '730px' }}
      >
        <ul className="activity-list">

          {images && images.length &&  images.map((image,i)=>{
            return(
              <li className="activity-list__single" key={i}>
              <span className="activity-icon primary">
                <FontAwesomeIcon icon={faImage} size='2x' />
              </span>
              <div className="activity-content">
                <div className="activity-info">
                  <img style={{width:'120px'}}src={image.image} alt="" />
                  <p>
                    <span className="inline-text color-primary">{image.description}</span> {' '}
                    <span className="hour">{"MODULED"}</span>
                  </p>
                </div>
                <NavLink className="activity-more" to="#"style={{marginRight:"20px"}}>
                  <Button
                    shape="circle"
                    size="small"
                    style={{marginRight:"5px"}}
                    onClick={()=>toggleModal("imageModalIndex",i)}
                  >
                    <FontAwesomeIcon icon={faEdit} style={{color:'#9299B8'}} size='sm'/>
                  </Button > 
                  <Button
                    shape="circle"
                    size="small"
                    onClick={()=>removeImageRow(i)}
                  >
                    <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
                  </Button>  
                </NavLink>
              </div>
            </li>

  
          )})}



        </ul>
      </Cards>
 
  </ActivityContents>
  );
}

export  {ProductImages};
